// @flow

import type { AdapterType } from './types'; // eslint-disable-line no-unused-vars

(x: AbstractAdapter) => (x: AdapterType); // eslint-disable-line no-unused-expressions
export default class AbstractAdapter {
  shouldFail: boolean;

  constructor() {
    this.shouldFail = false;
  }

  validateToken(data: Object): Promise<*> { // eslint-disable-line no-unused-vars
    throw new Error('Method validateToken is not implemented.');
  }

  createAuthClient(teamName: string, link: string, stripeToken?: string): Promise<*> { // eslint-disable-line no-unused-vars
    throw new Error('Method createAuthClient is not implemented.');
  }

  logout(): Promise<*> { // eslint-disable-line no-unused-vars
    throw new Error('Method logout is not implemented.');
  }

  checkLink(link: string): Promise<*> { // eslint-disable-line no-unused-vars
    throw new Error('Method checkLink is not implemented.');
  }
}
