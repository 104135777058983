// @flow

import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import superagent from 'superagent';
import withNode from '@bunchtogether/boost-client/dist/components/withNode';
import { Map } from 'immutable';
import type { Map as MapType } from 'immutable';
import LayoutComponentBase from 'components/LayoutComponentBase';
import { getPadding, getFlexAlignment } from '../../lib/alignment';

const styles = () => ({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
});

type Props = {
  classes: ClassesType, // eslint-disable-line react/no-unused-prop-types
  className?: string,
  node: MapType<string, *>,
  backgroundImage: Object,
  backgroundImageSize: string,
  backgroundImageAlignment: number,
  padding: Object,
  alignment: number, // eslint-disable-line react/no-unused-prop-types
  size: string, // eslint-disable-line
  fgColor: string,
  bgColor: string,
};

type State = {
  icon: React.Node | null,
};

const iconStrings = {};
const iconStringPromises = {};

const getIconString = (name:string) => {
  if (iconStrings[name]) {
    return iconStrings[name];
  }
  if (iconStringPromises[name]) {
    return iconStringPromises[name];
  }
  const promise = _getIconString(name);
  promise.then(() => {
    delete iconStringPromises[name];
  }).catch(() => {
    delete iconStringPromises[name];
  });
  iconStringPromises[name] = promise;
  return promise;
};

const _getIconString = async (name:string) => { // eslint-disable-line no-underscore-dangle
  const response = await superagent.get(`/images/material-io-tools-icons/${name}.svg`);
  iconStrings[name] = response.text;
  return response.text;
};

class LayoutComponentIcon extends React.PureComponent<Props, State> {
  constructor(props:Props) {
    super(props);
    this.state = {
      icon: null,
    };
    this.mounted = false;
  }

  componentDidMount() {
    this.mounted = true;
    if (this.props.node) {
      this.downloadIcon(this.props);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.node !== this.props.node && this.props.node || prevProps.size !== this.props.size || prevProps.padding !== this.props.padding) {
      this.downloadIcon(this.props);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  mounted: boolean;

  downloadIcon = async (props: Props) => {
    const { node, size, padding } = props;
    if (!node || !Map.isMap(node)) {
      return;
    }
    const icon = node.getIn(['metadata', 'icon']);
    if (!icon) {
      return;
    }
    let svgString = await getIconString(icon);
    if (!this.mounted) {
      return;
    }
    const p = getPadding(padding);
    if (!size || size === 'cover' || size === 'contain') {
      svgString = svgString.replace('<svg ', `<svg preserveAspectRatio="xMidYMid meet" style="width:100%;height:100%;max-width:100%;max-height:100%;padding:${p.paddingTop} ${p.paddingRight} ${p.paddingBottom} ${p.paddingLeft};"`);
    } else {
      svgString = svgString.replace('<svg ', `<svg style="width:${size};height:${size};max-width:100%;max-height:100%;padding:${p.paddingTop} ${p.paddingRight} ${p.paddingBottom} ${p.paddingLeft};"`);
    }
    this.setState({
      icon: <div dangerouslySetInnerHTML={{ __html: svgString }} />,
    });
  }

  render() {
    const { className, classes, alignment, node, backgroundImage, backgroundImageSize, backgroundImageAlignment, fgColor, bgColor } = this.props;
    const { icon } = this.state;
    if (!node || !Map.isMap(node)) {
      return null;
    }
    return (
      <LayoutComponentBase
        className={className}
        color={fgColor || 'inherit'}
        backgroundColor={bgColor || 'inherit'}
        backgroundImage={backgroundImage}
        backgroundImageSize={backgroundImageSize}
        backgroundImageAlignment={backgroundImageAlignment}
      >
        <div className={classes.container} style={getFlexAlignment(alignment)}>
          {icon}
        </div>
      </LayoutComponentBase>
    );
  }
}

export default compose(
  withStyles(styles),
  withNode(),
)(LayoutComponentIcon);

