// @flow

import React from 'react';

type Props = {
  style?: Object
};

type State = {

};

const styles = {
  button: {
    position: 'absolute',
    backgroundColor: 'transparent',
    width: 50,
    height: 50,
    bottom: 0,
    right: 0,
    zIndex: 100,
    cursor: 'pointer',
    border: 0,
    outline: 'none',
  },
};

export default class FullscreenButton extends React.PureComponent<Props, State> { // eslint-disable-line react/prefer-stateless-function
  handleClick = (event:Event) => {
    event.stopPropagation();
    const rootElement = document.getElementById('app') || {};
    const d:Object = document;
    const isFullScreen = !!(d.fullScreenElement || d.msFullscreenElement || d.mozFullScreenElement || d.webkitFullscreenElement);
    if (isFullScreen) {
      // $FlowFixMe
      if (typeof (document.cancelFullScreen) === 'function') {
        // $FlowFixMe
        document.cancelFullScreen();
      // $FlowFixMe
      } else if (typeof (document.msExitFullscreen) === 'function') {
        // $FlowFixMe
        document.msExitFullscreen();
      // $FlowFixMe
      } else if (typeof (document.mozCancelFullScreen) === 'function') {
        // $FlowFixMe
        document.mozCancelFullScreen();
      // $FlowFixMe
      } else if (typeof (document.webkitCancelFullScreen) === 'function') {
        // $FlowFixMe
        document.webkitCancelFullScreen();
      }
      // $FlowFixMe
    } else if (typeof (rootElement.requestFullscreen) === 'function') {
      // $FlowFixMe
      rootElement.requestFullscreen();
      // $FlowFixMe
    } else if (typeof (rootElement.msRequestFullscreen) === 'function') {
      // $FlowFixMe
      rootElement.msRequestFullscreen();
      // $FlowFixMe
    } else if (typeof (rootElement.mozRequestFullScreen) === 'function') {
      // $FlowFixMe
      rootElement.mozRequestFullScreen();
      // $FlowFixMe
    } else if (typeof (rootElement.webkitRequestFullscreen) === 'function') {
      // $FlowFixMe
      rootElement.webkitRequestFullscreen();
    }
  }
  button: Object;
  render() {
    return (<button onClick={this.handleClick} style={Object.assign({}, styles.button, this.props.style)} />);
  }
}
