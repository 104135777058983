// @flow

import * as React from 'react';
import { List } from 'immutable';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import debounce from 'lodash/debounce';
import matchSorter from 'match-sorter';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import { setMenuButtonVisible } from 'containers/App/actions';
import DeviceItem from 'components/DeviceItem';
import boltClient from '@bunchtogether/bolt-client';
import type { VideoElephantType } from 'shared-redux/src/video-elephant/types';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { boltClientReadySelector } from 'containers/App/selectors';

const styles = (theme: Object) => ({
  innerContainer: {
    zIndex: 10,
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    WebkitOverflowScrolling: 'touch',
    boxSizing: 'border-box',
    '-ms-overflow-x': 'hidden',
    '-ms-overflow-y': 'hidden',
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
  },
  header: {
    width: `calc(100vw - ${theme.spacing(3)}px)`,
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(6),
    alignSelf: 'first baseline',
  },
  channelTextContainer: {
    width: '50%',
  },
  channelName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textAlign: 'left',
    maxWidth: '100%',
    lineHeight: 1.3,
    fontWeight: '600',
    color: '#FFF',
  },
  channelDescription: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textAlign: 'left',
    maxWidth: '100%',
    lineHeight: 1.3,
    color: '#FFF',
    whiteSpace: 'normal',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  searchField: {
    marginLeft: theme.spacing(2),
    width: '20%',
  },
  searchFieldInput: {
    alignSelf: 'flex-start',
    fontSize: '1.5vw',
    color: '#FFF',
    borderBottomColor: '#FFF',
    '&:before': {
      borderBottomColor: '#FFF',
    },
    '&:after': {
      borderBottomColor: emphasize('#FFF', 0.2),
    },
  },
  searchFieldLabel: {
    color: '#FFF',
  },
  searchFieldLabelFocused: {
    color: `${emphasize('#FFF', 0.2)} !important`,
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundSize: 'cover',
  },
});

type Props = {
  classes: ClassesType,
  setMenuButtonVisible: Function,
  channelName: string,
  channelDescription: string,
  videos: List<VideoElephantType>,
  filteredVideos: List<VideoElephantType>,
  handleSetVideos: Function,
  handlePlayVideo: Function,
  activeVideo: boolean,
  boltClientReady: boolean,
};

type State = {
  search: string,
};

export class LayoutComponentContentLibraryPlaylistChoose extends React.Component<Props, State> {
  state = {
    search: '',
  };

  componentDidMount() {
    const { activeVideo } = this.props;
    this.props.setMenuButtonVisible(!activeVideo);
  }

  componentDidUpdate(prevProps: Props) {
    const { activeVideo } = this.props;
    if (activeVideo !== prevProps.activeVideo) {
      this.props.setMenuButtonVisible(!activeVideo);
    }
  }

  componentWillUnmount() {
    this.props.setMenuButtonVisible(false);
  }

  handleSearchFilter = debounce(() => {
    const { videos } = this.props;
    const { search } = this.state;
    if (!search) {
      this.props.handleSetVideos(videos);
    } else {
      this.props.handleSetVideos(List(matchSorter(videos.toJS(), search, {
        keys: [
          { threshold: matchSorter.rankings.ACRONYM, key: 'title' },
          { threshold: matchSorter.rankings.ACRONYM, key: 'description' },
          { threshold: matchSorter.rankings.CONTAINS, key: 'credit' },
          { threshold: matchSorter.rankings.EQUAL, key: 'keywords' },
          { threshold: matchSorter.rankings.EQUAL, key: 'category' },
        ],
      })));
    }
  }, 200, { leading: true });

  handleSearch = (event: Object) => {
    this.setState({ search: event.target.value }, this.handleSearchFilter);
  }

  handlePlayVideo(index: number) {
    if (this.props.handlePlayVideo) {
      this.props.handlePlayVideo(index);
    }
  }

  render() {
    const { classes, channelName, channelDescription, filteredVideos, boltClientReady } = this.props;
    const { search } = this.state;
    return (
      <div className={classes.innerContainer}>
        <div className={classes.header}>
          <div className={classes.channelTextContainer}>
            <Typography variant='h2' className={classes.channelName}>
              {channelName}
            </Typography>
            <Typography variant='h6' className={classes.channelDescription}>
              {channelDescription}
            </Typography>
          </div>
          <TextField
            InputProps={{
              startAdornment: <SearchIcon className={classes.icon} />,
              className: classes.searchFieldInput,
            }}
            InputLabelProps={{
              classes: {
                root: classes.searchFieldLabel,
                focused: classes.searchFieldLabelFocused,
              },
            }}
            placeholder='Type to Search'
            label='Search'
            className={classes.searchField}
            value={search}
            onChange={this.handleSearch}
          />
        </div>
        {boltClientReady ? filteredVideos.toJS().map((video: VideoElephantType, index: number) => (
          <DeviceItem
            onClick={() => this.handlePlayVideo(index)}
            name={video.title}
            description={video.description}
            content={<div
              className={classes.image}
              style={{ backgroundImage: `url(${boltClient.getUrl((video.thumbnail || '').replace(/^https?:\/\//, 'no-expire/'))})` }}
            />}
          />
        )) : null}
      </div>
    );
  }
}

const withConnect = connect((state:StateType) => ({
  boltClientReady: boltClientReadySelector(state),
}), (dispatch: Function) => bindActionCreators({ setMenuButtonVisible }, dispatch),
);

export default compose(
  withStyles(styles),
  withConnect,
)(LayoutComponentContentLibraryPlaylistChoose);
