// @flow

import * as React from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import ReactQuill from 'react-quill';
import withNode from '@bunchtogether/boost-client/dist/components/withNode';
import { Map } from 'immutable';
import type { Map as MapType } from 'immutable';
import LayoutComponentBase from 'components/LayoutComponentBase';
import { getFlexAlignment, getPadding } from '../../lib/alignment';


const styles = (theme: Object) => ({
  deltaContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    fontFamily: theme.typography.fontFamily,
    wordBreak: 'break-word',
  },
});

type Props = {
  classes: Object,
  className?: string,
  node?: MapType<string, *>,
  layoutWidth: number,
  backgroundImage: Object,
  backgroundImageSize: string,
  backgroundImageAlignment: number,
  padding: Object,
  alignment: number,
  fgColor: string,
  bgColor: string,
};

type State = {
  timeFormats: Object,
  editorState: Object,
  previousTimeFormats: string,
};

export class LayoutComponentClock extends React.PureComponent<Props, State> { // eslint-disable-line react/prefer-stateless-function
  constructor(props: Props) {
    super(props);
    this.state = {
      timeFormats: {},
      editorState: {},
      previousTimeFormats: '',
    };
  }

  componentDidMount() {
    if (this.props.node) {
      this.initializeComponent(this.props);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.node !== this.props.node && this.props.node) {
      this.initializeComponent(this.props);
    }
  }

  componentWillUnmount() {
    if (this.timeInterval) {
      clearInterval(this.timeInterval);
    }
  }

  getLocalTime = (timezone: string) => {
    if (this.timeInterval) {
      clearInterval(this.timeInterval);
    }
    this.timeInterval = setInterval(() => {
      const formatKeys = Object.keys(this.state.timeFormats);
      const newTimeFormats = {};
      for (const format of formatKeys) {
        newTimeFormats[format] = moment().tz(timezone).format(format.slice(1).slice(0, -1));
      }
      if (JSON.stringify(newTimeFormats) !== this.state.previousTimeFormats) {
        this.setState({ timeFormats: newTimeFormats, previousTimeFormats: JSON.stringify(newTimeFormats) });
      }
    }, 1000);
  }

  getQuillPreview = () => {
    const { editorState, timeFormats } = this.state;
    let stringifiedDelta = JSON.stringify(editorState);
    const formatKeys = Object.keys(timeFormats);
    for (const format of formatKeys) {
      stringifiedDelta = stringifiedDelta.replace(new RegExp(format, 'g'), timeFormats[format]);
    }
    return JSON.parse(stringifiedDelta);
  }

  initializeComponent = (props: Props) => {
    const { node } = props;
    if (!node || !Map.isMap(node)) {
      return;
    }
    const timezone = node.getIn(['metadata', 'timezone']);
    const timeFormats = node.getIn(['metadata', 'timeFormats']) || Map();
    const delta = node.getIn(['metadata', 'delta']);
    const formatKeys = Object.keys(timeFormats.toJS());
    const newTimeFormats = {};
    for (const format of formatKeys) {
      newTimeFormats[format] = moment().tz(timezone).format(format.slice(1).slice(0, -1));
    }
    this.setState({ timeFormats: newTimeFormats, editorState: JSON.parse(delta) }, () => {
      this.getLocalTime(timezone);
    });
  }


  timeInterval: any;

  render() {
    const { classes, className, node, layoutWidth, backgroundImage, backgroundImageSize, backgroundImageAlignment, padding, alignment, fgColor, bgColor } = this.props;
    if (!node || !Map.isMap(node)) {
      return null;
    }
    const delta = node.getIn(['metadata', 'delta']);
    const scale = layoutWidth / 1280;
    const style = {
      fontSize: `${scale}em`,
      ...getFlexAlignment(alignment),
      ...getPadding(padding),
    };
    return (
      <LayoutComponentBase
        className={className}
        color={fgColor || 'inherit'}
        backgroundColor={bgColor || 'inherit'}
        backgroundImage={backgroundImage}
        backgroundImageSize={backgroundImageSize}
        backgroundImageAlignment={backgroundImageAlignment}
      >
        <div style={style} className={classes.deltaContainer}>
          {delta ? <ReactQuill theme={null} readOnly value={this.getQuillPreview()} /> : null}
        </div>
      </LayoutComponentBase>
    );
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withNode(),
)(LayoutComponentClock);

