// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import LayoutComponentContentLibraryPlaylistVideo from 'components/LayoutComponentContentLibraryPlaylistVideo';
import { resultSelector } from 'shared-redux/src/video-elephant/selectors';
import type { VideoElephantType } from 'shared-redux/src/video-elephant/types';

type Props = {
  id: string,
  className: string,
  video: VideoElephantType,
  handleOnEnded: Function,
  style: Object,
};

export class LayoutComponentContentLibraryPlaylistSearchVideo extends React.PureComponent<Props> {
  render() {
    const { id, video, className, style } = this.props;
    return (
      <LayoutComponentContentLibraryPlaylistVideo
        id={id}
        className={className}
        video={Map(video)}
        handleOnEnded={this.props.handleOnEnded}
        style={style}
      />
    );
  }
}

export default connect((state: StateType, props: Props) => ({
  video: resultSelector(state, props.id),
}))(LayoutComponentContentLibraryPlaylistSearchVideo);
