// @flow

import { OrderedSet, fromJS } from 'immutable';
import moment from 'moment';
import { UPDATE_BAND_DEVICE } from 'band-redux/src/app/constants';
import * as constants from './constants';


const o = new Date();

const initialState = fromJS({
  time: moment(o).format('LT'),
  date: moment(o).format('LL'),
  navigationVisible: false,
  searchQuery: '',
  teamId: null,
  orientation: window.orientation,
  deepstreamError: false,
  boltClientReady: false,
  notifications: OrderedSet(),
  application: {},
  deviceId: null,
  userId: '',
  logoData: null,
  dialogs: fromJS({}),
  dialogOptions: fromJS({}),
  loginError: '',
  channelSelectorOpen: false,
  menuButtonVisible: false,
  activeLayout: null,
  teamName: '',
  teamIdError: null,
  metadata: fromJS({}),
  themeColor: 'rgba(63, 81, 181, 1)',
  activeChannelId: null,
  deviceAccessToken: null,
  canAutoplayMuted: true,
  canAutoplayUnmuted: undefined,
  userAgentParams: fromJS({}),
  blendExtensionStatus: false,
  screenWidth: window.innerWidth,
  screenHeight: window.innerHeight,
  zoomRoomAvailable: false,
  bluescapeAvailable: false,
  mouseActive: false,
  zoomMeetingId: '',
  zoomMeetingNodeId: '',
  useMulticast: false,
  hardwareManagerIp: '',
  clock24Hr: false,
  zoomClientMeetingId: '',
  keepQrCodeOnTop: false,
});

export default (state: AppStateType = initialState, action: ActionType) => {
  switch (action.type) {
    case UPDATE_BAND_DEVICE:
      const metadata = state.get('metadata'); // eslint-disable-line no-case-declarations
      if (metadata && action.value && action.value.metadata) {
        return state.set('metadata', metadata.merge(fromJS(action.value.metadata)));
      }
      return state;
    case constants.SET_CLOCK:
      return state.merge({ time: action.value[0], date: action.value[1] });
    case constants.SET_CLOCK_24HR:
      return state.set('clock24Hr', action.value);
    case constants.BOLT_CLIENT_READY:
      return state.set('boltClientReady', action.value);
    case constants.CAN_AUTOPLAY_MUTED:
      return state.set('canAutoplayMuted', action.value);
    case constants.CAN_AUTOPLAY_UNMUTED:
      return state.set('canAutoplayUnmuted', action.value);
    case constants.SET_DEVICE_ACCESS_TOKEN:
      return state.set('deviceAccessToken', action.value);
    case constants.SET_DEVICE_METADATA:
      return state.set('metadata', action.value);
    case constants.SET_USER_AGENT_PARAMS:
      return state.set('userAgentParams', fromJS(action.value));
    case constants.ADD_NOTIFICATION:
      return state.set('notifications', state.get('notifications').takeLast(10).add(fromJS(action.value)));
    case constants.SHOW_NAVIGATION:
      return state.set('navigationVisible', true);
    case constants.HIDE_NAVIGATION:
      return state.set('navigationVisible', false);
    case constants.SEARCH:
      return state.set('searchQuery', action.value);
    case constants.CLEAR_SEARCH:
      return state.set('searchQuery', '');
    case constants.SET_LOGO:
      return state.set('logoData', action.value ? fromJS(action.value) : action.value);
    case constants.SET_PRIVATE_KEY:
      return state.set('privateKey', action.value);
    case constants.SET_SCALA_DATA:
      return state.merge({
        scalaPlayerName: action.value.scalaPlayerName,
        scalaPlayerId: action.value.scalaPlayerId,
        scalaAccountId: action.value.scalaAccountId,
      });
    case constants.SET_THEME:
      return state.set('themeColor', fromJS(action.value));
    case constants.SET_ACTIVE_CHANNEL_ID:
      return state.merge({
        activeChannelId: action.value,
        channelSelectorOpen: false,
      });
    case constants.SET_USE_MULTICAST:
      return state.set('useMulticast', action.value);
    case constants.SET_TEAM_NAME:
      return state.merge({
        teamName: action.value,
        teamIdError: null,
      });
    case constants.GET_TEAM_ID_ERROR:
      return state.set('teamIdError', action.value);
    case constants.GET_DEFAULT_TEAM_ERROR:
      return state.merge({
        deepstreamLogin: false,
      });
    case constants.SET_APPLICATION:
      if (action.value.type === 'metadata') {
        return state.merge({
          application: action.value,
          teamId: action.value.metadata.teamId,
        });
      }
      return state.merge({
        application: action.value,
        teamId: action.value.id,
      });
    case constants.CLEAR_PRIVATE_KEY:
      return state.delete('privateKey');
    case constants.LOGIN_ERROR:
      return state.set('loginError', action.value);
    case constants.RESET_LOGIN_ERROR:
      return state.set('loginError', '');
    case constants.OPEN_CHANNEL_SELECTOR:
      return state.set('channelSelectorOpen', true);
    case constants.CLOSE_CHANNEL_SELECTOR:
      return state.set('channelSelectorOpen', false);
    case constants.SET_ACTIVE_LAYOUT:
      return state.set('activeLayout', action.value);
    case constants.OPEN_DIALOG:
      return state.setIn(['dialogs', action.value.dialog], true).set('dialogOptions', fromJS(action.value.options || {}));
    case constants.CLOSE_DIALOG:
      return state.setIn(['dialogs', action.value.dialog], false).set('dialogOptions', fromJS({}));
    case constants.DEVICE_LOGIN:
      return state.set('deviceId', action.value);
    case constants.SET_USER_ID:
      return state.set('userId', action.value);
    case constants.SET_BLEND_EXTENSION:
      return state.set('blendExtensionStatus', action.value);
    case constants.SET_ZOOM_ROOM_PASSCODE:
      return state.set('zoomRoomPasscode', action.value || '');
    case constants.SET_ZOOM_ROOM_AVAILABLE:
      return state.set('zoomRoomAvailable', action.value);
    case constants.SET_BLUESCAPE_AVAILABLE:
      return state.set('bluescapeAvailable', action.value);
    case constants.SET_WINDOW_SIZE:
      return state.merge({
        screenWidth: action.value.width,
        screenHeight: action.value.height,
      });
    case constants.SET_MOUSE_ACTIVE:
      return state.set('mouseActive', action.value);
    case constants.SET_ZOOM_MEETING_ID:
      return state.set('zoomMeetingId', action.value);
    case constants.SET_ZOOM_MEETING_NODE_ID:
      return state.set('zoomMeetingNodeId', action.value);
    case constants.SET_ZOOM_CLIENT_MEETING_ID:
      return state.set('zoomClientMeetingId', action.value);
    case constants.SET_MENU_BUTTON_VISIBLE:
      return state.set('menuButtonVisible', action.value);
    case constants.SET_HARDWARE_MANAGER_IP:
      return state.set('hardwareManagerIp', action.value);
    case constants.SET_REMOTE_URL:
      return state.set('remoteUrl', action.value);
    case constants.KEEP_QR_CODE_ON_TOP:
      return state.set('keepQrCodeOnTop', action.value);
    default:
      return state;
  }
};
