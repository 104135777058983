// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { compose, bindActionCreators } from 'redux';
import boltClient from '@bunchtogether/bolt-client';
import withNode from '@bunchtogether/boost-client/dist/components/withNode';
import { Map } from 'immutable';
import type { Map as MapType } from 'immutable';
import LayoutComponentBase from 'components/LayoutComponentBase';
import { boltClientReadySelector } from 'containers/App/selectors';
import { getPadding, getFlexAlignment, getCssAlignment } from '../../lib/alignment';

const styles = () => ({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
});


type Props = {
  classes: ClassesType,
  className?: string,
  node?: MapType<string, any>,
  boltClientReady: boolean,
  backgroundImage: Object,
  backgroundImageSize: string,
  backgroundImageAlignment: number,
  padding: Object,
  alignment: number,
  size: string,
  fgColor: string,
  bgColor: string,
};

class LayoutComponentImage extends React.PureComponent<Props, void> {
  getImage = (data: Map, size: string, alignment: Object) => {
    if (!data || !Map.isMap(data) || !this.props.boltClientReady) {
      return null;
    }
    const imageProps: Object = {};
    const imgSrc = `${boltClient.getUrl(data.get('path'))}`;
    if (!size || size === 'cover' || size === 'contain') {
      imageProps.style = {
        width: '100%',
        height: '100%',
        backgroundSize: size || 'contain',
        backgroundPosition: alignment.backgroundPosition,
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${imgSrc})`,
      };
      return <div {...imageProps} />;
    }
    imageProps.style = {
      width: size,
      maxWidth: '100%',
    };
    imageProps.src = imgSrc;
    return <img {...imageProps} alt='' />;
  };

  render() {
    const { classes, className, node, backgroundImage, backgroundImageSize, backgroundImageAlignment, alignment, padding, size, fgColor, bgColor } = this.props;
    if (!node || !Map.isMap(node)) {
      return null;
    }
    const style = {
      ...getFlexAlignment(alignment),
      ...getPadding(padding),
    };
    const image = node.getIn(['metadata', 'image']);
    return (
      <LayoutComponentBase
        className={className}
        color={fgColor || 'inherit'}
        backgroundColor={bgColor || 'inherit'}
        backgroundImage={backgroundImage}
        backgroundImageSize={backgroundImageSize}
        backgroundImageAlignment={backgroundImageAlignment}
      >
        <div className={classes.container} style={style}>
          {this.getImage(image, size, getCssAlignment(alignment))}
        </div>
      </LayoutComponentBase>
    );
  }
}

const withConnect = connect((state: StateType) => ({
  boltClientReady: boltClientReadySelector(state),
}), (dispatch: Function) => bindActionCreators({}, dispatch));

export default compose(
  withStyles(styles),
  withConnect,
  withNode(),
)(LayoutComponentImage);

