// @flow

/**
*
* KeiserAggregatePower
*
*/

import * as React from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { List, is } from 'immutable';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import withKeiserAggregate from '@bunchtogether/boost-client/dist/components/withKeiserAggregate';
import { teamIdSelector } from 'containers/App/selectors';
import { stringToColor } from '../../lib/colors';


const styles = (theme:Object) => ({ // eslint-disable-line no-unused-vars
  root: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
  },
  container: {
    overflowX: 'hidden',
    overflowY: 'auto',
    width: '100%',
    height: '100%',
  },
  outerContainer: {
    width: '100%',
    height: '12.2%',
    minHeight: 105,
  },
  dataContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    padding: theme.spacing(1),
  },
  title: {
    fontFamily: 'Roboto Condensed',
    fontWeight: 600,
    color: '#666',
    [theme.breakpoints.up('xl')]: {
      fontSize: '1.7em',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.7em',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  condensed: {
    fontFamily: 'Roboto Condensed',
    fontWeight: 600,
    color: '#666',
    lineHeight: 1,
    [theme.breakpoints.up('xl')]: {
      fontSize: '3em',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '2em',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '2.1em',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '3.8em',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '4.3em',
    },
  },
  work: {
    fontFamily: 'Roboto Condensed',
    fontWeight: 900,
    lineHeight: 0.9,
    color: theme.palette.primary.main,
    [theme.breakpoints.up('xl')]: {
      fontSize: '7em',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '5em',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '6em',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '10em',
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  jouleText: {
    color: theme.palette.primary.main,
    opacity: 0.3,
    fontWeight: 600,
    lineHeight: 0.9,
    [theme.breakpoints.up('xl')]: {
      fontSize: '7em',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '5em',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '6em',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '10em',
    },
  },
});

type Props = {
  classes: ClassesType,
  keiser: List,
};

type State = {
  keys: Set<string>,
  data: Map,
};

class KeiserAggregatePower extends React.PureComponent<Props, State> { // eslint-disable-line react/prefer-stateless-function
  constructor(props: Props) {
    super(props);
    this.state = {
      keys: new Set(),
      data: new Map(),
    };
  }

  componentDidMount() {
    if (this.props.keiser && List.isList(this.props.keiser)) {
      this.handleUpdateData(this.props);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (!is(prevProps.keiser, this.props.keiser) && this.props.keiser && List.isList(this.props.keiser)) {
      this.handleUpdateData(this.props);
    }
  }

  handleUpdateData = (props: Props) => {
    const { keiser } = props;
    const tempSet = new Set();
    const tempMap = new Map();
    for (let i = 0; i < keiser.size; i += 1) {
      const node = keiser.get(i);
      const lastRep = node.getIn(['metadata', 'reps']).last();
      const serialNumber = node.getIn(['metadata', 'serialNumber']);
      const serialNumberStr = typeof serialNumber === 'number' ? serialNumber.toString() : serialNumber;
      tempSet.add(serialNumberStr);
      const totalWorkForSet = lastRep ? lastRep.get('work') : 0;
      if (!tempMap.get(serialNumberStr)) {
        tempMap.set(serialNumberStr, totalWorkForSet);
      } else {
        tempMap.set(serialNumberStr, tempMap.get(serialNumberStr) + totalWorkForSet);
      }
    }
    const mapSortValues = new Map([...tempMap.entries()].sort((a, b) => b[1] - a[1]));
    this.setState({
      keys: tempSet,
      data: mapSortValues,
    });
  }

  render() {
    const { classes } = this.props;
    const { data } = this.state;
    const keys = data.keys();
    return (
      <Paper className={classes.root} elevation={0}>
        <Typography className={classes.title}>TOTAL STRENGTH</Typography>
        <div className={classes.container}>
          {[...keys].map((key) => {
            const keyStr = typeof key === 'number' ? key.toString() : key;
            const work = data.get(keyStr) ? Number(parseFloat(data.get(keyStr) / 1000).toFixed(2)) : 0;
            if (work > 0) {
              let unit = '';
              let calculatedWork = work;
              if (work > 1000000) {
                calculatedWork = Math.round(work / 10000) * 10;
                unit = 'K';
              }
              return (
                <div className={classes.outerContainer} key={keyStr}>
                  <div className={classes.dataContainer}>
                    <Typography className={classes.condensed} style={{ color: stringToColor(keyStr) }}>{keyStr.slice(8)}</Typography>
                    <div className={classes.row}>
                      <Typography className={classes.work}>{calculatedWork}</Typography>
                      <Typography className={classes.jouleText}>{unit}</Typography>
                    </div>
                  </div>
                  <Divider />
                </div>
              );
            }
            return null;
          })}
        </div>
      </Paper>
    );
  }
}

const withConnect = connect((state:StateType) => ({
  teamId: teamIdSelector(state),
}), (dispatch: Function) => bindActionCreators({}, dispatch));

export default compose(
  withStyles(styles, { withTheme: true }),
  withConnect,
  withKeiserAggregate(),
)(KeiserAggregatePower);

