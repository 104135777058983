// @flow

import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import boltClient from '@bunchtogether/bolt-client';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import withNode from '@bunchtogether/boost-client/dist/components/withNode';
import LayoutComponentBase from 'components/LayoutComponentBase';
import PdfComponent from 'components/PdfComponent';
import { boltClientReadySelector } from 'containers/App/selectors';
import { getFlexAlignment, getPadding } from '../../lib/alignment';


const styles = () => ({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
});

type Props = {
  classes: ClassesType,
  className?: string,
  node?: Map,
  boltClientReady: boolean,
  active: boolean,
  padding: Object,
  backgroundImage: Object,
  backgroundImageSize: string,
  backgroundImageAlignment: number,
  alignment: number,
  size: string,
  fgColor: string,
  bgColor: string,
  layoutWidth: string,
  layoutHeight: string,
};

type State = {};

class LayoutComponentPdf extends React.PureComponent<Props, State> {
  getPdf = (data: Map) => {
    if (!data || !Map.isMap(data) || !this.props.boltClientReady) {
      return null;
    }
    const { size, layoutHeight, layoutWidth, active } = this.props;
    const pdfSrc = boltClient.getUrl(data.get('path'));
    return <PdfComponent url={pdfSrc} size={size} layoutHeight={layoutHeight} layoutWidth={layoutWidth} preview={!active} />;
  };

  render() {
    const { classes, className, node, backgroundImage, backgroundImageSize, backgroundImageAlignment, alignment, padding, fgColor, bgColor } = this.props;
    if (!node || !Map.isMap(node)) {
      return null;
    }
    const style = {
      ...getFlexAlignment(alignment),
      ...getPadding(padding),
    };
    const pdf = node.getIn(['metadata', 'pdf']);
    return (
      <LayoutComponentBase
        className={className}
        color={fgColor || 'inherit'}
        backgroundColor={bgColor || 'inherit'}
        backgroundImage={backgroundImage}
        backgroundImageSize={backgroundImageSize}
        backgroundImageAlignment={backgroundImageAlignment}
      >
        <div className={classes.container} style={style}>
          {this.getPdf(pdf)}
        </div>
      </LayoutComponentBase>
    );
  }
}

const withConnect = connect((state: StateType) => ({
  boltClientReady: boltClientReadySelector(state),
}), (dispatch: Function) => bindActionCreators({}, dispatch));

export default compose(
  withStyles(styles),
  withConnect,
  withNode(),
)(LayoutComponentPdf);
