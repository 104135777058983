// @flow

/**
*
* DeviceItem
*
*/

import * as React from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';

const styles = (theme:Object) => ({ // eslint-disable-line no-unused-vars
  cardContainer: {
    boxSizing: 'border-box',
    height: 'auto',
    [theme.breakpoints.up('md')]: {
      width: '33.333%',
      paddingRight: theme.spacing(3),
    },
    [theme.breakpoints.up('lg')]: {
      width: '25%',
      paddingRight: theme.spacing(3),
    },
    paddingBottom: theme.spacing(3),
  },
  liCardContainer: {
    backgroundColor: 'transparent',
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  liThumbnailContainer: {
    minWidth: 176,
    height: 99,
    backgroundColor: 'rgba(240,240,240,1)',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: `0px 0px ${theme.spacing(2)}px 2px rgba(0,0,0,0.5)`,
  },
  button: {
    width: '100%',
  },
  liContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
    cursor: 'pointer',
  },
  liTextContainer: {
    maxWidth: `calc(100% - 176px - ${theme.spacing(2)}px)`,
    maxHeight: 99,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginLeft: theme.spacing(2),
  },
  cardChannelName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textAlign: 'left',
    maxWidth: '100%',
    lineHeight: 1.3,
    fontWeight: '600',
    color: 'white',
  },
  liChannelName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textAlign: 'left',
    maxWidth: '100%',
    lineHeight: 1.3,
    color: 'white',
  },
  liChannelDescription: {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    lineHeight: '1.3em',
    height: '3.9em',
    textAlign: 'left',
    color: 'white',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '100%',
    cursor: 'pointer',
    position: 'relative',
  },
  thumbnailContainer: {
    width: '100%',
    height: 0,
    borderRadius: 0,
    overflow: 'hidden',
    paddingBottom: '56.25%',
    transform: 'scale(1)',
    WebkitTransform: 'scale(1)',
    backgroundColor: 'rgba(240,240,240,1)',
    boxShadow: `0px 0px ${theme.spacing(2)}px 2px rgba(0,0,0,0.25)`,
    transition: 'box-shadow 0.1s ease-in, transform 0.1s ease-in',
    '&:hover': {
      boxShadow: `0px 0px ${theme.spacing(2)}px 2px rgba(0,0,0,0.5)`,
      transform: 'scale(1.025)',
      WebkitTransform: 'scale(1.025)',
    },
  },
  highlight: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    transition: 'box-shadow 0.1s ease-in',
    '&:hover': {
      boxShadow: `inset 0px 0px ${theme.spacing(8)}px 2px rgba(255, 255, 255, 0.15)`,
    },
  },
  thumbnail: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    boxSizing: 'border-box',
    maxWidth: '100%',
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  cardChannelDescription: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    lineHeight: '1.2em',
    height: '3.5em',
    textAlign: 'left',
    fontSize: 14,
    fontWeight: '300',
    opacity: 0.66,
    color: 'white',
    maxWidth: '100%',
  },
  buttonRoot: {
    position: 'initial',
  },
});

type Props = {
  classes: ClassesType,
  width: string,
  name: string,
  description: string,
  content: React.Node,
  onClick: () => void,
  disabled?: boolean,
};

type State = {};

class DeviceItem extends React.PureComponent<Props, State> { // eslint-disable-line react/prefer-stateless-function
  render() {
    const { name, description, content, onClick, classes, width, disabled } = this.props;
    if (isWidthDown('sm', width)) {
      return (
        <div className={classes.liCardContainer}>
          <ButtonBase
            className={classes.button}
            onClick={onClick}
            disabled={disabled || !onClick}
          >
            <div className={classes.liContainer}>
              <div className={classes.liThumbnailContainer}>
                {content}
              </div>
              <div className={classes.liTextContainer}>
                <Typography variant="h6" className={classes.liChannelName}>
                  {name}
                </Typography>
                <Typography variant="caption" className={classes.liChannelDescription}>
                  {description}
                </Typography>
              </div>
            </div>
          </ButtonBase>
        </div>
      );
    }
    return (
      <div className={classes.cardContainer}>
        <ButtonBase
          className={classes.button}
          onClick={onClick}
          classes={{
            root: classes.buttonRoot,
          }}
          disabled={disabled || !onClick}
        >
          <div className={classes.container}>
            <div className={classes.thumbnailContainer}>
              <div className={classes.thumbnail}>
                {content}
              </div>
              <div className={classes.highlight} />
            </div>
            <div className={classes.cardTextContainer}>
              <Typography variant="h6" className={classes.cardChannelName}>
                {name}
              </Typography>
              <Typography variant="body2" className={classes.cardChannelDescription}>
                {description}
              </Typography>
            </div>
          </div>
        </ButtonBase>
      </div>
    );
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withWidth(),
)(DeviceItem);

