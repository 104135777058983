// @flow

import { call, put, takeEvery, select } from 'redux-saga/effects';
import * as constants from './constants';
import { programLiveSchedulesSelector, programVodMediaStreamsSelector } from './selectors';
import type { AdapterType, CreateDefaultSaga } from './types';
import type { ActionType } from '../types';

const PROJECT_PROTOCOL = process.env.PROJECT_PROTOCOL || window.location.protocol;
const PROJECT_HOST = process.env.PROJECT_HOST || window.location.hostname;
const PROJECT_PORT = process.env.PROJECT_PORT || window.location.port;

export function* queryProgramsSaga(adapter: AdapterType, action: ActionType): Generator<*, *, *> {
  const { hostname, port, id, domain } = action.value.metadata;
  adapter.setXQumuHost(`${hostname}:${port}:${id}`);
  adapter.setDomain(domain);
  let origin = location.origin;
  if (PROJECT_PROTOCOL && PROJECT_HOST && PROJECT_PORT) {
    origin = `${PROJECT_PROTOCOL}://${PROJECT_HOST}:${PROJECT_PORT}`;
  }
  adapter.setOrigin(origin);
  try {
    const value = yield call(adapter.queryPrograms, action.value.queryGroupId, action.value.queryId, action.value.options);
    yield put({ type: constants.QUERY_PROGRAMS_RESULT, value });
  } catch (error) {
    yield put({ type: constants.QUERY_PROGRAMS_ERROR, value: error });
  }
}

export function* queryAdminProgramsSaga(adapter: AdapterType, action: ActionType): Generator<*, *, *> {
  const { hostname, port, id, domain } = action.value.metadata;
  adapter.setXQumuHost(`${hostname}:${port}:${id}`);
  adapter.setDomain(domain);
  let origin = location.origin;
  if (PROJECT_PROTOCOL && PROJECT_HOST && PROJECT_PORT) {
    origin = `${PROJECT_PROTOCOL}://${PROJECT_HOST}:${PROJECT_PORT}`;
  }
  adapter.setOrigin(origin);
  try {
    const value = yield call(adapter.queryAdminPrograms, action.value.queryGroupId, action.value.queryId, action.value.options);
    yield put({ type: constants.QUERY_ADMIN_PROGRAMS_RESULT, value });
  } catch (error) {
    yield put({ type: constants.QUERY_ADMIN_PROGRAMS_ERROR, value: error });
  }
}

export function* getProgramSaga(adapter: AdapterType, action: ActionType): Generator<*, *, *> {
  const { hostname, port, id, domain } = action.value.metadata;
  adapter.setXQumuHost(`${hostname}:${port}:${id}`);
  adapter.setDomain(domain);
  let origin = location.origin;
  if (PROJECT_PROTOCOL && PROJECT_HOST && PROJECT_PORT) {
    origin = `${PROJECT_PROTOCOL}://${PROJECT_HOST}:${PROJECT_PORT}`;
  }
  adapter.setOrigin(origin);
  try {
    const value = yield call(adapter.getProgram, action.value.programId);
    yield put({ type: constants.GET_PROGRAM_RESULT, value });
  } catch (error) {
    yield put({ type: constants.GET_PROGRAM_ERROR, value: error });
  }
}

export function* getProgramContentLocationsSaga(adapter: AdapterType, action: ActionType): Generator<*, *, *> {
  const cachedString = localStorage.getItem(action.value.programId);
  if (cachedString) {
    try {
      const cached = JSON.parse(cachedString);
      yield put({ type: constants.GET_PROGRAM_CONTENT_LOCATIONS_RESULT, value: cached });
    } catch (error) {
      console.log(`Error parsing content location cache: ${error.message}`);
    }
  }
  const { hostname, port, id, domain } = action.value.metadata;
  adapter.setXQumuHost(`${hostname}:${port}:${id}`);
  adapter.setDomain(domain);
  let origin = location.origin;
  if (PROJECT_PROTOCOL && PROJECT_HOST && PROJECT_PORT) {
    origin = `${PROJECT_PROTOCOL}://${PROJECT_HOST}:${PROJECT_PORT}`;
  }
  adapter.setOrigin(origin);
  try {
    yield* getProgramSaga(adapter, action);
    const liveScheduleMediaLink = yield select(programLiveSchedulesSelector, action.value.programId);
    const vodMediaLink = yield select(programVodMediaStreamsSelector, action.value.programId);
    let liveContentLocations = [];
    let vodContentLocations = [];
    if (liveScheduleMediaLink) {
      const proxyMediaLink = `viewerportal/${liveScheduleMediaLink.split('/viewerportal/')[1]}`;
      const response = yield call(adapter.getMediaLink, `${proxyMediaLink}&outputFormat=JSON`);
      if (response && response.data && response.data.contentLocations) {
        liveContentLocations = response.data.contentLocations;
      }
    }
    if (vodMediaLink) {
      const proxyMediaLink = `viewerportal/${vodMediaLink.split('/viewerportal/')[1]}`;
      const response = yield call(adapter.getMediaLink, `${proxyMediaLink}&outputFormat=JSON`);
      if (response && response.data && response.data.contentLocations) {
        vodContentLocations = response.data.contentLocations;
      }
    }
    const value = { programId: action.value.programId, liveContentLocations, vodContentLocations };
    const valueString = JSON.stringify(value);
    if (valueString === cachedString) {
      return;
    }
    yield put({ type: constants.GET_PROGRAM_CONTENT_LOCATIONS_RESULT, value });
    localStorage.setItem(action.value.programId, valueString);
  } catch (error) {
    yield put({ type: constants.GET_PROGRAM_CONTENT_LOCATIONS_ERROR, value: error });
  }
}


export default function createDefaultSaga(adapter: AdapterType): CreateDefaultSaga {
  return function* (): Generator<*, *, *> {
    yield takeEvery(constants.QUERY_PROGRAMS, queryProgramsSaga, adapter);
    yield takeEvery(constants.QUERY_ADMIN_PROGRAMS, queryAdminProgramsSaga, adapter);
    yield takeEvery(constants.GET_PROGRAM, getProgramSaga, adapter);
    yield takeEvery(constants.GET_PROGRAM_CONTENT_LOCATIONS, getProgramContentLocationsSaga, adapter);
  };
}
