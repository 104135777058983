// @flow

/**
*
* DialogMuteRequired
*
*/

import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

const styles = (theme:Object) => ({ // eslint-disable-line no-unused-vars
});

type Props = {
  onClose: Function,
  open: boolean,
};

function DialogMuteRequired(props: Props) { // eslint-disable-line react/prefer-stateless-function
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby='mute-dialog-title'
    >
      <DialogTitle id='mute-dialog-title'>Unable to Control Browser Volume</DialogTitle>
      <DialogContent>
        <Typography>Volume cannot be controlled remotely due to your <a href="https://developers.google.com/web/updates/2017/09/autoplay-policy-changes">browser‘s video autoplay settings</a>.</Typography>
      </DialogContent>
      <DialogActions>
        <Button type='submit' color='primary' autoFocus onClick={props.onClose}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogMuteRequired;

