// @flow

import type {
  AdapterType,
  QueryProgramsResultType,
  ProgramType,
  SearchOptionsType,
} from './types'; // eslint-disable-line no-unused-vars

(x: AbstractAdapter) => (x: AdapterType); // eslint-disable-line no-unused-expressions
export default class AbstractAdapter {
  shouldFail: boolean;
  xQumuHost: string;
  origin: string;
  domain: string
  viewerPortalUrl: string;
  adminPortalUrl: string;

  setXQumuHost = (xQumuHost: string) => {
    this.xQumuHost = xQumuHost;
  }
  setOrigin = (origin: string) => {
    this.origin = origin;
    this.setPortalUrls();
  }
  setDomain = (domain: string) => {
    this.domain = domain;
    this.setPortalUrls();
  }
  setPortalUrls = (): void => {
    if (this.origin && this.domain) {
      this.viewerPortalUrl = `${this.origin}/viewerportal/services/rest/${this.domain}`;
      this.adminPortalUrl = `${this.origin}/adminportal/services/rest/${this.domain}`;
    }
  }
  constructor() {
    this.shouldFail = false;
  }
  queryPrograms(queryGroupId: string, queryId: string, options: SearchOptionsType): Promise<QueryProgramsResultType> { // eslint-disable-line no-unused-vars
    throw new Error('Method queryPrograms is not implemented.');
  }
  queryAdminPrograms(queryGroupId: string, queryId: string, options: SearchOptionsType): Promise<QueryProgramsResultType> { // eslint-disable-line no-unused-vars
    throw new Error('Method queryAdminPrograms is not implemented.');
  }
  getProgram(programId: string): Promise<ProgramType> { // eslint-disable-line no-unused-vars
    throw new Error('Method getProgram is not implemented.');
  }
  getMediaLink(link: string): Promise<ProgramType> { // eslint-disable-line no-unused-vars
    throw new Error('Method getMediaLink is not implemented.');
  }
}
