// @flow

import type { Saga } from 'redux-saga';
import { takeLatest, call, put } from 'redux-saga/effects';
import * as graphConstants from 'shared-redux/src/graph/constants';
import { logout } from 'shared-redux/src/auth/actions';
import * as constants from './constants';
import type { BandAdapterType, CreateDefaultSaga } from '../types';

export function* joinMeetingSaga(graphAdapter: BandAdapterType, action: ActionType): any {
  try {
    yield call(graphAdapter.joinZoomMeeting, action.value);
  } catch (error) {
    if (error.status === 401) {
      yield put(logout());
    } else {
      yield put({ type: graphConstants.ERROR, value: error });
    }
  }
}

export function* startMeetingSaga(graphAdapter: BandAdapterType, action: ActionType): any {
  try {
    yield call(graphAdapter.startZoomMeeting, action.value);
  } catch (error) {
    if (error.status === 401) {
      yield put(logout());
    } else {
      yield put({ type: graphConstants.ERROR, value: error });
    }
  }
}

export function* endMeetingSaga(graphAdapter: BandAdapterType, action: ActionType): any {
  try {
    yield call(graphAdapter.stopZoomMeeting, action.value);
  } catch (error) {
    if (error.status === 401) {
      yield put(logout());
    } else {
      yield put({ type: graphConstants.ERROR, value: error });
    }
  }
}

export function* updateZoomMeetingSaga(graphAdapter: BandAdapterType, action: ActionType): any {
  try {
    const type = graphConstants.TYPE_ZOOM_MEETING;
    if (action.value.id) {
      yield call(graphAdapter.updateNode, type, action.value.metadata, action.value.id);
    }
  } catch (error) {
    console.error('Error in updating zoom meeting: ', error)
  }
}

export default function createDefaultSaga(graphAdapter: BandAdapterType): CreateDefaultSaga {
  return function* (): Saga<void> {
    yield takeLatest(constants.JOIN_MEETING, joinMeetingSaga, graphAdapter);
    yield takeLatest(constants.START_MEETING, startMeetingSaga, graphAdapter);
    yield takeLatest(constants.END_MEETING, endMeetingSaga, graphAdapter);
    yield takeLatest(constants.UPDATE_ZOOM_MEETING, updateZoomMeetingSaga, graphAdapter);
  };
}
