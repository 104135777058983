// @flow

import { createMuiTheme } from '@material-ui/core/styles';
import { darken, decomposeColor, recomposeColor } from '@material-ui/core/styles/colorManipulator';

export const DEFAULT_PRIMARY = 'rgba(63, 81, 181, 1)';
export const DEFAULT_SECONDARY = 'rgba(245, 0, 87, 1)';

let muiTheme = {};

export const setTheme = (primary: string, secondary?: string) => {
  muiTheme = createMuiTheme({
    navigationWidth: 190,
    typography: {
      useNextVariants: true,
    },
    palette: {
      primary: {
        main: primary,
      },
      secondary: {
        main: secondary || DEFAULT_SECONDARY,
      },
    },
  });
};
setTheme(DEFAULT_PRIMARY, DEFAULT_SECONDARY);
export const getTheme = () => muiTheme;

export const getBackgroundGradient = (color1: string, color2: string) => {
  const { values: primary } = decomposeColor(color1);
  const { values: secondary } = decomposeColor(color2);
  const mixRgb = [(0.75 * primary[0] + 0.25 * secondary[0]) / 2, (0.75 * primary[1] + 0.25 * secondary[1]) / 2, (0.75 * primary[2] + 0.25 * secondary[2]) / 2];
  const mix = recomposeColor({ type: 'rgb', values: mixRgb });
  return `radial-gradient(circle, ${darken(color1, 0.5)} 10%, ${mix} 60%, ${darken(color1, 0.7)} 100%)`;
};
