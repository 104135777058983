// @flow

import * as constants from './constants';
import type { VideoElephantType } from './types';
import type { ActionType } from '../types';

export function query(queryGroupId: string, options: Object): ActionType {
  const queryId = JSON.stringify(options);
  return {
    type: constants.QUERY,
    value: {
      queryGroupId,
      queryId,
      options,
    },
  };
}

export function queryWithId(queryGroupId: string, queryId: string, options: Object): ActionType {
  return {
    type: constants.QUERY,
    value: {
      queryGroupId,
      queryId,
      options,
    },
  };
}

export function incrementCount(videoElephant: VideoElephantType): ActionType {
  return {
    type: constants.INCREMENT_COUNT,
    value: videoElephant,
  };
}

export function videoElephantQuery(q: string) {
  return {
    type: constants.VIDEO_ELEPHANT_QUERY,
    value: {
      query: q,
    },
  };
}
