// @flow

export const CREATE_AUTH_CLIENT = 'redux/auth/CREATE_AUTH_CLIENT';
export const CREATE_AUTH_CLIENT_RESULT = 'redux/auth/CREATE_AUTH_CLIENT_RESULT';
export const CREATE_AUTH_CLIENT_ERROR = 'redux/auth/CREATE_AUTH_CLIENT_ERROR';
export const CLEAR_CREATE_AUTH_CLIENT_ERROR = 'redux/auth/CLEAR_CREATE_AUTH_CLIENT_ERROR';
export const VALIDATE_TOKEN = 'redux/auth/VALIDATE_TOKEN';
export const VALIDATE_TOKEN_RESULT = 'redux/auth/VALIDATE_TOKEN_RESULT';
export const VALIDATE_TOKEN_ERROR = 'redux/auth/VALIDATE_TOKEN_ERROR';
export const LOGOUT = 'redux/auth/LOGOUT';
export const USER_LOGOUT = 'redux/auth/USER_LOGOUT';
export const CHECK_LINK = 'redux/auth/CHECK_LINK';
export const CHECK_LINK_RESULT = 'redux/auth/CHECK_LINK_RESULT';
export const CHECK_LINK_ERROR = 'redux/auth/CHECK_LINK_ERROR';
export const CLEAR_CHECK_LINK = 'redux/auth/CLEAR_CHECK_LINK';
