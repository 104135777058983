// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { List, Map } from 'immutable';
import { withStyles } from '@material-ui/core/styles';
import withNode from '@bunchtogether/boost-client/dist/components/withNode';
import { accessTokenSelector } from 'shared-redux/src/auth/selectors';
import {
  volumeSelector,
  mutedSelector,
  captionsSelector,
  deviceAccessTokenSelector,
  useMulticastSelector,
  blendExtensionStatusSelector,
  boltClientReadySelector,
} from 'containers/App/selectors';
import LayoutComponentBase from 'components/LayoutComponentBase';
import Video2Hls from 'components/Video2Hls';
import VideoBlend from 'components/VideoBlend';
import VideoSilverlight from 'components/VideoSilverlight';
import boltClient from '@bunchtogether/bolt-client';
import { isBlendUrl, isSilverlightUrl } from '../../lib/blendCheck';

const styles = () => ({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  videoElement: {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
  },
});

type Props = {
  className?: string,
  node?: Map<string, *>,
  active: boolean,
  volume: number,
  muted: boolean,
  classes: ClassesType,
  padding: Object,
  id: string,
  alignment: number,
  fgColor: string,
  bgColor: string,
  captions?: boolean,
  accessToken?: string,
  deviceAccessToken?: string,
  useMulticast: boolean,
  blendExtensionAvailable: boolean,
  boltClientReady: boolean,
};

type State = {
  availableStreams: List<string>,
  currentStream: number,
  src: string
};

export class LayoutComponentVideoStream extends React.Component<Props, State> {
  static getDerivedStateFromProps(props:Props, state:State) {
    const { node, boltClientReady } = props;
    const { currentStream } = state;
    if (!node || !Map.isMap(node)) {
      return null;
    }
    const paths = node.getIn(['metadata', 'urls']);
    if (!paths || paths.size < 1) {
      return null;
    }
    const useBoltUrl = node.getIn(['metadata', 'boltUrl']);
    const useBoltProxy = node.getIn(['metadata', 'boltProxy']);
    if ((useBoltUrl || useBoltProxy) && !boltClientReady) {
      return null;
    }
    const availableStreams = paths.map((path: string) => {
      if (isBlendUrl(path)) {
        return path;
      }
      if (useBoltUrl) {
        return boltClient.getUrl(path);
      }
      if (useBoltProxy) {
        const url = new URL(path);
        return boltClient.getUrl(`origin/${url.hostname}${url.pathname}${url.search ? `?${url.search}` : ''}`);
      }
      return path;
    });
    return {
      availableStreams,
      src: availableStreams.get(currentStream),
    };
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      availableStreams: List(),
      currentStream: 0,
      src: '',
    };
  }

  shouldComponentUpdate(nextProps:Props, nextState:State) {
    if (
      this.props.id !== nextProps.id ||
      this.props.muted !== nextProps.muted ||
      this.props.volume !== nextProps.volume ||
      this.props.captions !== nextProps.captions ||
      this.props.className !== nextProps.className ||
      this.props.active !== nextProps.active ||
      this.props.alignment !== nextProps.alignment ||
      this.props.fgColor !== nextProps.fgColor ||
      this.props.bgColor !== nextProps.bgColor ||
      this.props.accessToken !== nextProps.accessToken ||
      this.props.deviceAccessToken !== nextProps.deviceAccessToken ||
      this.props.useMulticast !== nextProps.useMulticast ||
      this.props.blendExtensionAvailable !== nextProps.blendExtensionAvailable ||
      this.state.currentStream !== nextState.currentStream ||
      this.state.src !== nextState.src ||
      this.getRoomId() !== this.getRoomId(nextProps)
    ) {
      return true;
    }
    return false;
  }

  getRoomId(props:Props = this.props) {
    const { node } = props;
    if (!node || !Map.isMap(node)) {
      return undefined;
    }
    return node.getIn(['metadata', 'roomId']);
  }

  handleNextStream = () => {
    if (this.state.currentStream < this.state.availableStreams.size) {
      this.setState({
        currentStream: this.state.currentStream += 1,
      });
    } else {
      this.setState({
        currentStream: 0,
      });
    }
  }

  renderVideoElement() {
    const { id, classes, active, muted, volume, captions, node, accessToken, deviceAccessToken, blendExtensionAvailable, useMulticast, boltClientReady } = this.props;
    const { src, availableStreams } = this.state;
    const roomId = this.getRoomId();
    const hasFallbackStreams = List.isList(availableStreams) && availableStreams.size > 1;
    const appKey = node && Map.isMap(node) ? node.getIn(['metadata', 'appKey']) : null;
    if (appKey && blendExtensionAvailable && useMulticast && boltClientReady) {
      return (
        <VideoBlend
          id={id}
          src={boltClient.getUrl(`api/1.0/rtmp/${appKey}/stream.sdp`)}
          className={classes.videoElement}
          controls={active}
          muted={muted}
          volume={volume}
          playsInline={active}
          active={active}
          autoPlay={active}
          captions={captions}
          handleNextStream={hasFallbackStreams ? this.handleNextStream : null}
        />
      );
    } else if (roomId && blendExtensionAvailable && useMulticast && boltClientReady) {
      return (
        <VideoBlend
          id={id}
          src={boltClient.getUrl(`stream/sdp/${roomId}.sdp`)}
          className={classes.videoElement}
          controls={active}
          muted={muted}
          volume={volume}
          playsInline={active}
          active={active}
          autoPlay={active}
          captions={captions}
          handleNextStream={hasFallbackStreams ? this.handleNextStream : null}
        />
      );
    } else if (isSilverlightUrl(src)) {
      const sourceAddress = node && Map.isMap(node) ? node.getIn(['metadata', 'sourceAddress']) : null;
      return (<VideoSilverlight
        id={id}
        src={src}
        sourceAddress={sourceAddress}
        className={classes.videoElement}
        controls={active}
        muted={muted}
        volume={volume || 0}
        active={active}
        handleNextStream={hasFallbackStreams ? this.handleNextStream : null}
      />);
    } else if (isBlendUrl(src)) {
      return (
        <VideoBlend
          id={id}
          src={src}
          className={classes.videoElement}
          controls={active}
          muted={muted}
          volume={volume}
          playsInline={active}
          active={active}
          autoPlay={active}
          captions={captions}
          handleNextStream={hasFallbackStreams ? this.handleNextStream : null}
        />
      );
    }
    const enc = node && Map.isMap(node) ? node.getIn(['metadata', 'application/x-mpegURL']) : null;
    return (
      <Video2Hls
        id={id}
        src={src}
        className={classes.videoElement}
        controls={active}
        muted={muted}
        volume={volume}
        playsInline={active}
        active={active}
        autoPlay={active}
        captions={captions}
        hasKey={!!enc}
        accessToken={accessToken || deviceAccessToken}
        handleNextStream={hasFallbackStreams ? this.handleNextStream : null}
      />
    );
  }

  render() {
    const { classes, className, fgColor, bgColor, alignment, padding, node } = this.props;

    if (!Map.isMap(node)) {
      return null;
    }

    return (
      <LayoutComponentBase
        className={className}
        color={fgColor || 'inherit'}
        backgroundColor={bgColor || 'inherit'}
        alignment={alignment}
        padding={padding}
      >
        <div className={classes.container}>
          {this.renderVideoElement()}
        </div>
      </LayoutComponentBase>
    );
  }
}

const withConnect = connect(
  (state: StateType): Object => ({
    blendExtensionAvailable: blendExtensionStatusSelector(state),
    useMulticast: useMulticastSelector(state),
    volume: volumeSelector(state),
    muted: mutedSelector(state),
    captions: captionsSelector(state),
    accessToken: accessTokenSelector(state),
    deviceAccessToken: deviceAccessTokenSelector(state),
    boltClientReady: boltClientReadySelector(state),
  }),
  (dispatch: Function) => bindActionCreators({ }, dispatch),
);

export default compose(
  withStyles(styles),
  withConnect,
  withNode(),
)(LayoutComponentVideoStream);
