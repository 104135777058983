/**
 * Create the store with dynamic reducers
 */

import { createStore, applyMiddleware, compose } from 'redux';
import { fromJS } from 'immutable';
import { routerMiddleware } from 'react-router-redux';
import createSagaMiddleware from 'redux-saga';
import appSaga from 'containers/App/saga';
import createAuthSagas from 'shared-redux/src/auth/sagas';
import createGraphSagas from 'shared-redux/src/graph/sagas';
import createVccSagas from 'shared-redux/src/vcc/sagas';
import createVideoElephantSagas from 'shared-redux/src/video-elephant/sagas';

import createReducer from './reducers';

import createAppSagas from './vendor/band-redux/src/app/sagas';
import createZoomSagas from './vendor/band-redux/src/zoom/sagas';
import createBluescapeSagas from './vendor/band-redux/src/bluescape/sagas';

import AuthAdapter from './adapters/AuthAdapter';
import GraphAdapter from './adapters/GraphAdapter';
import VccAdapter from './adapters/VccAdapter';
import VideoElephantAdapter from './adapters/VideoElephantAdapter';

import safeReload from './lib/safe-reload';

const authAdapter = new AuthAdapter();
const graphAdapter = new GraphAdapter();
const vccAdapter = new VccAdapter();
const videoElephantAdapter = new VideoElephantAdapter();

const sagaMiddleware = createSagaMiddleware({
  onError: (error) => {
    console.error('Error in saga middleware: ', error);
    safeReload();
  },
});

export default function configureStore(initialState = {}, history) {
  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [
    sagaMiddleware,
    routerMiddleware(history),
  ];

  const enhancers = [
    applyMiddleware(...middlewares),
  ];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // TODO Try to remove when `react-router-redux` is out of beta, LOCATION_CHANGE should not be fired more than once after hot reloading
        // Prevent recomputing reducers for `replaceReducer`
        shouldHotReload: false,
      })
      : compose;
  /* eslint-enable */

  const store = createStore(
    createReducer(),
    fromJS(initialState),
    composeEnhancers(...enhancers),
  );

  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.injectedReducers = {}; // Reducer registry
  store.injectedSagas = {}; // Saga registry

  sagaMiddleware.run(createAuthSagas(authAdapter));
  sagaMiddleware.run(createGraphSagas(graphAdapter));
  sagaMiddleware.run(createAppSagas(graphAdapter));
  sagaMiddleware.run(createZoomSagas(graphAdapter));
  sagaMiddleware.run(createBluescapeSagas(graphAdapter));
  sagaMiddleware.run(createVccSagas(vccAdapter));
  sagaMiddleware.run(createVideoElephantSagas(videoElephantAdapter));
  sagaMiddleware.run(appSaga);

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(createReducer(store.injectedReducers));
    });
  }

  return store;
}
