// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { List, Map } from 'immutable';
import { withStyles } from '@material-ui/core/styles';
import withNode from '@bunchtogether/boost-client/dist/components/withNode';
import { getProgramContentLocations } from 'shared-redux/src/vcc/actions';
import { programContentLocationsSelector } from 'shared-redux/src/vcc/selectors';
import {
  volumeSelector,
  mutedSelector,
  captionsSelector,
  userAgentParamsSelector,
  blendExtensionStatusSelector,
} from 'containers/App/selectors';
import type { Map as MapType } from 'immutable';
import LayoutComponentBase from 'components/LayoutComponentBase';
import QumuVccProgram from 'components/QumuVccProgram';
import Video2 from 'components/Video2';
import Video2Hls from 'components/Video2Hls';
import VideoBlend from 'components/VideoBlend';
import { isBlendUrl } from '../../lib/blendCheck';

const styles = () => ({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  videoElement: {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  icon: {
    width: '30%',
    height: '30%',
    color: 'grey',
  },
  iconContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  text: {
    color: 'grey',
  },
});

type Props = {
  id: string,
  className?: string,
  node?: MapType<string, *>,
  color?: string,
  backgroundColor?: string,
  active: boolean,
  getProgramContentLocations: Function,
  contentLocations: MapType<List>, // eslint-disable-line
  volume: number,
  muted: boolean,
  classes: ClassesType,
  blendExtensionAvailable: boolean,
  userAgentParams: MapType<string, *>,
  padding: Object,
  captions?: boolean,
};

type State = {
  liveStreams: ListType<string>,
  vodStreams: ListType<string>,
  currentStream: number,
  contentType: string | null,
};

export class LayoutComponentQumuVccProgram extends React.Component<Props, State> {
  static getDerivedStateFromProps(props: Props, state: State) {
    const { node, contentLocations } = props;
    const { currentStream } = state;
    if (!node || !Map.isMap(node)) {
      return null;
    }
    if (!contentLocations || !Map.isMap(contentLocations) || !contentLocations.get('live') || !contentLocations.get('vod')) {
      return null;
    }
    const liveStreams = contentLocations.get('live').map((loc) => loc.get('url'));
    const vodStreams = contentLocations.get('vod').map((loc) => loc.get('url'));
    let contentType = null;
    if (liveStreams.size || vodStreams.size) {
      contentType = liveStreams.size > 0 ? 'live' : 'vod';
    }
    return {
      liveStreams,
      vodStreams,
      contentType,
      currentStream,
    };
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      liveStreams: List(),
      vodStreams: List(),
      currentStream: 0,
      contentType: null,
    };
  }

  componentDidMount() {
    this.getProgramContentLocations();
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    if (this.state.currentStream !== nextState.currentStream) {
      return true;
    }
    if (nextProps !== this.props) {
      return true;
    }
    if (nextState !== this.state) {
      return true;
    }
    return false;
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.node !== prevProps.node || this.props.active !== prevProps.active) {
      this.getProgramContentLocations();
    }
  }

  getProgramContentLocations = () => {
    const { node, active } = this.props;
    if (!node || !Map.isMap(node) || !active) {
      return;
    }
    const altPlayer = node.getIn(['metadata', 'altPlayer']);
    if (!altPlayer) {
      return;
    }
    const vccProgramId = node.getIn(['metadata', 'vccProgramId']);
    const hostname = node.getIn(['metadata', 'hostname']);
    const port = node.getIn(['metadata', 'port']);
    const id = node.getIn(['metadata', 'vccAccountId']);
    const domain = node.getIn(['metadata', 'domain']);
    if (vccProgramId && hostname && port && id && domain) {
      this.props.getProgramContentLocations(vccProgramId, { hostname, port, id, domain });
    }
  }

  getLiveSrc() {
    if (this.state.liveStreams.isEmpty()) {
      return null;
    }
    return this.state.liveStreams.get(this.state.currentStream);
  }

  getVodSrc() {
    if (this.state.vodStreams.isEmpty()) {
      return null;
    }
    const currStream = this.state.vodStreams.get(this.state.currentStream);
    if (currStream && (currStream.includes('https://') || currStream.includes('http://'))) {
      return currStream;
    }
    return null;
  }

  handleNextStream = () => {
    const { contentType, liveStreams, vodStreams, currentStream } = this.state;
    const availableStreamsLength = contentType === 'live' ? liveStreams.size : vodStreams.size;
    if (currentStream < availableStreamsLength) {
      this.setState({ currentStream: this.state.currentStream += 1 });
    }
  }

  render() {
    const { active, id, className, node, userAgentParams, classes, muted, volume, blendExtensionAvailable, padding, captions } = this.props;
    if (!node || !Map.isMap(node) || !userAgentParams || !Map.isMap(userAgentParams)) {
      return null;
    }
    const browserName = userAgentParams.get('browserName');
    const color = node.getIn(['metadata', 'color']);
    const backgroundColor = node.getIn(['metadata', 'backgroundColor']);
    const alignment = node.getIn(['metadata', 'alignment']);
    const altPlayer = node.getIn(['metadata', 'altPlayer']);
    const { contentType } = this.state;
    if (altPlayer && active && typeof contentType !== 'string') {
      return null;
    }
    const hasLiveFallbackStreams = this.state.liveStreams.size > 1;
    const hasVodFallbackStreams = this.state.vodStreams.size > 1;
    let player;
    if (active) {
      if (altPlayer && contentType === 'vod') {
        player = (<div className={classes.container}><Video2
          id={id}
          src={this.getVodSrc()}
          className={classes.videoElement}
          muted={muted}
          volume={volume}
          controls={active}
          playsInline={active}
          autoPlay={active}
          active={active}
          loop={active}
          captions={captions}
          handleNextStream={hasVodFallbackStreams ? this.handleNextStream : null}
        /></div>);
      } else if (altPlayer && contentType === 'live' && (browserName && browserName.includes('Safari'))) {
        if (isBlendUrl(this.getLiveSrc())) {
          player = (<div className={classes.container}><VideoBlend
            id={id}
            src={this.getLiveSrc()}
            className={classes.videoElement}
            controls={active}
            muted={muted}
            volume={volume}
            playsInline={active}
            active={active}
            autoPlay={active}
            captions={captions}
            handleNextStream={hasLiveFallbackStreams ? this.handleNextStream : null}
          /></div>);
        } else {
          player = (<div className={classes.container}><Video2
            id={id}
            src={this.getLiveSrc()}
            className={classes.videoElement}
            muted={muted}
            volume={volume}
            controls={active}
            playsInline={active}
            autoPlay={active}
            active={active}
            captions={captions}
            handleNextStream={hasLiveFallbackStreams ? this.handleNextStream : null}
          /></div>);
        }
      } else if (altPlayer && contentType === 'live') {
        const isBlendUrlStatus = isBlendUrl(this.getLiveSrc());
        if (isBlendUrl(this.getLiveSrc())) {
          player = (<div className={classes.container}><VideoBlend
            id={id}
            src={this.getLiveSrc()}
            className={classes.videoElement}
            controls={active}
            muted={muted}
            volume={volume}
            playsInline={active}
            active={active}
            autoPlay={active}
            captions={captions}
            handleNextStream={hasLiveFallbackStreams ? this.handleNextStream : null}
          /></div>);
        } else {
          player = (<div className={classes.container}><Video2Hls
            id={id}
            src={this.getLiveSrc()}
            className={classes.videoElement}
            muted={muted}
            volume={volume}
            controls={active}
            playsInline={active}
            autoPlay={active}
            active={active}
            captions={captions}
            handleNextStream={hasLiveFallbackStreams ? this.handleNextStream : null}
            debug={isBlendUrlStatus && blendExtensionAvailable}
          /></div>);
        }
      } else {
        player = (<QumuVccProgram id={id} active={active} />);
      }
    } else {
      player = (<QumuVccProgram id={id} active={active} />);
    }
    return (
      <LayoutComponentBase
        className={className}
        color={color === 'inherit' ? this.props.color : color}
        backgroundColor={backgroundColor === 'inherit' ? this.props.backgroundColor : backgroundColor}
        alignment={alignment}
        padding={padding}
      >
        {player}
      </LayoutComponentBase>
    );
  }
}

const withConnect = connect(
  (state: StateType, props: {node?: MapType<string, *>}): Object => {
    const data:Object = {
      volume: volumeSelector(state),
      muted: mutedSelector(state),
      captions: captionsSelector(state),
      userAgentParams: userAgentParamsSelector(state),
      blendExtensionAvailable: blendExtensionStatusSelector(state),
    };
    const { node } = props;
    if (node && Map.isMap(node)) {
      const vccProgramId = node.getIn(['metadata', 'vccProgramId']);
      if (vccProgramId) {
        data.contentLocations = programContentLocationsSelector(state, vccProgramId);
      }
    }
    return data;
  },
  (dispatch: Function) => bindActionCreators({ getProgramContentLocations }, dispatch),
);


export default compose(
  withStyles(styles),
  withNode(),
  withConnect,
)(LayoutComponentQumuVccProgram);
