// @flow

import * as constants from './constants';
import type { ActionType } from '../types';

export function validateToken(authData: Object): ActionType {
  return {
    type: constants.VALIDATE_TOKEN,
    value: authData,
  };
}

export function logout(): ActionType {
  return {
    type: constants.LOGOUT,
    value: null,
  };
}

export function userLogout(): ActionType {
  return {
    type: constants.USER_LOGOUT,
    value: null,
  };
}

export function createAuthClient(teamName: string, link: string, stripeToken: string): ActionType {
  return {
    type: constants.CREATE_AUTH_CLIENT,
    value: {
      teamName,
      link,
      stripeToken,
    },
  };
}

export function clearCreateAuthClientError(): ActionType {
  return {
    type: constants.CLEAR_CREATE_AUTH_CLIENT_ERROR,
    value: null,
  };
}

export function checkLink(link: string): ActionType {
  return {
    type: constants.CHECK_LINK,
    value: link,
  };
}

export function clearCheckLink(): ActionType {
  return {
    type: constants.CLEAR_CHECK_LINK,
    value: null,
  };
}
