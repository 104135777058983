// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { is, List } from 'immutable';
import { withStyles } from '@material-ui/core/styles';
import boltClient from '@bunchtogether/bolt-client';
import type { VideoElephantType } from 'shared-redux/src/video-elephant/types';
import { boltClientReadySelector } from 'containers/App/selectors';
import './styles.css';

const styles = () => ({
  image: {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  imageNext: {
    position: 'absolute',
  },
});

type Props = {
  classes: ClassesType,
  videos: List<VideoElephantType>,
  boltClientReady: boolean,
};

type State = {
  activeThumbnail: ?number,
};

export class LayoutComponentContentLibraryPlaylistPreview extends React.Component<Props, State> {
  state = {
    activeThumbnail: null,
  }

  componentDidMount() {
    const { videos } = this.props;
    if (List.isList(videos)) {
      this.startPreview(videos);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { videos } = this.props;
    if (List.isList(videos) && !is(videos, prevProps.videos)) {
      this.startPreview(videos);
    }
  }


  componentWillUnmount() {
    this.clearPreviewInterval();
  }

  clearPreviewInterval = () => {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  startPreview = (videos: List<VideoElephantType>) => {
    this.clearPreviewInterval();
    if (videos.size === 0) {
      return;
    }
    this.setState({ activeThumbnail: 0 });
    this.intervalId = setInterval(() => {
      this.setState({ activeThumbnail: (this.state.activeThumbnail + 1) % this.props.videos.size });
    }, 5000);
  }

  intervalId: any;

  render() {
    const { classes, videos, boltClientReady } = this.props;
    const { activeThumbnail } = this.state;
    if (activeThumbnail === null) {
      return 'Content Library Playlist has no available videos';
    }
    if (!boltClientReady) {
      return null;
    }
    const src1 = boltClient.getUrl((videos.get(activeThumbnail).get('thumbnail') || '').replace(/^https?:\/\//, 'no-expire/'));
    const src2 = boltClient.getUrl((videos.get((activeThumbnail + 1) % videos.size).get('thumbnail') || '').replace(/^https?:\/\//, 'no-expire/'));
    return (
      <React.Fragment>
        <img
          src={src2}
          key={src2}
          className={`${classes.image} ${classes.imageNext}`}
          alt=''
        />
        <img
          src={src1}
          key={src1}
          className={`${classes.image} fade-in`}
          alt=''
        />
      </React.Fragment>
    );
  }
}

const withConnect = connect((state:StateType) => ({
  boltClientReady: boltClientReadySelector(state),
}), (dispatch: Function) => bindActionCreators({}, dispatch));

export default compose(withStyles(styles), withConnect)(LayoutComponentContentLibraryPlaylistPreview);
