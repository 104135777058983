// @flow

import * as React from 'react';
import { List, Map, is } from 'immutable';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { getIsZoomRoomAvailable } from 'blend2-client';
import { withStyles } from '@material-ui/core/styles';
import LayoutComponentBase from 'components/LayoutComponentBase';
import LayoutComponentVideoStream from 'components/LayoutComponentVideoStream';
import Typography from '@material-ui/core/Typography';
import withNode from '@bunchtogether/boost-client/dist/components/withNode';
import withAncestors from '@bunchtogether/boost-client/dist/components/withAncestors';
import { mouseActiveSelector } from 'containers/App/selectors';
import Fab from '@material-ui/core/Fab';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import { TYPE_VIDEO_STREAM } from 'shared-redux/src/graph/constants';
import { joinLocalZoomMeeting, leaveZoomMeeting, setZoomMeetingId, setZoomMeetingNodeId, closeDialog } from 'containers/App/actions';
import zoomLogo from '../../images/ZoomLogo.svg';

const styles = (theme:Object) => ({
  overlay: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    pointerEvents: 'none',
  },
  fab: {
    position: 'absolute',
    top: theme.spacing(7),
    left: theme.spacing(2),
    transition: 'opacity 0.5s',
    '&:hover': {
      opacity: 1,
    },
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  noOpacity: {
    opacity: 0,
  },
  logo: {
    width: '30%',
    marginBottom: theme.spacing(2),
  },
  meetingId: {
    color: '#666',
    fontSize: '5vw',
    marginTop: theme.spacing(2),
  },
  topic: {
    fontSize: '3.5vw',
    marginTop: theme.spacing(4),
  },
});

function formatMeetingNumber(meetingNumber: string) {
  const num = typeof meetingNumber === 'number' ? meetingNumber.toString() : meetingNumber;
  const length = num.length;
  if (length < 4) {
    return num;
  }
  if (length < 7) {
    return `${num.slice(0, 3)}-${num.slice(3)}`;
  }
  if (length < 11) {
    return `${num.slice(0, 3)}-${num.slice(3, 6)}-${num.slice(6)}`;
  }
  return `${num.slice(0, 3)}-${num.slice(3, 7)}-${num.slice(7, 11)}`;
}

type Props = {
  classes: ClassesType,
  className?: string,
  node: Map<string, any>,
  padding: Object,
  active?: boolean, // eslint-disable-line
  mouseActive: boolean,
  ancestors: List<string>,
  joinLocalZoomMeeting: Function,
  leaveZoomMeeting: Function,
  setZoomMeetingId: Function,
  setZoomMeetingNodeId: Function,
  closeDialog: Function,
};

type State = {
  hasLiveStream: boolean,
};

class LayoutComponentZoomMeeting extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.meetingId = null;
    this.state = {
      hasLiveStream: false,
    };
  }

  componentDidMount() {
    if (this.props.node) {
      this.handleJoinMeeting();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (!is(prevProps.node, this.props.node) && this.props.node) {
      this.handleJoinMeeting();
    }
  }

  componentWillUnmount() {
    this.props.setZoomMeetingId('');
    this.props.setZoomMeetingNodeId('');
    this.handleLeaveMeeting();
    this.props.closeDialog('password');
  }

  getButtonStyle = () => {
    const { classes, mouseActive } = this.props;
    if (mouseActive) {
      return classes.fab;
    }
    return `${classes.noOpacity} ${classes.fab}`;
  }

  openTab = (joinUrl: string) => {
    const win = window.open(joinUrl, '_blank');
    setTimeout(() => {
      if (win) {
        win.close();
      }
    }, 5000);
  }

  handleLeaveMeeting = async () => {
    if (this.props.active) {
      this.props.leaveZoomMeeting();
    }
  }

  handleJoinMeeting = async () => {
    if (!this.props.active) {
      return;
    }
    const { node } = this.props;
    if (!node || !Map.isMap(node)) {
      await new Promise((resolve) => setTimeout(() => resolve(), 500));
      await this.handleJoinMeeting();
      return;
    }
    const id = node.getIn(['metadata', 'id']) || '';
    const password = node.getIn(['metadata', 'password']) || '';
    const needsPassword = node.getIn(['metadata', 'needsPassword']) || '';
    this.props.setZoomMeetingId(id);
    this.props.setZoomMeetingNodeId(node.get('id'));
    if (id === this.meetingId && !needsPassword) {
      return;
    }
    this.meetingId = id;
    const status = node.getIn(['metadata', 'status']) || '';
    if (status === 'ENDED') {
      return;
    }
    const joinUrl = node.getIn(['metadata', 'join_url']) || '';
    if (!id || !joinUrl) {
      return;
    }
    const liveStreamData = node.getIn(['metadata', 'liveStreamData']);
    const isZoomRoomAvailable = await getIsZoomRoomAvailable();
    if (isZoomRoomAvailable) {
      try {
        this.props.joinLocalZoomMeeting(id.toString(), password);
        return;
      } catch (e) {
        console.log('Zoom Rooms not available');
      }
    }
    this.openTab(joinUrl);
    if (liveStreamData) {
      this.setState({ hasLiveStream: true });
    }
  }

  meetingId: string | null;

  render() {
    const { className, node, padding, classes, ancestors, active } = this.props;
    if (!node || !Map.isMap(node)) {
      return null;
    }
    const { hasLiveStream } = this.state;
    const id = node.getIn(['metadata', 'id']) || '';
    const topic = node.getIn(['metadata', 'topic']) || '';
    const joinUrl = node.getIn(['metadata', 'join_url']) || '';
    const videoStreamId = List.isList(ancestors) ? ancestors.first() : null;
    const formattedId = formatMeetingNumber(id);
    return (
      <LayoutComponentBase
        className={className}
        color={'inherit'}
        backgroundColor={'inherit'}
        alignment={0}
        padding={padding}
      >
        {hasLiveStream && videoStreamId ? (
          <LayoutComponentVideoStream id={videoStreamId} active={active} />
        ) : (
          <div className={classes.overlay}>
            <img src={zoomLogo} className={classes.logo} alt='Zoom Logo' />
            <Typography className={classes.topic}>{topic}</Typography>
            <Typography className={classes.meetingId}>{formattedId}</Typography>
          </div>
        )}
        {joinUrl && active ? (
          <Fab
            className={this.getButtonStyle()}
            variant='extended'
            onClick={() => this.openTab(joinUrl)}
            color='primary'
          >
            <MeetingRoomIcon className={classes.icon} />
            Join Meeting
          </Fab>
        ) : null}
      </LayoutComponentBase>
    );
  }
}

const withConnect = connect((state: StateType) => ({
  mouseActive: mouseActiveSelector(state),
}), (dispatch: Function) => bindActionCreators({ joinLocalZoomMeeting, leaveZoomMeeting, setZoomMeetingId, setZoomMeetingNodeId, closeDialog }, dispatch));

export default compose(
  withStyles(styles),
  withConnect,
  withNode(),
  withAncestors({ type: TYPE_VIDEO_STREAM }),
)(LayoutComponentZoomMeeting);

