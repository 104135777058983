// @flow

/**
*
* KeiserRoomFilterSet
*
*/

import * as React from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { List } from 'immutable';
import { withStyles } from '@material-ui/core/styles';
import withDescendents from '@bunchtogether/boost-client/dist/components/withDescendents';
import { TYPE_KEISER_SET } from 'shared-redux/src/graph/constants';
import KeiserSet from 'components/KeiserSet';


const styles = (theme:Object) => ({ // eslint-disable-line no-unused-vars
});

type Props = {
  // classes: ClassesType,
  sets?: List,
};

type State = {};

class KeiserRoomFilterSet extends React.PureComponent<Props, State> { // eslint-disable-line react/prefer-stateless-function
  render() {
    const { sets } = this.props;
    const setId = sets && List.isList(sets) ? sets.first() : '';
    return (
      <KeiserSet id={setId} />
    );
  }
}

const withConnect = connect((state:StateType) => ({
  state,
}), (dispatch: Function) => bindActionCreators({}, dispatch));

export default compose(
  withStyles(styles, { withTheme: true }),
  withConnect,
  withDescendents({
    type: TYPE_KEISER_SET,
    propertyName: 'sets',
    limit: 1,
    sort: 'lastUpdate',
    order: 'desc',
  }),
)(KeiserRoomFilterSet);

