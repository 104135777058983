// @flow

export const SUCCESS = 'redux/node/SUCCESS';
export const ERROR = 'redux/node/ERROR';
export const RESET = 'redux/node/RESET';

export const UPSERT_NODE = 'redux/node/UPSERT_NODE';
export const UPDATE_NODE = 'redux/node/UPDATE_NODE';
export const DELETE_NODE = 'redux/node/DELETE_NODE';
export const DELETE_NODES = 'redux/node/DELETE_NODES';
export const UPSERT_EDGE = 'redux/node/UPSERT_EDGE';
export const DELETE_EDGE = 'redux/node/DELETE_EDGE';
export const UPDATE_EDGE = 'redux/node/UPDATE_EDGE';
export const ADD_PERMISSION = 'redux/node/ADD_PERMISSION';
export const REMOVE_PERMISSION = 'redux/node/REMOVE_PERMISSION';
export const DELETE_TAG = 'redux/node/DELETE_TAG';

export const TYPE_PRIVATE_USER = 'private-user';
export const TYPE_USER = 'user';
export const TYPE_BRIDGE_GROUP = 'bridge-group';
export const TYPE_GROUP = 'group';
export const TYPE_CHANNEL = 'channel';
export const TYPE_CHANNEL_ADMIN_GROUP = 'channel-admin-group';
export const TYPE_POSTER_GROUP = 'poster-group';
export const TYPE_COMMENTER_GROUP = 'commenter-group';
export const TYPE_COMMENT = 'comment';
export const TYPE_COMMENT_APPROVAL_GROUP = 'comment-approval-group';
export const TYPE_STORY = 'story';
export const TYPE_STORY_DRAFT = 'story-draft';
export const TYPE_STORY_APPROVAL_GROUP = 'story-approval-group';
export const TYPE_WIDGET = 'widget';
export const TYPE_IMAGE = 'image';
export const TYPE_VIDEO = 'video';
export const TYPE_VIDEO_STREAM = 'video-stream';
export const TYPE_URL = 'url';
export const TYPE_HTML = 'html';
export const TYPE_QUMU_VCC_PROGRAM = 'qumu-vcc-program';
export const TYPE_DRAWING = 'drawing';
export const TYPE_DEVICE = 'device';
export const TYPE_TEXT = 'text';
export const TYPE_ICON = 'icon';
export const TYPE_BLUESCAPE_ACCOUNT = 'bluescape-account';
export const TYPE_BLUESCAPE_WORKSPACE = 'bluescape-workspace';
export const TYPE_METADATA = 'metadata';
export const TYPE_CLOCK = 'clock';
export const TYPE_PDF = 'pdf';
export const TYPE_FLAG = 'flag';
export const TYPE_CONTENT_LIBRARY_PLAYLIST = 'content-library-playlist';
export const TYPE_CAROUSEL = 'carousel';
export const TYPE_SPACER = 'spacer';
export const TYPE_VCC_ACCOUNT = 'vcc-account';
export const TYPE_SCALA_ACCOUNT = 'scala-account';
export const TYPE_ZOOM_ACCOUNT = 'zoom-account';
export const TYPE_ZOOM_MEETING = 'zoom-meeting';
export const TYPE_SLACK_TEAM = 'slack-team';
export const TYPE_SLACK_CHANNEL = 'slack-channel';
export const TYPE_ADMIN_GROUP = 'admin-group';
export const TYPE_USER_APPROVAL_GROUP = 'user-approval-group';
export const TYPE_CERTIFICATE = 'certificate';
export const TYPE_UPDATER_LINUX = 'updater-linux';
export const TYPE_HUB = 'hub';
export const TYPE_HUB_ADMIN_GROUP = 'hub-admin-group';
export const TYPE_HUB_SECTION = 'hub-section';
export const TYPE_KEISER_ROOM = 'keiser-room';
export const TYPE_KEISER_SET = 'keiser-set';
export const TYPE_EMAIL_DOMAIN_GROUP = 'email-domain-group';
export const TYPE_NAMED_ENTITY = 'named-entity';
export const TYPE_ZOOM_USER_ACCOUNT = 'zoom-user-account';

export const TYPE_BAND_DEVICE = 'band-device';
export const TYPE_BAND_ADMIN_GROUP = 'band-admin-group';
export const TYPE_SCALA_PLAYER = 'scala-player';
export const TYPE_BAND_CHANNEL = 'band-channel';
export const TYPE_BAND_CHANNEL_GROUP = 'band-channel-group';
export const TYPE_BAND_CHANNEL_GROUP_ADMIN_GROUP = 'band-channel-group-admin-group';
export const TYPE_BAND_CHANNEL_ADMIN_GROUP = 'band-channel-admin-group';
export const TYPE_BAND_CHANNEL_GROUPS = 'band-channel-groups';
export const TYPE_BAND_LAYOUT = 'band-layout';
export const TYPE_BAND_LIBRARY = 'band-library';
export const TYPE_BAND_SCREEN_GROUP = 'band-screen-group';
export const TYPE_BAND_SCREEN_GROUP_ADMIN_GROUP = 'band-screen-group-admin-group';
export const TYPE_BAND_SCREEN_GROUPS = 'band-screen-groups';
export const TYPE_BAND_SCREEN_BRIDGE_GROUP = 'band-screen-bridge-group';
export const TYPE_BAND_SCREEN_DEVICE = 'band-screen-device';
export const TYPE_BAND_SCREEN_USER = 'band-screen-user';
export const TYPE_BAND_LIBRARY_GROUP = 'band-library-group';
export const TYPE_BAND_LAYOUT_GROUP = 'band-layout-group';
export const TYPE_BAND_LAYOUT_GROUP_ADMIN_GROUP = 'band-layout-group-admin-group';
export const TYPE_BAND_LAYOUT_GROUPS = 'band-layout-groups';
export const TYPE_BAND_SCHEDULE = 'band-schedule';
export const TYPE_BAND_HARDWARE = 'band-hardware';
export const TYPE_BAND_HARDWARE_GROUP = 'band-hardware-group';

export const TYPE_BEGIN_SOURCE = 'begin-source';
export const TYPE_BEGIN_DESTINATION = 'begin-destination';
export const TYPE_BEGIN_PROFILE = 'begin-profile';
export const TYPE_BEGIN_PROFILE_GROUP = 'begin-profile-group';
export const TYPE_BEGIN_CHANNEL = 'begin-channel';
export const TYPE_BEGIN_CHANNEL_DESTINATION = 'begin-channel-destination';

export const TYPE_BUNCH_USER_GROUP = 'bunch-user-group';
export const TYPE_CONVERSATION = 'conversation';
export const TYPE_MESSAGE = 'message';

export const TYPE_EDGE_TEAM = 'edge-team';
export const TYPE_EDGE_FOLLOWER = 'edge-follower';
export const TYPE_EDGE_USER_EXTERNAL_CHANNEL = 'edge-user-external-channel';
export const TYPE_EDGE_TEAM_DEFAULT_CHANNEL = 'edge-team-default-channel';
export const TYPE_EDGE_TEAM_MANDATORY_CHANNEL = 'edge-team-mandatory-channel';
export const TYPE_EDGE_USER_RECORDINGS_CHANNEL = 'edge-user-recordings-channel';
export const TYPE_EDGE_COMMENTER = 'edge-commenter';

export const PERMISSION_READ = 'READ';
export const PERMISSION_WRITE = 'WRITE';
export const PERMISSION_DELEGATE = 'DELEGATE';
export const PERMISSION_DELETE = 'DELETE';
export const PERMISSION_APPROVE = 'APPROVE';
export const PERMISSION_ADD_CHILD = 'ADD_CHILD';
export const PERMISSION_ADD_CHILD_USER = 'ADD_CHILD_USER';
export const PERMISSION_ADD_CHILD_GROUP = 'ADD_CHILD_GROUP';
export const PERMISSION_ADD_CHILD_STORY = 'ADD_CHILD_STORY';
export const PERMISSION_ADD_CHILD_CHANNEL = 'ADD_CHILD_CHANNEL';
export const PERMISSION_ADD_CHILD_BAND_CHANNEL = 'ADD_CHILD_BAND_CHANNEL';
export const PERMISSION_ADD_CHILD_COMMENT = 'ADD_CHILD_COMMENT';
export const PERMISSION_ADD_CHILD_IMAGE = 'ADD_CHILD_IMAGE';
export const PERMISSION_ADD_CHILD_BAND_DEVICE = 'ADD_CHILD_BAND_DEVICE';
export const PERMISSION_ADD_CHILD_VIDEO = 'ADD_CHILD_VIDEO';
export const PERMISSION_ADD_CHILD_VIDEO_STREAM = 'ADD_CHILD_VIDEO_STREAM';
export const PERMISSION_ADD_CHILD_URL = 'ADD_CHILD_URL';
export const PERMISSION_ADD_CHILD_CLOCK = 'ADD_CHILD_CLOCK';
export const PERMISSION_ADD_CHILD_HTML = 'ADD_CHILD_HTML';
export const PERMISSION_ADD_CHILD_PDF = 'ADD_CHILD_PDF';
export const PERMISSION_ADD_CHILD_FLAG = 'ADD_CHILD_FLAG';
export const PERMISSION_ADD_CHILD_CAROUSEL = 'ADD_CHILD_CAROUSEL';
export const PERMISSION_ADD_CHILD_SPACER = 'ADD_CHILD_SPACER';
export const PERMISSION_ADD_CHILD_QUMU_VCC_PROGRAM = 'ADD_CHILD_QUMU_VCC_PROGRAM';
export const PERMISSION_ADD_CHILD_HUB = 'ADD_CHILD_HUB';

export const SETTING_TEAM_LIMIT_USER_SIGNUP = 'limitUserSignup';

export const FILTER_CIDR = 'FILTER_CIDR';

export const PERMISSION_VIEW_GLOBAL = 'VIEW_GLOBAL';
export const PERMISSION_VIEW_BAND_SCREENS = 'VIEW_BAND_SCREENS';
export const PERMISSION_VIEW_BAND_CHANNELS = 'VIEW_BAND_CHANNELS';
export const PERMISSION_VIEW_BAND_LAYOUTS = 'VIEW_BAND_LAYOUTS';
export const PERMISSION_VIEW_BAND_SCHEDULER = 'VIEW_BAND_SCHEDULER';
export const PERMISSION_VIEW_BAND_MEETINGS = 'VIEW_BAND_MEETINGS';
export const PERMISSION_VIEW_BAND_ALERTS = 'VIEW_BAND_ALERTS';
export const PERMISSION_VIEW_BAND_HARDWARE = 'VIEW_BAND_HARDWARE';


export const CHANNEL_TYPES_IN_TREE = [
  TYPE_BAND_CHANNEL,
  TYPE_BAND_CHANNEL_ADMIN_GROUP,
  TYPE_BAND_CHANNEL_GROUP,
  TYPE_BAND_CHANNEL_GROUP_ADMIN_GROUP,
  TYPE_ADMIN_GROUP,
  TYPE_BAND_SCREEN_GROUP,
  TYPE_BAND_SCREEN_DEVICE,
  TYPE_BAND_SCREEN_USER,
  TYPE_BAND_SCREEN_BRIDGE_GROUP,
  TYPE_BAND_ADMIN_GROUP,
];
