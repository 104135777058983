// @flow

/**
*
* PdfComponent
*
*/

import * as React from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { Document, Page } from 'react-pdf/dist/entry.webpack';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import ArrowForward from '@material-ui/icons/ArrowForward';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { mouseActiveSelector, isKioskSelector } from 'containers/App/selectors';
import 'react-pdf/dist/Page/AnnotationLayer.css';
// import { getPadding } from '../../lib/alignment';


const styles = (theme: Object) => ({ // eslint-disable-line no-unused-vars
  container: {
  },
  innerContainer: {
    overflow: 'auto',
    height: '100%',
    position: 'relative',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  previousButton: {
    position: 'absolute',
    bottom: theme.spacing(4),
    left: 'calc(50% - 85px)',
    transition: 'opacity 0.5s',
  },
  nextButton: {
    position: 'absolute',
    bottom: theme.spacing(4),
    right: 'calc(50% - 85px)',
    transition: 'opacity 0.5s',
  },
  noOpacity: {
    opacity: 0,
  },
});

type Props = {
  classes: ClassesType,
  preview: boolean,
  layoutHeight: number,
  layoutWidth: number,
  size: string,
  url: string | null,
  mouseActive: boolean,
};

type State = {
  numPages: number | null,
  pageNumber: number,
  error: string,
};

class PdfComponent extends React.PureComponent<Props, State> { // eslint-disable-line react/prefer-stateless-function
  state = {
    numPages: null,
    pageNumber: 1,
    error: '',
  }

  onDocumentLoadSuccess = (document) => {
    const { numPages } = document;
    this.setState({
      numPages,
      pageNumber: 1,
    });
  };

  onDocumentLoadError = (error: Object) => {
    if (error && error.message) {
      this.setState({ error: error.message });
    }
  }

  onSourceError = (error: Object) => {
    if (error && error.message) {
      this.setState({ error: error.message });
    }
  }

  getHeight = () => {
    const { layoutHeight, layoutWidth, size } = this.props;
    if (!size || size === 'cover' || size === 'contain') {
      return layoutHeight;
    }
    return layoutWidth * (parseInt(size, 10) / 100);
  }

  getWidth = () => {
    const { layoutWidth, size, preview } = this.props;
    if (preview) {
      return null;
    }
    if (!size || size === 'contain') {
      return null;
    }
    if (size === 'cover') {
      return layoutWidth;
    }
    return layoutWidth * (parseInt(size, 10) / 100);
  }

  changePage = (offset) => this.setState((prevState) => ({
    pageNumber: prevState.pageNumber + offset,
  }));

  previousPage = () => this.changePage(-1);
  nextPage = () => this.changePage(1);

  render() {
    const { classes, url, preview, mouseActive } = this.props;
    if (!url) {
      return null;
    }
    const showPageNav = !preview && !!mouseActive;
    const { pageNumber, numPages, error } = this.state;
    return (
      <React.Fragment>
        <div className={classes.innerContainer}>
          <Document
            options={{
              cMapUrl: 'cmaps/',
              cMapPacked: true,
            }}
            file={url}
            error={error ? <Typography>{error}</Typography> : ''}
            onLoadSuccess={this.onDocumentLoadSuccess}
            onLoadError={this.onDocumentLoadError}
            onSourceError={this.onSourceError}
            loading={<Typography>Loading PDF...</Typography>}
          >
            <Page pageNumber={pageNumber} height={this.getHeight()} width={this.getWidth()} />
          </Document>
        </div>
        <Fab
          color="secondary"
          disabled={pageNumber <= 1}
          onClick={this.previousPage}
          className={showPageNav ? classes.previousButton : `${classes.noOpacity} ${classes.previousButton}`}
        >
          <ArrowBack />
        </Fab>
        <Fab
          color="secondary"
          disabled={pageNumber >= (numPages || 0)}
          onClick={this.nextPage}
          className={showPageNav ? classes.nextButton : `${classes.noOpacity} ${classes.nextButton}`}
        >
          <ArrowForward />
        </Fab>
      </React.Fragment>
    );
  }
}

const withConnect = connect((state: StateType) => ({
  mouseActive: mouseActiveSelector(state),
  isKiosk: isKioskSelector(state),
}), (dispatch: Function) => bindActionCreators({}, dispatch));

export default compose(
  withStyles(styles, { withTheme: true }),
  withConnect,
)(PdfComponent);

