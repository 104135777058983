// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { is, Map } from 'immutable';
import { withStyles } from '@material-ui/core/styles';
import Video2 from 'components/Video2';
import { incrementCount } from 'shared-redux/src/video-elephant/actions';
import { volumeSelector, mutedSelector, boltClientReadySelector } from 'containers/App/selectors';
import type { VideoElephantType } from 'shared-redux/src/video-elephant/types';
import boltClient from '@bunchtogether/bolt-client';

const styles = () => ({
  videoContainer: {
    position: 'absolute',
    zIndex: 10,
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
  video: {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
  },
});

type Props = {
  id: string,
  classes: ClassesType,
  incrementCount: Function,
  className: string,
  volume: number,
  muted: boolean,
  video: ?Map<VideoElephantType>,
  handleOnEnded: Function,
  style: Object,
  boltClientReady: boolean,
};

export class LayoutComponentContentLibraryPlaylistVideo extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.activeWatchTime = null;
    this.activeLastTick = null;
  }

  componentDidMount() {
    if (this.props.video) {
      this.resetTime();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (!is(this.props.video, prevProps.video)) {
      this.resetTime();
    }
  }

  resetTime() {
    const { video } = this.props;
    this.activeWatchTime = video ? 0 : null;
    this.activeLastTick = video ? Date.now() : null;
  }


  handleOnTimeUpdate = () => {
    const { video } = this.props;
    const { activeWatchTime, activeLastTick } = this;
    if (typeof activeWatchTime !== 'number' || typeof activeLastTick !== 'number' || !video || !Map.isMap(video)) {
      return;
    }
    const currentTick = Date.now();
    const watchTime = activeWatchTime + currentTick - activeLastTick;
    if (watchTime > 1000) {
      this.props.incrementCount(video.toJS());
      this.activeWatchTime = null;
      this.activeLastTick = null;
    } else {
      this.activeWatchTime = watchTime;
      this.activeLastTick = currentTick;
    }
  }

  activeWatchTime: ?number;
  activeLastTick: ?number;

  render() {
    const { id, className, classes, video, volume, muted, style, boltClientReady } = this.props;
    if (video && boltClientReady && Map.isMap(video)) {
      return (
        <div className={`${classes.videoContainer} ${className}`} style={style}>
          <Video2
            id={id}
            src={boltClient.getUrl(video.get('url').replace(/^https?:\/\//, 'no-expire/'))}
            active
            autoPlay
            controls
            muted={muted}
            volume={volume}
            playsInline
            className={classes.video}
            onEnded={this.props.handleOnEnded}
            onTimeUpdate={this.handleOnTimeUpdate}
          />
        </div>
      );
    }
    return null;
  }
}

const withConnect = connect(
  (state: StateType): Object => ({
    volume: volumeSelector(state),
    muted: mutedSelector(state),
    boltClientReady: boltClientReadySelector(state),
  }), (dispatch: Function) => bindActionCreators({ incrementCount }, dispatch),
);

export default compose(
  withStyles(styles),
  withConnect,
)(LayoutComponentContentLibraryPlaylistVideo);
