// @flow

import { Map as ImmutableMap } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import boltClient from '@bunchtogether/bolt-client';
import { compose, bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import withNode from '@bunchtogether/boost-client/dist/components/withNode';
import LayoutComponent from 'components/LayoutComponent';
import LayoutPane from 'components/LayoutPane';
import { boltClientReadySelector } from 'containers/App/selectors';
import { getBackgroundPosition } from '../../lib/alignment';

const styles = (theme:Object) => ({ // eslint-disable-line no-unused-vars
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  element: {
    transformOrigin: 'top left',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    alignItems: 'stretch',
  },
  component: {
    flex: 1,
  },
});

type RowType = {
  type: "row",
  flex: number,
  id: string,
  children: Array<ColumnType | ComponentType>
};

type ColumnType = {
  type: "column",
  flex: number,
  id: string,
  children: Array<RowType | ComponentType>
};

type ComponentType = {
  id: string,
  type: "component",
  backgroundImage?: Object,
  backgroundImageSize?: string,
  backgroundImageAlignment?: number,
  padding?: Object,
  alignment?: number,
  size?: string,
  fgColor?: string,
  bgColor?: string,
  carouselStyles?: Object,
};

type Props = {
  classes: ClassesType,
  node: ImmutableMap,
  thumbnail?: boolean,
  boltClientReady: boolean, // eslint-disable-line react/no-unused-prop-types,
  width: number,
  height: number,
  offsetWidth: number,
  channelName?: string,
  channelDescription?: string,
};

type State = {
  rootStyle: Object,
  elementStyle?: Object,
};

class Layout extends React.PureComponent<Props, State> { // eslint-disable-line react/prefer-stateless-function
  static defaultProps = {
    width: 1280,
    height: 720,
  }

  static getDerivedStateFromProps(props, prevState) {
    if (
      props.offsetWidth !== prevState.offsetWidth ||
      props.width !== prevState.width ||
      props.height !== prevState.height
    ) {
      return {
        elementStyle: {
          width: props.width,
          height: props.height,
          transform: `scale(${props.offsetWidth / props.width})`,
          WebkitTransform: `scale(${props.offsetWidth / props.width})`,
        },
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      rootStyle: this.getRootStyle(props),
      offsetWidth: props.offsetWidth,
      elementStyle: {
        width: props.width,
        height: props.height,
        transform: `scale(${props.offsetWidth / props.width})`,
        WebkitTransform: `scale(${props.offsetWidth / props.width})`,
      },
    };
  }

  componentDidUpdate(prevProps:Props) {
    if (this.props.node !== prevProps.node && this.props.node || prevProps.boltClientReady !== this.props.boltClientReady && this.props.boltClientReady) {
      this.setRootStyle(this.props);
    }
  }

  setRootStyle = (props: Props) => {
    this.setState({
      rootStyle: this.getRootStyle(props),
    });
  }

  getRootStyle(props) {
    const style:Object = {
      overflowX: 'hidden',
      overflowY: 'hidden',
    };
    const { node, boltClientReady } = props;
    if (node && ImmutableMap.isMap(node) && node.get('metadata')) {
      const color = node.getIn(['metadata', 'color']);
      const backgroundColor = node.getIn(['metadata', 'backgroundColor']);
      const backgroundImage = node.getIn(['metadata', 'backgroundImage']);
      const backgroundImageSize = node.getIn(['metadata', 'backgroundImageSize']);
      const backgroundImageAlignment = node.getIn(['metadata', 'backgroundImageAlignment']);
      style.color = color;
      style.backgroundColor = backgroundColor;
      if (backgroundImage && backgroundImageSize && boltClientReady) {
        style.backgroundRepeat = 'no-repeat';
        style.backgroundPosition = getBackgroundPosition(backgroundImageAlignment);
        style.backgroundSize = backgroundImageSize;
        style.backgroundImage = `url(${boltClient.getUrl(backgroundImage.get('path'))})`;
      }
    }
    return style;
  }

  root: any;
  rootElement: any;

  renderChildren(container: { children: Array<Object> }) {
    if (!container.children) {
      return null;
    }
    return container.children.map((child) => {
      if (child.type === 'row') {
        return this.renderRow(child);
      }
      if (child.type === 'column') {
        return this.renderColumn(child);
      }
      if (child.type === 'component') {
        return this.renderComponent(child);
      }
      return null;
    });
  }

  renderColumn(container: ColumnType) {
    return (<LayoutPane key={container.id} flex={container.flex} type="column">
      {this.renderChildren(container)}
    </LayoutPane>);
  }

  renderComponent(container: ComponentType) {
    const { classes, thumbnail, width, height } = this.props;
    return (<LayoutComponent
      className={classes.component}
      id={container.id}
      key={container.id}
      thumbnail={thumbnail}
      layoutWidth={width}
      layoutHeight={height}
      padding={container.padding || {}}
      alignment={container.alignment}
      size={container.size}
      fgColor={container.fgColor}
      bgColor={container.bgColor}
      carouselStyles={container.carouselStyles}
      backgroundImage={container.backgroundImage}
      backgroundImageSize={container.backgroundImageSize}
      backgroundImageAlignment={container.backgroundImageAlignment}
      channelName={this.props.channelName}
      channelDescription={this.props.channelDescription}
    />);
  }

  renderRow(container: RowType) {
    return (<LayoutPane key={container.id} flex={container.flex} type="row">
      {this.renderChildren(container)}
    </LayoutPane>);
  }

  render() {
    const { rootStyle, elementStyle } = this.state;
    const { node, classes } = this.props;
    let element = null;
    if (ImmutableMap.isMap(node) && node.get('metadata')) {
      const config = node.getIn(['metadata', 'config']);
      const type = node.getIn(['metadata', 'config', 'type']);
      if (config) {
        const configData = config.toJS();
        if (type === 'row') {
          element = this.renderRow(configData);
        } else if (type === 'column') {
          element = this.renderColumn(configData);
        } else if (type === 'component') {
          element = this.renderComponent(configData);
        }
      }
    }
    return (
      <div style={rootStyle} className={classes.root} ref={(e) => { this.root = e; }}>
        <div style={elementStyle} className={classes.element}>{element}</div>
      </div>
    );
  }
}

const withConnect = connect((state:StateType) => ({
  boltClientReady: boltClientReadySelector(state),
}), (dispatch: Function) => bindActionCreators({}, dispatch));

export default compose(
  withStyles(styles, { withTheme: true }),
  withConnect,
  withNode(),
)(Layout);

