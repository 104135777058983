// @flow

import NodeRSA from 'node-rsa';
import murmurHash3 from 'murmurhash3js';
import stringify from './json-stringify-deterministic';

export const generatePrivateKey = (size: number = 2048) => new NodeRSA({ b: size }).exportKey('pkcs1-private-pem');

export const generatePublicKey = (privateKey: Object) => privateKey.exportKey('pkcs1-public-pem');

export const parsePrivateKey = (s: string) => new NodeRSA(s, 'pkcs1-private-pem');

export const sign = (privateKey:NodeRSA, ...args:Array<any>) => privateKey.sign(stringify(args), 'hex', 'utf8');

export const verify = (publicKeyString: string, signature: string, ...args: Array<any>) => {
  const publicKey = new NodeRSA(publicKeyString, 'pkcs1-public-pem');
  return publicKey.verify(stringify(args), signature, 'utf8', 'hex');
};

export const generateId = (privateKey: Object) => {
  const hashedId = murmurHash3.x64.hash128(privateKey.exportKey('pkcs1-public-pem'));
  return `${hashedId.slice(0, 8)}-${hashedId.slice(8, 12)}-${hashedId.slice(12, 16)}-${hashedId.slice(16, 20)}-${hashedId.slice(20)}`;
};

export const exampleLogInVerification = (publicKey: string, username: string, password: string) => {
  const id = username.slice(0, 36);
  const date = new Date(username.slice(36));
  if (new Date() - date > 10000) {
    throw new Error('Expired login credentials');
  }
  if (!verify(publicKey, password, username)) {
    throw new Error('Invalid signature');
  }
  return id;
};
