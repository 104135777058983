// @flow

import { v4 as uuidv4 } from 'uuid';
import type { Map as ImmutableMap } from 'immutable';
import * as constants from './constants';

export function addNotification(message: string | React.node, onClose?: Function): ActionType {
  return {
    type: constants.ADD_NOTIFICATION,
    value: {
      id: uuidv4(),
      created: Date.now(),
      message,
      onClose,
    },
  };
}

export function handleLayoutChange(width: number, height: number): ActionType {
  return {
    type: constants.LAYOUT_CHANGE,
    value: {
      width,
      height,
    },
  };
}

export function hideNavigation(): ActionType {
  return {
    type: constants.HIDE_NAVIGATION,
    value: null,
  };
}

export function showNavigation(): ActionType {
  return {
    type: constants.SHOW_NAVIGATION,
    value: null,
  };
}

export function setActiveChannelId(id: string): ActionType {
  return {
    type: constants.SET_ACTIVE_CHANNEL_ID,
    value: id,
  };
}

export function search(value: string): ActionType {
  return {
    type: constants.SEARCH,
    value,
  };
}

export function setLogo(data?: Object): ActionType {
  return {
    type: constants.SET_LOGO,
    value: data,
  };
}

export function setClock24Hr(clock: boolean): ActionType {
  return {
    type: constants.SET_CLOCK_24HR,
    value: clock,
  };
}

export function navigateLanding(): ActionType {
  return {
    type: constants.NAVIGATE_LANDING,
    value: null,
  };
}

export function userLogin(): ActionType {
  return {
    type: constants.USER_LOGIN,
    value: null,
  };
}

export function switchTeam(name: string): ActionType {
  return {
    type: constants.SWITCH_TEAM,
    value: name,
  };
}

export function setPrivateKey(privateKey: Object): ActionType {
  return {
    type: constants.SET_PRIVATE_KEY,
    value: privateKey,
  };
}

export function clearPrivateKey(): ActionType {
  return {
    type: constants.CLEAR_PRIVATE_KEY,
    value: null,
  };
}

export function openDialog(dialog: string, options?: Object = {}): ActionType {
  return {
    type: constants.OPEN_DIALOG,
    value: {
      dialog,
      options,
    },
  };
}

export function logoutUser(): ActionType {
  return {
    type: constants.LOGOUT_USER,
    value: null,
  };
}

export function closeDialog(dialog: string): ActionType {
  return {
    type: constants.CLOSE_DIALOG,
    value: {
      dialog,
    },
  };
}

export function resetLoginError(): ActionType {
  return {
    type: constants.RESET_LOGIN_ERROR,
    value: null,
  };
}

export function openChannelSelector(): ActionType {
  return {
    type: constants.OPEN_CHANNEL_SELECTOR,
    value: null,
  };
}

export function closeChannelSelector(): ActionType {
  return {
    type: constants.CLOSE_CHANNEL_SELECTOR,
    value: null,
  };
}

export function setActiveLayout(layoutId: string): ActionType {
  return {
    type: constants.SET_ACTIVE_LAYOUT,
    value: layoutId,
  };
}

export function setChannelUrl(channelId?: string): ActionType {
  return {
    type: constants.SET_CHANNEL_URL,
    value: channelId,
  };
}

export function setTeamName(name: string) {
  return {
    type: constants.SET_TEAM_NAME,
    value: name,
  };
}

export function getTeamId() {
  return {
    type: constants.GET_TEAM_ID,
    value: null,
  };
}

export function setCanAutoplayUnmuted(value: boolean): ActionType {
  return {
    type: constants.CAN_AUTOPLAY_UNMUTED,
    value,
  };
}

export function setDeviceMetadata(metadata: ImmutableMap<string, *>): ActionType {
  return {
    type: constants.SET_DEVICE_METADATA,
    value: metadata,
  };
}

export function navigateChannel(channelId: string): ActionType {
  return {
    type: constants.NAVIGATE_CHANNEL,
    value: channelId,
  };
}

export function addLayoutVideo(id: string): ActionType {
  return {
    type: constants.ADD_LAYOUT_VIDEO,
    value: id,
  };
}

export function removeLayoutVideo(id: string): ActionType {
  return {
    type: constants.REMOVE_LAYOUT_VIDEO,
    value: id,
  };
}


export function setWindowSize(width: number, height: number): ActionType {
  return {
    type: constants.SET_WINDOW_SIZE,
    value: { width, height },
  };
}

export function setMouseActive(active: boolean): ActionType {
  return {
    type: constants.SET_MOUSE_ACTIVE,
    value: active,
  };
}

export function setZoomRoomPasscode(passcode: string = ''): ActionType {
  return {
    type: constants.SET_ZOOM_ROOM_PASSCODE,
    value: passcode,
  };
}

export function setZoomRoomAvailable(available: boolean): ActionType {
  return {
    type: constants.SET_ZOOM_ROOM_AVAILABLE,
    value: available,
  };
}

export function setBluescapeAvailable(available: boolean): ActionType {
  return {
    type: constants.SET_BLUESCAPE_AVAILABLE,
    value: available,
  };
}

export function startLocalZoomMeeting(): ActionType {
  return {
    type: constants.START_LOCAL_ZOOM_MEETING,
    value: null,
  };
}

export function joinLocalZoomMeeting(meetingNumber: string, password: string): ActionType {
  return {
    type: constants.JOIN_LOCAL_ZOOM_MEETING,
    value: { meetingNumber, password },
  };
}

export function startLocalZoomShare(): ActionType {
  return {
    type: constants.START_LOCAL_ZOOM_SHARE,
    value: null,
  };
}

export function startLocalZoomPhone(number?: string): ActionType {
  return {
    type: constants.START_LOCAL_ZOOM_PHONE,
    value: number,
  };
}

export function leaveZoomMeeting(): ActionType {
  return {
    type: constants.LEAVE_ZOOM_MEETING,
    value: null,
  };
}

export function updateDevice(id: string, metadata: Object): ActionType {
  return {
    type: constants.UPDATE_DEVICE,
    value: {
      id,
      metadata,
    },
  };
}

export function updateMeeting(id: string, metadata: Object): ActionType {
  return {
    type: constants.UPDATE_MEETING,
    value: {
      id,
      metadata,
    },
  };
}

export function setZoomMeetingId(id: string): ActionType {
  return {
    type: constants.SET_ZOOM_MEETING_ID,
    value: id,
  };
}

export function setZoomMeetingNodeId(id: string): ActionType {
  return {
    type: constants.SET_ZOOM_MEETING_NODE_ID,
    value: id,
  };
}

export function setMenuButtonVisible(visible: boolean): ActionType {
  return {
    type: constants.SET_MENU_BUTTON_VISIBLE,
    value: visible,
  };
}

export function setZoomClientMeetingId(id: string): ActionType {
  return {
    type: constants.SET_ZOOM_CLIENT_MEETING_ID,
    value: id,
  };
}

export function setRemoteUrl(url: string): ActionType {
  return {
    type: constants.SET_REMOTE_URL,
    value: url,
  };
}

export function sendAnalytics(name: string, options: Object = {}): ActionType {
  return {
    type: constants.SEND_ANALYTICS,
    value: { name, options },
  };
}
