// @flow

import * as React from 'react';
import { List } from 'immutable';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import debounce from 'lodash/debounce';
import InfiniteScroll from 'react-infinite-scroller';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import LinearProgress from '@material-ui/core/LinearProgress';
import SearchIcon from '@material-ui/icons/Search';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { setMenuButtonVisible } from 'containers/App/actions';
import LayoutComponentBase from 'components/LayoutComponentBase';
import DeviceItemVideoElephant from 'components/DeviceItemVideoElephant';
import LayoutComponentContentLibraryPlaylistButtons from 'components/LayoutComponentContentLibraryPlaylistButtons';
import LayoutComponentContentLibraryPlaylistSearchVideo from 'components/LayoutComponentContentLibraryPlaylistSearchVideo';
import { query as queryVideoElephant, videoElephantQuery } from 'shared-redux/src/video-elephant/actions';
import { queryGroupResultSelector, latestQueryHasMoreSelector, latestQueryLoadSelector, videoElephantQuerySelector } from 'shared-redux/src/video-elephant/selectors';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { getBackgroundGradient } from 'containers/App/theme';

const styles = (theme: Object) => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  container: {
    zIndex: 10,
    width: '100%',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    WebkitOverflowScrolling: 'touch',
    boxSizing: 'border-box',
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
    maxWidth: '100%',
    maxHeight: '100%',
  },
  preview: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  previewIcon: {
    color: '#FFF',
    height: '100%',
    width: '100%',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${theme.spacing(3)}px)`,
      marginTop: '20%',
      marginBottom: '5%',
    },
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  searchField: {
    marginRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      width: 280,
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
    },
  },
  searchFieldInput: {
    alignSelf: 'flex-start',
    color: '#FFF',
    fontSize: 30,
    borderBottomColor: '#FFF',
    '&:before': {
      borderBottomColor: '#FFF',
    },
    '&:after': {
      borderBottomColor: emphasize('#FFF', 0.2),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 'inherit',
    },
  },
  searchFieldLabel: {
    color: '#FFF',
  },
  searchFieldLabelFocused: {
    color: '#FFF !important',
  },
  categoryIcon: {
    color: '#FFF',
  },
  categorySelector: {
    width: 220,
    marginRight: theme.spacing(4),
    color: '#FFF',
    [theme.breakpoints.down('sm')]: {
      width: 150,
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      width: 110,
    },
  },
  categoryAll: {
    color: '#FFF',
    opacity: 0.5,
  },
  categoryAllOpen: {
    color: theme.palette.text.hint,
  },
  evergreenRoot: {
    marginTop: theme.spacing(2),
    color: '#FFF',
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
      marginTop: 0,
      marginLeft: 0,
    },
  },
  evergreenLabel: {
    fontSize: 25,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      paddingTop: 5,
    },
  },
  checkbox: {
    color: '#FFF !important',
    fontSize: '30px !important',
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px !important',
    },
  },
  iconSize: {
    fontSize: 30,
    [theme.breakpoints.down('xs')]: {
      fontSize: 'inherit',
    },
  },
  infiniteScroll: {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    boxSizing: 'border-box',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
  },
  infoText: {
    alignSelf: 'flex-start',
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    color: '#FFF',
  },
  loader: {
    width: '100%',
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  loaderEmpty: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(7),
  },
});

type Props = {
  className: string,
  active: boolean,
  fgColor: string,
  bgColor: string,
  backgroundImage: Object,
  backgroundImageSize: string,
  backgroundImageAlignment: number,
  classes: ClassesType,
  query: string,
  loading: boolean,
  hasMore: boolean,
  videoElephantQuery: Function,
  queryVideoElephant: Function,
  setMenuButtonVisible: Function,
  videoIds: List<string>,
  width: string,
};

type State = {
  categorySelectOpen: boolean,
  evergreen: boolean,
  category: string,
  query: string,
  activeVideo: ?number,
};

const DEFAULT_VIDEO_ELEPHANT_QUERY = 'DEFAULT_VIDEO_ELEPHANT_QUERY';
const QUERY_SIZE = 21;
const CATEGORIES = ['Animals', 'Business', 'Celebrity', 'Entertainment', 'Environment', 'Fashion', 'Food', 'How-to', 'Kids', 'Lifestyle', 'Motoring', 'Nature', 'News', 'Science', 'Sports', 'Technology', 'Travel'];

export class LayoutComponentContentLibraryPlaylistSearch extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      categorySelectOpen: false,
      evergreen: false,
      category: '',
      query: '',
      activeVideo: null,
    };
    this.start = 0;
    this.size = QUERY_SIZE;
    this.queryVideoElephant = debounce(this.props.queryVideoElephant, 400, { leading: true });
  }

  componentDidMount() {
    if (this.props.active) {
      this.props.setMenuButtonVisible(true);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { query } = this.props;
    if (query !== prevProps.query) {
      this.handleQuery(true);
    }
  }

  componentWillUnmount() {
    if (this.props.active) {
      this.props.setMenuButtonVisible(false);
    }
  }

  handleSetQuery = () => {
    const { query, category, evergreen } = this.state;
    if (query) {
      this.props.videoElephantQuery(`(and${query ? ` '${query.replace(/'/g, '')}'` : ''}${category ? ` category:'${category.toLowerCase()}'` : ''}${evergreen ? " evergreen:'true'" : ''})`);
    }
  }

  handleQuery = (reset: boolean = false) => {
    const { query } = this.props;
    if (reset) {
      this.start = 0;
      if (this.scrollContainerRef) {
        // Must scroll container to top to avoid loading too many icons at once
        this.scrollContainerRef.scrollTop = 0;
      }
    }
    if (query) {
      this.queryVideoElephant(`${DEFAULT_VIDEO_ELEPHANT_QUERY}_${query}`, {
        query,
        queryParser: 'structured',
        sort: 'pub_date desc',
        size: this.size,
        start: this.start,
      });
    }
  }

  handleLoadMore = () => {
    this.start += QUERY_SIZE;
    this.handleQuery(false);
  }

  handlePlayVideo = (index: ?number) => {
    this.props.setMenuButtonVisible(typeof index !== 'number');
    this.setState({ activeVideo: index });
  }

  handleSkipVideo = (skip: number) => {
    const { videoIds } = this.props;
    const { activeVideo } = this.state;
    if (typeof activeVideo === 'number') {
      const size = videoIds.size;
      this.handlePlayVideo((size + activeVideo + (skip % size)) % size);
    }
  }

  queryVideoElephant: Function;
  size: number;
  start: number;
  scrollContainerRef: any;

  renderHeader() {
    const { classes, width } = this.props;
    const { categorySelectOpen, query, category, evergreen } = this.state;
    return (
      <div className={classes.header}>
        <TextField
          InputProps={{
            startAdornment: <SearchIcon className={`${classes.icon} ${classes.iconSize}`} />,
            className: classes.searchFieldInput,
          }}
          InputLabelProps={{
            classes: {
              root: classes.searchFieldLabel,
              focused: classes.searchFieldLabelFocused,
            },
          }}
          placeholder='Type to Search'
          label='Search'
          className={classes.searchField}
          value={query}
          onChange={(event: Object) => this.setState({ query: event.target.value }, this.handleSetQuery)}
        />
        <FormControl>
          <InputLabel
            shrink
            classes={{
              root: classes.searchFieldLabel,
              focused: classes.searchFieldLabelFocused,
            }}
          >
            Category
          </InputLabel>
          <Select
            value={category}
            onChange={(event: Object) => this.setState({ category: event.target.value }, this.handleSetQuery)}
            displayEmpty
            name='Category'
            className={`${classes.searchFieldInput} ${classes.categorySelector}`}
            classes={{ icon: classes.categoryIcon }}
            onOpen={() => this.setState({ categorySelectOpen: true })}
            onClose={() => this.setState({ categorySelectOpen: false })}
          >
            <MenuItem value=''>
              <span className={categorySelectOpen ? classes.categoryAllOpen : classes.categoryAll}>All</span>
            </MenuItem>
            {CATEGORIES.map((c: string) => (
              <MenuItem value={c}>{c}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={evergreen}
              onChange={(event: Object) => this.setState({ evergreen: event.target.checked }, this.handleSetQuery)}
              value='evergreen'
              color='primary'
              classes={{ root: classes.checkbox, checked: classes.checkbox }}
              checkedIcon={<CheckBoxIcon className={classes.iconSize} />}
              icon={<CheckBoxOutlineBlankIcon className={classes.iconSize} />}
            />
          }
          label='Evergreen'
          labelPlacement={width === 'xs' ? 'top' : 'right'}
          classes={{
            root: classes.evergreenRoot,
            label: classes.evergreenLabel,
          }}
        />
      </div>
    );
  }

  renderContent() {
    const { classes, hasMore, loading, videoIds } = this.props;
    const { query } = this.state;
    if (!query) {
      return null;
    }
    return (
      <React.Fragment>
        {videoIds.size > 0 ? (
          <InfiniteScroll
            className={classes.infiniteScroll}
            pageStart={0}
            loadMore={this.handleLoadMore}
            threshold={600}
            hasMore={hasMore}
            useWindow={false}
          >
            {videoIds.toJS().map((id: string, index: number) => (
              <DeviceItemVideoElephant
                id={id}
                key={id}
                onClick={() => this.handlePlayVideo(index)}
              />
            ))}
            {loading ? <LinearProgress color='secondary' className={classes.loader} /> : null}
          </InfiniteScroll>
        ) : (loading ? <LinearProgress color='secondary' className={classes.loaderEmpty} /> : <Typography className={classes.infoText}>No search results found.</Typography>)}
      </React.Fragment>
    );
  }

  render() {
    const { classes, className, active, fgColor, bgColor, backgroundImage, backgroundImageSize, backgroundImageAlignment, videoIds } = this.props;
    const { activeVideo } = this.state;
    const isVideoPlaying = active && typeof activeVideo === 'number';
    const background = getBackgroundGradient(bgColor || '#FFF', fgColor || '#000');
    return (
      <LayoutComponentBase
        className={className}
        color={fgColor || 'inherit'}
        backgroundColor={bgColor || 'inherit'}
        backgroundImage={backgroundImage}
        backgroundImageSize={backgroundImageSize}
        backgroundImageAlignment={backgroundImageAlignment}
      >
        <div className={classes.root}>
          {!active ? (
            <div className={classes.preview} style={{ background }}>
              <SearchIcon className={classes.previewIcon} />
            </div>
          ) : (
            <div
              className={classes.container}
              style={{ background }}
              ref={(e) => { this.scrollContainerRef = e; }}
            >
              {this.renderHeader()}
              {this.renderContent()}
              <LayoutComponentContentLibraryPlaylistButtons
                isVideoPlaying={isVideoPlaying}
                playMode={'search'}
                handleSkipVideo={this.handleSkipVideo}
                clearActiveVideo={() => this.handlePlayVideo(null)}
              />
              {isVideoPlaying ? (
                <LayoutComponentContentLibraryPlaylistSearchVideo
                  id={videoIds.get(activeVideo)}
                  style={{ background }}
                  handleOnEnded={() => this.handlePlayVideo(null)}
                />
              ) : null}
            </div>
          )}
        </div>
      </LayoutComponentBase>
    );
  }
}

const withConnect = connect((state: StateType) => {
  const query = videoElephantQuerySelector(state);
  let videoIds = List();
  let hasMore = true;
  let loading = false;
  const queryGroupId = `${DEFAULT_VIDEO_ELEPHANT_QUERY}_${query}`;
  try {
    loading = latestQueryLoadSelector(state, queryGroupId);
    videoIds = queryGroupResultSelector(state, queryGroupId);
    hasMore = latestQueryHasMoreSelector(state, queryGroupId);
  } catch (error) {
    if (!/Unable to get videoElephant/.test(error.message)) {
      throw error;
    }
  }
  return {
    query,
    loading,
    hasMore,
    videoIds,
  };
}, (dispatch: Function) => bindActionCreators({ setMenuButtonVisible, queryVideoElephant, videoElephantQuery }, dispatch));

export default compose(
  withStyles(styles),
  withConnect,
  withWidth(),
)(LayoutComponentContentLibraryPlaylistSearch);
