// @flow

import { Map, fromJS } from 'immutable';
import * as constants from './constants';
import type { ActionType, StateType } from '../types';
import type { ProgramResultType } from './types';


const initialState = Map({}).merge({
  latestQueryProgramsIds: {},
  latestQueryProgramsResultIds: {},
  queryProgramsResults: {},
  programsResultValues: {},
  contentLocations: {},
});

const actionsMap = {
  [constants.QUERY_PROGRAMS](state: StateType, action: ActionType): StateType {
    const previousQueryCreated = state.getIn(['queryProgramsResults', action.value.queryGroupId, action.value.queryId, 'created']);
    if (previousQueryCreated) {
      return state.mergeDeep({
        latestQueryProgramsIds: {
          [action.value.queryGroupId]: action.value.queryId,
        },
        queryProgramsResults: {
          [action.value.queryGroupId]: {
            [action.value.queryId]: {
              loading: true,
            },
          },
        },
        latestQueryProgramsResultIds: {
          [action.value.queryGroupId]: action.value.queryId,
        },
      });
    }
    const newState = state.mergeDeep({
      latestQueryProgramsIds: {
        [action.value.queryGroupId]: action.value.queryId,
      },
      queryProgramsResults: {
        [action.value.queryGroupId]: {
          [action.value.queryId]: {
            loading: true,
          },
        },
      },
    });
    return newState;
  },
  [constants.QUERY_ADMIN_PROGRAMS](state: StateType, action: ActionType): StateType {
    const previousQueryCreated = state.getIn(['queryProgramsResults', action.value.queryGroupId, action.value.queryId, 'created']);
    if (previousQueryCreated) {
      return state.mergeDeep({
        latestQueryProgramsIds: {
          [action.value.queryGroupId]: action.value.queryId,
        },
        queryProgramsResults: {
          [action.value.queryGroupId]: {
            [action.value.queryId]: {
              loading: true,
            },
          },
        },
        latestQueryProgramsResultIds: {
          [action.value.queryGroupId]: action.value.queryId,
        },
      });
    }
    const newState = state.mergeDeep({
      latestQueryProgramsIds: {
        [action.value.queryGroupId]: action.value.queryId,
      },
      queryProgramsResults: {
        [action.value.queryGroupId]: {
          [action.value.queryId]: {
            loading: true,
          },
        },
      },
    });
    return newState;
  },
  [constants.QUERY_PROGRAMS_RESULT](state: StateType, action: ActionType): StateType {
    const values = {};
    let ids = [];
    if (action.value.result) {
      action.value.result.forEach((programResult: ProgramResultType) => {
        values[programResult.identifier] = fromJS(programResult);
      });
      ids = action.value.result.map((programResult: ProgramResultType) => ({
        id: programResult.identifier,
        name: programResult.name,
        thumbnail: programResult.thumbnailUrls && programResult.thumbnailUrls.default ? programResult.thumbnailUrls.default : null,
      }));
    }
    return state.merge({
      programsResultValues: Map(state.get('programsResultValues')).merge(values),
      queryProgramsResults: Map(state.get('queryProgramsResults')).setIn([action.value.queryGroupId, action.value.queryId], fromJS({
        loading: false,
        created: action.value.created,
        hasMore: action.value.hasMore,
        ids,
      })),
      latestQueryProgramsResultIds: Map(state.get('latestQueryProgramsResultIds')).set(action.value.queryGroupId, action.value.queryId),
    });
  },
  [constants.QUERY_ADMIN_PROGRAMS_RESULT](state: StateType, action: ActionType): StateType {
    const values = {};
    let ids = [];
    if (action.value.result) {
      action.value.result.forEach((programResult: ProgramResultType) => {
        values[programResult.identifier] = fromJS(programResult);
      });
      ids = action.value.result.map((programResult: ProgramResultType) => ({
        id: programResult.identifier,
        name: programResult.name,
        thumbnail: programResult.thumbnailUrls && programResult.thumbnailUrls.default ? programResult.thumbnailUrls.default : null,
      }));
    }
    return state.merge({
      programsResultValues: Map(state.get('programsResultValues')).merge(values),
      queryProgramsResults: Map(state.get('queryProgramsResults')).setIn([action.value.queryGroupId, action.value.queryId], fromJS({
        loading: false,
        created: action.value.created,
        hasMore: action.value.hasMore,
        ids,
      })),
      latestQueryProgramsResultIds: Map(state.get('latestQueryProgramsResultIds')).set(action.value.queryGroupId, action.value.queryId),
    });
  },
  [constants.GET_PROGRAM_RESULT](state: StateType, action: ActionType): StateType {
    const value = {};
    value[action.value.programId] = fromJS(action.value.result);
    return state.merge({
      programsResultValues: Map(state.get('programsResultValues')).merge(value),
    });
  },
  [constants.GET_PROGRAM_CONTENT_LOCATIONS_RESULT](state: StateType, action: ActionType): StateType {
    const value = {};
    value[action.value.programId] = Map({
      live: fromJS(action.value.liveContentLocations),
      vod: fromJS(action.value.vodContentLocations),
    });
    return state.merge({
      contentLocations: Map(state.get('contentLocations')).merge(value),
    });
  },
};

export default (state: StateType = initialState, action: ActionType): StateType => {
  const reduceFn = actionsMap[action.type];
  if (!reduceFn) return state;
  return reduceFn(state, action);
};
