// @flow

/**
*
* LayoutComponentSpacer
*
*/

import * as React from 'react';
import { compose } from 'redux';
import withNode from '@bunchtogether/boost-client/dist/components/withNode';
import { Map } from 'immutable';
import LayoutComponentBase from 'components/LayoutComponentBase';

type Props = {
  className?: string,
  node?: Map,
  backgroundImage: Object,
  backgroundImageSize: string,
  backgroundImageAlignment: number,
  fgColor: string,
  bgColor: string,
};

type State = {
  style: Object,
};

export class LayoutComponentSpacer extends React.PureComponent<Props, State> { // eslint-disable-line react/prefer-stateless-function
  static defaultProps = {
    className: '',
  };

  render() {
    const { className, node, backgroundImage, backgroundImageSize, backgroundImageAlignment, fgColor, bgColor } = this.props;
    if (!node || !Map.isMap(node)) {
      return null;
    }
    return (
      <LayoutComponentBase
        className={className}
        color={fgColor || 'inherit'}
        backgroundColor={bgColor || 'inherit'}
        alignment={0}
        backgroundImage={backgroundImage}
        backgroundImageSize={backgroundImageSize}
        backgroundImageAlignment={backgroundImageAlignment}
      />
    );
  }
}

export default compose(
  withNode(),
)(LayoutComponentSpacer);
