// @flow

import { Map, fromJS } from 'immutable';
import * as constants from './constants';
import type { VideoElephantType } from './types'; // eslint-disable-line no-unused-vars

import type { RecordType, ActionType, StateType } from '../types';

const initialState = Map({}).merge({
  latestQueryIds: {},
  latestQueryResultIds: {},
  queryResults: {},
  resultValues: {},
  videoElephantQuery: 'matchall',
});

const actionsMap = {
  [constants.QUERY](state: StateType, action: ActionType): StateType {
    const previousQueryCreated = state.getIn(['queryResults', action.value.queryGroupId, action.value.queryId, 'created']);
    if (previousQueryCreated) {
      return state.mergeDeep(fromJS({
        latestQueryIds: {
          [action.value.queryGroupId]: action.value.queryId,
        },
        queryResults: {
          [action.value.queryGroupId]: {
            [action.value.queryId]: {
              loading: true,
            },
          },
        },
        latestQueryResultIds: {
          [action.value.queryGroupId]: action.value.queryId,
        },
      }));
    }
    const newState = state.mergeDeep(fromJS({
      latestQueryIds: {
        [action.value.queryGroupId]: action.value.queryId,
      },
      queryResults: {
        [action.value.queryGroupId]: {
          [action.value.queryId]: {
            loading: true,
          },
        },
      },
    }));
    return newState;
  },
  [constants.QUERY_RESULT](state: StateType, action: ActionType): StateType {
    const values = {};
    if (!action.value.result) {
      return state;
    }
    action.value.result.forEach((result: VideoElephantType & RecordType) => {
      values[result.id] = result;
    });
    return state.merge({
      resultValues: Map(state.get('resultValues')).merge(Map(values)),
      queryResults: Map(state.get('queryResults')).setIn([action.value.queryGroupId, action.value.queryId], fromJS({
        loading: false,
        created: action.value.created,
        hasMore: action.value.hasMore,
        ids: action.value.result.map((result: VideoElephantType & RecordType) => result.id),
      })),
      latestQueryResultIds: Map(state.get('latestQueryResultIds')).set(action.value.queryGroupId, action.value.queryId),
    });
  },
  [constants.VIDEO_ELEPHANT_QUERY](state: StateType, action: ActionType): StateType {
    return state.set('videoElephantQuery', action.value.query);
  },
};

export default (state: StateType = initialState, action: ActionType): StateType => {
  const reduceFn = actionsMap[action.type];
  if (!reduceFn) return state;
  return reduceFn(state, action);
};
