// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { mouseActiveSelector } from 'containers/App/selectors';

const styles = (theme: Object) => ({
  noOpacity: {
    opacity: 0,
  },
  fab: {
    position: 'absolute',
    top: theme.spacing(9),
    left: theme.spacing(5),
    zIndex: 20,
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  nextButton: {
    position: 'absolute',
    top: 'calc(50% - 50px)',
    right: theme.spacing(2),
    transition: 'opacity 0.5s',
  },
  previousButton: {
    position: 'absolute',
    top: 'calc(50% - 50px)',
    left: theme.spacing(2),
    transition: 'opacity 0.5s',
  },
  iconSkip: {
    fontSize: 100,
    color: 'white',
    zIndex: 20,
  },
  transition: {
    transition: 'opacity 0.5s',
    '&:hover': {
      opacity: 1,
    },
  },
});

type Props = {
  classes: ClassesType,
  mouseActive: boolean,
  isVideoPlaying: boolean,
  playMode: string,
  handleSkipVideo: Function,
  clearActiveVideo: Function,
};

export class LayoutComponentContentLibraryPlaylistPreview extends React.PureComponent<Props> {
  render() {
    const { classes, mouseActive, isVideoPlaying, playMode, handleSkipVideo, clearActiveVideo } = this.props;
    if (!isVideoPlaying) {
      return null;
    }
    return (
      <React.Fragment>
        {playMode === 'choose' || playMode === 'search' ? (
          <Fab
            className={`${classes.fab} ${mouseActive ? classes.transition : classes.noOpacity}`}
            variant='outlined'
            color='primary'
            onClick={clearActiveVideo}
          >
            <ArrowBackIcon className={classes.icon} />
            Back
          </Fab>
        ) : null}
        <IconButton
          className={`${classes.previousButton} ${mouseActive ? classes.transition : classes.noOpacity}`}
          onClick={() => handleSkipVideo(-1)}
        >
          <KeyboardArrowLeftIcon className={classes.iconSkip} />
        </IconButton>
        <IconButton
          className={`${classes.nextButton} ${mouseActive ? classes.transition : classes.noOpacity}`}
          onClick={() => handleSkipVideo(1)}
        >
          <KeyboardArrowRightIcon className={classes.iconSkip} />
        </IconButton>
      </React.Fragment>
    );
  }
}

const withConnect = connect(
  (state: StateType): Object => ({
    mouseActive: mouseActiveSelector(state),
  }),
);

export default compose(
  withStyles(styles),
  withConnect,
)(LayoutComponentContentLibraryPlaylistPreview);
