// @flow

/**
*
* ChannelSelectorItem
*
*/

import * as React from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { Map as ImmutableMap, List } from 'immutable';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import { activeLayoutSelector, deviceIdSelector, teamIdSelector } from 'containers/App/selectors';
import { navigateChannel, closeChannelSelector } from 'containers/App/actions';
import { TYPE_BAND_LAYOUT } from 'shared-redux/src/graph/constants';
import Layout from 'components/Layout';
import withNode from '@bunchtogether/boost-client/dist/components/withNode';
import withAncestors from '@bunchtogether/boost-client/dist/components/withAncestors';
import ChannelImageComponent from 'components/ChannelImageComponent';

const styles = (theme:Object) => ({ // eslint-disable-line no-unused-vars
  container: {
    width: '100%',
    height: 63,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    cursor: 'pointer',
    marginBottom: theme.spacing(2),
  },
  containerLarge: {
    height: 63,
  },
  thumbnailContainer: {
    minWidth: 112,
    height: 63,
    background: 'rgba(240,240,240,1)',
    transform: 'scale(1)',
    WebkitTransform: 'scale(1)',
    boxShadow: `0px 0px ${theme.spacing(1)}px 1px rgba(0,0,0,0.5)`,
  },
  thumbnailContainerLarge: {
    minWidth: 112,
    height: 63,
    background: 'rgba(240,240,240,1)',
    transform: 'scale(1)',
    WebkitTransform: 'scale(1)',
    boxShadow: `0px 0px ${theme.spacing(1)}px 1px rgba(0,0,0,0.5)`,
  },
  textContainer: {
    paddingLeft: theme.spacing(1),
  },
  name: {
    fontSize: 14,
    fontWeight: '600',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textAlign: 'left',
    maxWidth: '100%',
    lineHeight: 1.3,
    color: 'white',
  },
  description: {
    fontSize: 11,
    fontWeight: '400',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    lineHeight: '1.3em',
    height: '3.9em',
    textAlign: 'left',
    color: 'white',
  },
  channelNameLarge: {
    fontSize: 18,
    fontWeight: 500,
  },
});

type Props = {
  classes: ClassesType,
  navigateChannel: Function,
  closeChannelSelector: Function,
  id: string,
  node: ImmutableMap<string, *>,
  ancestors: List<string>,
  activeLayout: string | null,
};

type State = {
  qrCodeValue: string
};

class ChannelSelectorItem extends React.PureComponent<Props, State> { // eslint-disable-line react/prefer-stateless-function
  handleClickChannel = () => {
    this.props.navigateChannel(this.props.id);
    this.props.closeChannelSelector();
  }

  renderContent = () => {
    const { node, ancestors, id, activeLayout } = this.props;

    if (!node || !ImmutableMap.isMap(node)) {
      return null;
    }
    const image = node.getIn(['metadata', 'image']);
    if (image) {
      return <ChannelImageComponent id={id} />;
    }
    const layout = ancestors && List.isList(ancestors) ? ancestors.first() : null;
    const isActive = activeLayout === layout;
    return (
      <Layout
        id={layout}
        offsetWidth={isActive ? 112 : 112}
        thumbnail
        channelName={node.getIn(['metadata', 'name'])}
        channelDescription={node.getIn(['metadata', 'description'])}
      />
    );
  }

  render() {
    const { classes, node, ancestors } = this.props;
    if (!node || !ImmutableMap.isMap(node)) {
      return null;
    }
    const name = node.getIn(['metadata', 'name']);
    const description = node.getIn(['metadata', 'description']);
    const layout = ancestors && List.isList(ancestors) ? ancestors.first() : null;
    const isActive = this.props.activeLayout === layout;
    return (
      <ButtonBase className={isActive ? `${classes.container} ${classes.containerLarge}` : classes.container} onClick={this.handleClickChannel}>
        <div className={isActive ? classes.thumbnailContainerLarge : classes.thumbnailContainer}>
          {this.renderContent()}
        </div>
        <div className={classes.textContainer}>
          <Typography className={isActive ? `${classes.nameLarge} ${classes.name}` : classes.name}>
            {name}
          </Typography>
          <Typography className={isActive ? `${classes.descriptionLarge} ${classes.description}` : classes.description}>
            {description}
          </Typography>
        </div>
      </ButtonBase>
    );
  }
}

const withConnect = connect((state:StateType) => ({
  teamId: teamIdSelector(state),
  deviceId: deviceIdSelector(state),
  activeLayout: activeLayoutSelector(state),
}), (dispatch: Function) => bindActionCreators({ navigateChannel, closeChannelSelector }, dispatch));

export default compose(
  withStyles(styles, { withTheme: true }),
  withNode(),
  withAncestors({ type: TYPE_BAND_LAYOUT, depth: 1 }),
  withConnect,
)(ChannelSelectorItem);

