// @flow

import { createSelector } from 'reselect';

class AuthTokenExpiredError extends Error {
  constructor(authData, ...params) {
    super(...params);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AuthTokenExpiredError);
    }

    this.name = 'AuthTokenExpired';
    this.authData = authData;
    this.date = new Date();
  }
  authData: Object;
  date: Date;
}

export const authSelector = (state: StateType): ?Object => state.get('auth');

export const linkValidSelector = createSelector<StateType, *, *, *>(
  authSelector,
  (auth: Object): string => auth.get('linkValid'),
);

export const tokenValidatedSelector = createSelector<StateType, *, *, *>(
  authSelector,
  (auth: Object): boolean => auth.get('tokenValidated'),
);

export const createAuthClientErrorSelector = createSelector<StateType, *, *, *>(
  authSelector,
  (auth: Object): string => auth.get('createAuthClientError'),
);

export const applicationIdSelector = createSelector<StateType, *, *, *>(
  authSelector,
  (auth: Object): string => auth.get('applicationId'),
);

export const authDataSelector = createSelector<StateType, *, *, *>(
  authSelector,
  (auth: Object): Object => {
    if (!auth.get('authData')) {
      throw new Error('Unable to get auth data');
    }
    return auth.get('authData');
  },
);

export const teamIdSelector = createSelector<StateType, *, *, *>(
  authDataSelector,
  (authData: Object): string => authData.get('teamId'),
);

export const clientIdSelector = createSelector<StateType, *, *, *>(
  authDataSelector,
  (authData: Object): string => authData.get('clientId'),
);

export const accessTokenSelector = createSelector<StateType, *, *, *>(
  authDataSelector,
  (authData: Object): string => {
    if (authData.get('expires_at') < Date.now()) {
      throw new AuthTokenExpiredError(authData);
    }
    return authData.get('access_token');
  },
);

export const idTokenSelector = createSelector<StateType, *, *, *>(
  authDataSelector,
  (authData: Object): string => authData.get('id_token'),
);

export const userSelector = createSelector<StateType, *, *, *>(
  authSelector,
  (state: Object): Object => {
    if (!state.get('user')) {
      throw new Error('Unable to get user');
    }
    return state.get('user');
  },
);

export const userIdSelector = createSelector<StateType, *, *, *>(
  userSelector,
  (user: Object): string => user.get('id'),
);

export const groupIdSelector = createSelector<StateType, *, *, *>(
  userSelector,
  (user: Object): string => user.get('group_id'),
);

export const nameSelector = createSelector<StateType, *, *, *>(
  userSelector,
  (user: Object): string => user.get('name'),
);

export const givenNameSelector = createSelector<StateType, *, *, *>(
  userSelector,
  (user: Object): string => user.get('given_name'),
);

export const familyNameSelector = createSelector<StateType, *, *, *>(
  userSelector,
  (user: Object): string => user.get('family_name'),
);

export const pictureSelector = createSelector<StateType, *, *, *>(
  userSelector,
  (user: Object): string => user.get('picture'),
);

export const emailSelector = createSelector<StateType, *, *, *>(
  userSelector,
  (user: Object): string => user.get('email'),
);
