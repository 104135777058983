// @flow

import * as React from 'react';
import { is, List, Map } from 'immutable';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import withNode from '@bunchtogether/boost-client/dist/components/withNode';
import LayoutComponentBase from 'components/LayoutComponentBase';
import type { VideoElephantType } from 'shared-redux/src/video-elephant/types';
import LayoutComponentContentLibraryPlaylistChoose from 'components/LayoutComponentContentLibraryPlaylistChoose';
import LayoutComponentContentLibraryPlaylistPreview from 'components/LayoutComponentContentLibraryPlaylistPreview';
import LayoutComponentContentLibraryPlaylistButtons from 'components/LayoutComponentContentLibraryPlaylistButtons';
import LayoutComponentContentLibraryPlaylistVideo from 'components/LayoutComponentContentLibraryPlaylistVideo';
import { getBackgroundGradient } from 'containers/App/theme';

const styles = () => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  container: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    maxWidth: '100%',
    maxHeight: '100%',
  },
});

type Props = {
  id: string,
  classes: ClassesType,
  className?: string,
  node: Map<string, *>,
  backgroundImage: Object,
  backgroundImageSize: string,
  backgroundImageAlignment: number,
  alignment: number, // eslint-disable-line react/no-unused-prop-types
  fgColor: string,
  bgColor: string,
  active: boolean,
  channelName?: string,
  channelDescription?: string,
};

type State = {
  videos: List<VideoElephantType>,
  filteredVideos: List<VideoElephantType>,
  playMode: string,
  activeVideo: ?number,
  search: string,
};

const initialState = {
  videos: List(),
  filteredVideos: List(),
  playMode: '',
  activeVideo: null,
  search: '',
};

export class LayoutComponentContentLibraryPlaylist extends React.Component<Props, State> {
  state = initialState;

  componentDidMount() {
    if (this.props.node) {
      this.loadData(this.props.node);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.node && !is(this.props.node, prevProps.node)) {
      this.loadData(this.props.node);
    }
  }

  loadData = (node: Map<string, *>) => {
    const { active } = this.props;
    const videos = node.getIn(['metadata', 'videos']) || List();
    const playMode = node.getIn(['metadata', 'playMode']) || 'sequence';
    const activeVideo = playMode === 'sequence' && active && videos.size > 0 ? 0 : null;

    this.setState({
      videos,
      filteredVideos: videos,
      playMode,
      activeVideo,
    });
  }

  handleSetVideos = (videos: List<VideoElephantType>) => {
    this.setState({ filteredVideos: videos });
  }

  handlePlayVideo = (index: ?number) => {
    this.setState({ activeVideo: index });
  }

  handleSkipVideo = (skip: number) => {
    const { activeVideo, filteredVideos } = this.state;
    if (typeof activeVideo === 'number') {
      const size = filteredVideos.size;
      this.handlePlayVideo((size + activeVideo + (skip % size)) % size);
    }
  }

  handleOnEnded = () => {
    const { playMode } = this.state;
    if (playMode === 'sequence') {
      this.handleSkipVideo(1);
    }
    if (playMode === 'choose') {
      this.handlePlayVideo(null);
    }
  }

  render() {
    const { id, classes, className, node, backgroundImage, backgroundImageSize, backgroundImageAlignment, fgColor, bgColor, active, channelName, channelDescription } = this.props;
    const { playMode, activeVideo, videos, filteredVideos } = this.state;
    if (!node || !Map.isMap(node)) {
      return null;
    }
    const isVideoPlaying = active && typeof activeVideo === 'number';
    const background = getBackgroundGradient(bgColor || '#FFF', fgColor || '#000');
    return (
      <LayoutComponentBase
        className={className}
        color={fgColor || 'inherit'}
        backgroundColor={bgColor || 'inherit'}
        backgroundImage={backgroundImage}
        backgroundImageSize={backgroundImageSize}
        backgroundImageAlignment={backgroundImageAlignment}
      >
        <div className={classes.root}>
          <div
            className={classes.container}
            style={{ background }}
          >
            {!active ? (
              <LayoutComponentContentLibraryPlaylistPreview videos={filteredVideos} />
            ) : (
              <LayoutComponentContentLibraryPlaylistChoose
                channelName={channelName}
                channelDescription={channelDescription}
                videos={videos}
                filteredVideos={filteredVideos}
                handleSetVideos={this.handleSetVideos}
                handlePlayVideo={this.handlePlayVideo}
                activeVideo={isVideoPlaying}
              />
            )}
          </div>
          <LayoutComponentContentLibraryPlaylistButtons
            isVideoPlaying={isVideoPlaying}
            playMode={playMode}
            handleSkipVideo={this.handleSkipVideo}
            clearActiveVideo={() => this.handlePlayVideo(null)}
          />
          <LayoutComponentContentLibraryPlaylistVideo
            id={id}
            style={{ background }}
            video={isVideoPlaying ? filteredVideos.get(activeVideo) : null}
            handleOnEnded={this.handleOnEnded}
          />
        </div>
      </LayoutComponentBase>
    );
  }
}

export default compose(
  withStyles(styles),
  withNode(),
)(LayoutComponentContentLibraryPlaylist);

