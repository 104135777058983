// @flow

import { Map, fromJS } from 'immutable';
import * as constants from './constants';
import type { ActionType, StateType } from '../types';

const initialState = Map({});

const actionsMap = {
  [constants.SUCCESS](state: StateType, action: ActionType): StateType {
    return state.set(action.value.id, fromJS({
      status: action.value.status,
      message: 'success',
    }));
  },
  [constants.ERROR](state: StateType, action: ActionType): StateType {
    if (action.value.response) {
      let message = 'Unknown error';
      if (action.value.response.body && action.value.response.body.error) {
        message = action.value.response.body.error;
      }
      return state.set(action.value.id, fromJS({
        status: action.value.response.status,
        message,
      }));
    }
    return state.set(action.value.id, fromJS({
      status: 0,
      message: action.value.message,
    }));
  },
  [constants.RESET](state: StateType, action: ActionType): StateType {
    return state.delete(action.value);
  },
};

export default (state: StateType = initialState, action: ActionType): StateType => {
  const reduceFn = actionsMap[action.type];
  if (!reduceFn) return state;
  return reduceFn(state, action);
};
