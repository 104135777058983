// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { closeDialog, switchTeam, resetLoginError } from 'containers/App/actions';
import { dialogSelector, loginErrorSelector } from 'containers/App/selectors';

const styles = (theme: Object) => ({
  paper: {
    [theme.breakpoints.up('sm')]: {
      minWidth: 500,
    },
  },
  spinner: {
    color: theme.palette.primary.main,
  },
  placeholderSpinner: {
    height: 40,
  },
});

type Props = {
  classes: ClassesType,
  closeDialog: Function,
  open: boolean,
  fullScreen: boolean,
  dialogName?: string,
  loginError: string,
  switchTeam: Function,
  resetLoginError: Function,
};

type State = {
  teamName: string,
  errorMessage: string,
  loading: boolean,
};

export class DialogSwitchTeam extends React.PureComponent<Props, State> { // eslint-disable-line react/prefer-stateless-function
  state = {
    teamName: '',
    errorMessage: '',
    loading: false,
  };

  componentDidUpdate(prevProps: Props) {
    if (prevProps.loginError !== this.props.loginError) {
      this.handleLoginError(this.props);
    }
    if (prevProps.open !== this.props.open && !this.props.open) {
      this.handleClose();
    }
  }

  handleLoginError = (props: Props) => {
    if (props.loginError === 'Link not found') {
      this.setState({ errorMessage: 'Team not found.', loading: false });
    } else {
      this.setState({ errorMessage: '' });
    }
  }

  handleClose = () => {
    this.props.closeDialog(this.props.dialogName || 'switchTeam');
    this.setState({ loading: false, teamName: '', errorMessage: '' });
  };

  handleChange = (event: Object) => {
    this.props.resetLoginError();
    this.setState({
      teamName: event.target.value,
      errorMessage: '',
    });
  }

  handleSwitchTeam = () => {
    this.props.resetLoginError();
    if (this.state.teamName) {
      this.setState({ loading: true });
      this.props.switchTeam(this.state.teamName);
    }
  }

  render() {
    const { classes, fullScreen, open } = this.props;
    return (
      <Dialog
        classes={{
          paper: classes.paper,
        }}
        fullScreen={fullScreen}
        open={open}
        onClose={this.handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Switch Team</DialogTitle>
        <DialogContent>
          <TextField
            value={this.state.teamName}
            onChange={this.handleChange}
            fullWidth
            label='Team Name'
            helperText={this.state.errorMessage || 'Enter a team name to continue'}
            error={!!this.state.errorMessage}
            onKeyDown={(event) => {
              if (event.key === 'Enter' && event.keyCode === 13) {
                this.handleSwitchTeam();
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          {this.state.loading ? <CircularProgress className={classes.spinner} /> : <div className={classes.placeholderSpinner} />}
          <Button onClick={this.handleClose} color="primary">
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={this.handleSwitchTeam}
            disabled={!this.state.teamName}
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const withConnect = connect((state: StateType, props: Props) => ({
  open: dialogSelector(state, props.dialogName || 'switchTeam'),
  loginError: loginErrorSelector(state),
}), (dispatch: Function) => bindActionCreators({ closeDialog, switchTeam, resetLoginError }, dispatch));

export default compose(
  withMobileDialog(),
  withStyles(styles),
  withConnect,
)(DialogSwitchTeam);
