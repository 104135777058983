// @flow

import * as constants from './constants';
import type { TypeJoinZoomMeetingOptions, TypeStartZoomMeetingOptions } from '../types';

export function joinMeeting(data: TypeJoinZoomMeetingOptions) {
  return {
    type: constants.JOIN_MEETING,
    value: data,
  };
}

export function startMeeting(data: TypeStartZoomMeetingOptions) {
  return {
    type: constants.START_MEETING,
    value: data,
  };
}


export function endMeeting(meetingId: string) {
  return {
    type: constants.END_MEETING,
    value: meetingId,
  };
}

export function updateZoomMeeting(id: String, metadata: Object) {
  return {
    type: constants.UPDATE_ZOOM_MEETING,
    value: {
      id,
      metadata,
    }
  }
}
