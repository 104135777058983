// @flow

import type { AdapterType } from 'shared-redux/src/graph/types';
import AbstractAdapter from 'shared-redux/src/graph/adapter';
import type { TypeJoinZoomMeetingOptions, TypeStartZoomMeetingOptions } from 'band-redux/src/types';
import * as graphConstants from 'shared-redux/src/graph/constants';
import { agent } from '../lib/api-agent';

(x: GraphAdapter) => (x: AdapterType); // eslint-disable-line no-unused-expressions
export default class GraphAdapter extends AbstractAdapter {
  upsertNode = (nodeType: string, metadata: Object, id: string): Promise<*> =>
    agent
      .put(`/api/v1.0/${nodeType}/${id}`)
      .set('Authorization', `Bearer ${agent.token}`)
      .send(metadata)

  updateNode = (nodeType: string, metadata: Object, id: string): Promise<*> =>
    agent
      .post(`/api/v1.0/${nodeType}/${id}`)
      .set('Authorization', `Bearer ${agent.token}`)
      .send(metadata)

  deleteNode = (nodeType: string, id: string): Promise<*> =>
    agent
      .delete(`/api/v1.0/${nodeType}/${id}`)
      .set('Authorization', `Bearer ${agent.token}`)

  upsertEdge = (parentId: string, childId: string, metadata: Object): Promise<*> =>
    agent
      .put(`/api/v1.0/edges/${parentId}/${childId}`)
      .set('Authorization', `Bearer ${agent.token}`)
      .send(metadata)

  deleteEdge = (parentId: string, childId: string): Promise<*> =>
    agent
      .delete(`/api/v1.0/edges/${parentId}/${childId}`)
      .set('Authorization', `Bearer ${agent.token}`)

  updateEdge = (parentId: string, childId: string, metadata: Object): Promise<*> =>
    agent
      .post(`/api/v1.0/edges/${parentId}/${childId}`)
      .set('Authorization', `Bearer ${agent.token}`)
      .send(metadata)

  addPermission = (parentId: string, childId: string, permissionType: string): Promise<*> =>
    agent
      .put(`/api/v1.0/permissions/${parentId}/${childId}/${permissionType}`)
      .set('Authorization', `Bearer ${agent.token}`)

  removePermission = (sourceId: string, targetId: string, permissionType: string): Promise<*> =>
    agent
      .delete(`/api/v1.0/permissions/${sourceId}/${targetId}/${permissionType}`)
      .set('Authorization', `Bearer ${agent.token}`);

  changeChannel = (deviceId: string, channelId: string, options: Object = {}): Promise<*> =>
    agent
      .post('/api/v1.0/band-channel/change')
      .set('Authorization', `Bearer ${agent.token}`)
      .send({
        channelId,
        deviceIds: [deviceId],
        ...options,
      })

  joinZoomMeeting = (options: TypeJoinZoomMeetingOptions): Promise<*> =>
    agent
      .post(`/api/v1.0/${graphConstants.TYPE_ZOOM_MEETING}/meeting/join/${options.meetingId}`)
      .send(options)
      .set('Authorization', `Bearer ${agent.token}`)

  startZoomMeeting = (options: TypeStartZoomMeetingOptions): Promise<*> =>
    agent
      .post(`/api/v1.0/${graphConstants.TYPE_ZOOM_MEETING}/meeting/start`)
      .send(options)
      .set('Authorization', `Bearer ${agent.token}`)

  stopZoomMeeting = (id: string): Promise<*> =>
    agent
      .post(`/api/v1.0/${graphConstants.TYPE_ZOOM_MEETING}/${id}/meeting/stop`)
      .set('Authorization', `Bearer ${agent.token}`)
      .send({ source: 'client' })
}
