// @flow

/**
*
* LayoutComponentBase
*
*/

import * as React from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import boltClient from '@bunchtogether/bolt-client';
import { boltClientReadySelector } from 'containers/App/selectors';
import { getFlexAlignment, getBackgroundPosition, getPadding } from '../../lib/alignment';

type Props = {
  children?: React.Node,
  color: string,
  backgroundColor: string,
  alignment: number,
  style: Object,
  className: string,
  backgroundImage: Object,
  backgroundImageSize: string,
  backgroundImageAlignment: number, // eslint-disable-line
  boltClientReady: boolean, // eslint-disable-line
  padding: Object, // eslint-disable-line
};

type State = {
  style: Object,
};

export class LayoutComponentBase extends React.PureComponent<Props, State> { // eslint-disable-line react/prefer-stateless-function
  constructor(props: Props) {
    super(props);
    this.state = {
      style: this.makeStyle(props),
    };
  }
  componentDidUpdate(prevProps: Props) {
    if (this.props.color !== prevProps.color || this.props.backgroundColor !== prevProps.backgroundColor || this.props.alignment !== prevProps.alignment || this.props.style !== prevProps.style || this.props.backgroundImage !== prevProps.backgroundImage || this.props.backgroundImageSize !== prevProps.backgroundImageSize || this.props.backgroundImageAlignment !== prevProps.backgroundImageAlignment || this.props.boltClientReady !== prevProps.boltClientReady || this.props.padding !== prevProps.padding) {
      this.updateStyle(this.props);
    }
  }

  updateStyle = (props: Props) => {
    this.setState({
      style: this.makeStyle(props),
    });
  }

  makeStyle(props: Props) {
    const baseStyle = {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      overflow: 'hidden',
    };
    const { color, backgroundColor, style, alignment, backgroundImage, backgroundImageSize, backgroundImageAlignment, padding, boltClientReady } = props;
    const paneStyle: Object = { color, backgroundColor, fill: color };
    if (boltClientReady && backgroundImage && backgroundImageSize) {
      paneStyle.backgroundImage = `url(${boltClient.getUrl(backgroundImage.path)})`;
      paneStyle.backgroundRepeat = 'no-repeat';
      paneStyle.backgroundPosition = getBackgroundPosition(backgroundImageAlignment);
      paneStyle.backgroundSize = backgroundImageSize;
    }
    return Object.assign(baseStyle, paneStyle, style, getFlexAlignment(alignment), getPadding(padding));
  }
  render() {
    const { style } = this.state;
    const { className, children } = this.props;
    return (
      <div className={className} style={style}>{children}</div>
    );
  }
}

const withConnect = connect((state: StateType) => ({
  boltClientReady: boltClientReadySelector(state),
}), (dispatch: Function) => bindActionCreators({}, dispatch));

export default compose(
  withConnect,
)(LayoutComponentBase);

