// @flow

/* eslint-disable no-console */

import superagent from 'superagent';
import { stringify } from 'flatted';

const log = (name:string, level:string, value:any, enableApi: boolean, description?: string) => {
  let color = 'gray';
  switch (level) {
    case 'debug':
      color = 'blue';
      break;
    case 'info':
      color = 'green';
      break;
    case 'warn':
      color = 'orange';
      break;
    case 'error':
      color = 'red';
      break;
    default:
      throw new Error(`Unknown level ${level}`);
  }
  if (typeof value === 'string') {
    console.log(`%c${name}: %c${value}`, `color:${color}; font-weight: bold`, `color:${color}`);
    if (enableApi) {
      superagent.post('http://127.0.0.1:61340/api/1.0/log').set('Content-Type', 'application/json').send({ name, level, value, description }).end((error) => {
        if (error) {
          console.error('Unable to post to logging API');
          console.error(error);
        }
      });
    }
  } else {
    const sanitizedValue = JSON.parse(stringify(value));
    JSON.stringify(sanitizedValue, null, 2).split('\n').forEach((line) => {
      console.log(`%c${name}: %c${line}`, `color:${color}; font-weight: bold`, `color:${color}`);
    });
    if (enableApi) {
      superagent.post('http://127.0.0.1:61340/api/1.0/log').set('Content-Type', 'application/json').send({ name, level, value: sanitizedValue, description }).end((error) => {
        if (error) {
          console.error('Unable to post to logging API');
          console.error(error);
        }
      });
    }
  }
  if (typeof description === 'string') {
    console.log(`%c\t${description}`, 'color:gray');
  }
};

export default (name: string, enableApi: boolean = false) => ({
  debug: (value:any, description?: string) => {
    log(name, 'debug', value, enableApi, description);
  },
  info: (value:any, description?: string) => {
    log(name, 'info', value, enableApi, description);
  },
  warn: (value:any, description?: string) => {
    log(name, 'warn', value, enableApi, description);
  },
  error: (value:any, description?: string) => {
    log(name, 'error', value, enableApi, description);
  },
  debugObject: (obj: Object = {}) => {
    const objString = JSON.stringify(obj, null, 2);
    objString.split('\n').forEach((line) => log(name, 'debug', line, enableApi));
  },
  infoObject: (obj: Object = {}) => {
    const objString = JSON.stringify(obj, null, 2);
    objString.split('\n').forEach((line) => log(name, 'info', line, enableApi));
  },
  warnObject: (obj: Object = {}) => {
    const objString = JSON.stringify(obj, null, 2);
    objString.split('\n').forEach((line) => log(name, 'warn', line, enableApi));
  },
  errorObject: (obj: Object = {}) => {
    const objString = JSON.stringify(obj, null, 2);
    objString.split('\n').forEach((line) => log(name, 'error', line, enableApi));
  },
  errorStack: (error: Error | MediaError) => {
    console.error(error);
  },
});
