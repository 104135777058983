// @flow

/* eslint-disable jsx-a11y/media-has-caption */

import * as React from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { BlendClient, getBlendThumbnailUrl } from 'blend2-client';
import { blendExtensionStatusSelector, deviceIdSelector } from 'containers/App/selectors';
import Video2 from 'components/Video2';
import VideoBlendNotAvailable from 'components/VideoBlendNotAvailable';
import { updateBandDevice } from 'band-redux/src/app/actions';
import { sendAnalytics } from 'containers/App/actions';
import { isBlendUrl } from '../../lib/blendCheck';


type Props = {
  onError?: (error: Error, data?: Object) => void,
  className?: string,
  style?: Object,
  autoPlay?: boolean,
  controls?: boolean,
  height?: number,
  intrinsicsize?: string,
  loop?: boolean,
  muted?: boolean,
  playsInline?: boolean,
  poster?: string,
  preload?: "none" | "metadata" | "auto" | "",
  src: string | null,
  width?: number,
  volume?: number,
  forwardedRef?: any,
  handleNextStream ?: Function, // eslint-disable-line
  active ?: boolean,
  id?: string,
  captions?: boolean,
  blendExtensionAvailable?: boolean,
  updateBandDevice?: Function,
  deviceId?: string,
  sendAnalytics?: Function,
};

export class VideoBlend extends React.PureComponent<Props, void> {
  constructor(props: Props) {
    super(props);
    this.isBuffering = false;
    this.initialLoad = true;
  }

  componentDidMount() {
    this.initialize(this.props.src);
    this.handleCaptions(this.props);
    const element = this.videoElement;
    if (!element) {
      return;
    }
    element.addEventListener('waiting', this.handleWaiting);
    element.addEventListener('playing', this.handlePlaying);
    element.addEventListener('pause', this.handlePause);
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.src !== prevProps.src || this.props.active !== prevProps.active || this.props.blendExtensionAvailable !== prevProps.blendExtensionAvailable) {
      this.handleCloseConnections();
      this.initialize(this.props.src);
    }
    if (prevProps.captions !== this.props.captions) {
      this.handleCaptions(this.props);
    }
  }

  componentWillUnmount() {
    this.handleCloseConnections();
    const element = this.videoElement;
    if (!element) {
      return;
    }
    element.removeEventListener('waiting', this.handleWaiting);
    element.removeEventListener('playing', this.handlePlaying);
    element.removeEventListener('pause', this.handlePause);
  }

  getThumbnail = async (element: HTMLVideoElement, src: string) => {
    try {
      const posterUrl = await getBlendThumbnailUrl(src);
      element.poster = posterUrl; // eslint-disable-line no-param-reassign
    } catch (error) {
      console.log('Error retrieving poster URL: ', error);
    }
  }

  initialize = (src: string | null) => {
    if (!this.videoElement) {
      return;
    }
    if (!src) {
      return;
    }
    if (!this.props.blendExtensionAvailable) {
      return;
    }
    if (!isBlendUrl(src)) {
      return;
    }
    const element = this.videoElement;
    element.textTracks.addEventListener('addtrack', () => {
      this.handleCaptions(this.props);
    });
    if (this.props.active) {
      const client = new BlendClient(element, src);
      this.client = client;
      client.on('error', () => {
        if (this.props.handleNextStream) {
          this.props.handleNextStream();
        }
      });
      client.addListener('handleFallbackStream', () => {
        if (this.props.handleNextStream) {
          this.props.handleNextStream();
        }
      });
    } else {
      this.getThumbnail(element, src);
    }
  }

  handleCloseConnections = async () => {
    const client = this.client;
    if (!client) {
      return;
    }
    this.client = null;
    try {
      await client.ready;
    } catch (error) {
      console.log('Error closing client when waiting for ready: ', error);
    }
    try {
      await client.close();
    } catch (error) {
      console.log('Error closing client: ', error);
    }
  }

  handleWaiting = () => {
    if (!this.initialLoad && !this.isBuffering) {
      if (this.props.sendAnalytics) {
        this.props.sendAnalytics('rebufferstart', { src: this.props.src });
      }
    }
    this.isBuffering = true;
  }

  handlePause = () => {
    if (this.props.sendAnalytics) {
      this.props.sendAnalytics('pause', { src: this.props.src });
    }
  }

  handlePlaying = () => {
    if (!this.initialLoad && this.isBuffering) {
      if (this.props.sendAnalytics) {
        this.props.sendAnalytics('rebufferend', { src: this.props.src });
      }
    } else if (this.props.sendAnalytics) {
      this.props.sendAnalytics('playing', { src: this.props.src });
    }
    this.initialLoad = false;
    this.isBuffering = false;
  }

  handleError = (error: Error, data?: Object) => {
    if (this.props.sendAnalytics) {
      this.props.sendAnalytics('error', { src: this.props.src });
    }
    if (this.props.onError) {
      this.props.onError(error, data);
    }
  }

  handleCaptions = (props: Props) => {
    if (!this.videoElement) {
      return;
    }
    const element = this.videoElement;
    if (element.textTracks && element.textTracks.length > 0) {
      element.textTracks.addEventListener('change', this.handleCaptionStatus, false);
      for (let i = 0; i < element.textTracks.length; i += 1) {
        const track = element.textTracks[i];
        if (track.kind === 'captions' && track.language === 'en') {
          track.mode = props.captions ? 'showing' : 'hidden';
        }
      }
    }
  }

  handleCaptionStatus = (event: { target: any }) => {
    if (!event || !event.target) {
      return;
    }
    const tracks = event.target;
    if (!tracks || tracks.length === 0) {
      return;
    }
    if (this.props.updateBandDevice && this.props.deviceId) {
      for (let i = 0; i < tracks.length; i += 1) {
        if ((tracks[i].kind === 'captions' && tracks[i].language === 'en') || (tracks[i].kind === 'subtitles' && tracks[i].language === 'eng')) {
          if (tracks[i].mode === 'showing') {
            this.props.updateBandDevice(this.props.deviceId, { captions: true });
          }
          if (tracks[i].mode === 'disabled' || tracks[i].mode === 'hidden') {
            this.props.updateBandDevice(this.props.deviceId, { captions: false });
          }
        }
      }
    }
  }

  videoElement: HTMLVideoElement;
  client: any;
  isBuffering: boolean;
  initialLoad: boolean;

  render() {
    const {
      forwardedRef,
      className,
      style,
      autoPlay,
      controls,
      height,
      intrinsicsize,
      loop,
      muted,
      playsInline,
      poster,
      preload,
      width,
      volume,
      active,
      id,
      src,
      blendExtensionAvailable,
    } = this.props;
    if (!src) {
      return null;
    }
    if ((!blendExtensionAvailable)) {
      return <VideoBlendNotAvailable active={active} />;
    }
    return (
      <Video2
        key={id}
        id={id}
        onError={this.handleError}
        style={style}
        className={className}
        autoPlay={autoPlay}
        controls={controls}
        height={height}
        intrinsicsize={intrinsicsize}
        loop={loop}
        muted={muted}
        volume={volume}
        playsInline={playsInline}
        poster={poster}
        preload={preload}
        width={width}
        active={active}
        ref={(e) => {
          if (e) {
            this.videoElement = e;
            if (forwardedRef && typeof forwardedRef === 'function') {
              forwardedRef(e);
            }
          }
        }}
      />
    );
  }
}

const withConnect = connect((state: StateType) => ({
  blendExtensionAvailable: blendExtensionStatusSelector(state),
  deviceId: deviceIdSelector(state),
}), (dispatch: Function) => bindActionCreators({ updateBandDevice, sendAnalytics }, dispatch));

const ComposedVideoBlend = compose(
  withConnect,
)(VideoBlend);

export default React.forwardRef<Props, HTMLVideoElement>((props, ref) => <ComposedVideoBlend {...props} forwardedRef={ref} />); // eslint-disable-line react/no-multi-comp
