// @flow

import * as React from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PortableWifiOff from '@material-ui/icons/PortableWifiOff';
import LoadingVideo1 from '../../images/LoadingVideo1.mp4';
import LoadingVideo2 from '../../images/LoadingVideo2.mp4';
import LoadingVideo3 from '../../images/LoadingVideo3.mp4';

const styles = (theme: Object) => ({
  container: {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    backgroundColor: '#FFF',
  },
  icon: {
    width: '70%',
    height: '70%',
    color: 'lightgrey',
  },
  iconContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  progressWheel: {
    marginBottom: theme.spacing(4),
  },
  text: {
    color: 'grey',
  },
});

type Props = {
  classes: ClassesType,
  active: boolean,
};

function getRandInt() {
  return Math.ceil(Math.random() * Math.floor(3));
}

const videos = [LoadingVideo1, LoadingVideo2, LoadingVideo3];

export class VideoBlendNotAvailable extends React.PureComponent<Props> {
  render() {
    const { classes, active } = this.props;
    const randomNumber = getRandInt();
    return (
      <div className={classes.container}>
        {active ? (
          <div className={classes.iconContainer}>
            <PortableWifiOff className={classes.icon} />
            {active ? <Typography variant='h6' className={classes.text}>Blend Multicast Extension unavailable on this device</Typography> : null}
          </div>
        ) : (<video src={videos[randomNumber - 1]} width='100%' autoPlay loop muted playsInline controls={false} />)}
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
)(VideoBlendNotAvailable);
