// @flow

import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import withNode from '@bunchtogether/boost-client/dist/components/withNode';
import { Map } from 'immutable';
import LayoutComponentBase from 'components/LayoutComponentBase';
import { getPadding, getFlexAlignment, getCssAlignment } from '../../lib/alignment';
import CountryFlagByCode from '../../images/country-flags/CountryFlagByCode';


const styles = () => ({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
});

type Props = {
  classes: ClassesType,
  className?: string,
  node: Map<string, *>,
  backgroundImage: Object,
  backgroundImageSize: string,
  backgroundImageAlignment: number,
  padding: Object,
  alignment: number,
  size: string, // eslint-disable-line
  fgColor: string,
  bgColor: string,
};

type State = {
  flag: React.Node | null,
}

class LayoutComponentFlag extends React.PureComponent<Props, State> {
  state = {
    flag: null,
  }

  componentDidMount() {
    if (this.props.node) {
      this.handleSetFlag(this.props);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.node !== this.props.node && this.props.node || prevProps.size !== this.props.size || prevProps.padding !== this.props.padding) {
      this.handleSetFlag(this.props);
    }
  }

  getFlag = (props: Props) => {
    if (!props.node || !Map.isMap(props.node)) {
      return null;
    }
    const flag = props.node.getIn(['metadata', 'flag']);
    if (!flag || !Map.isMap(flag)) {
      return null;
    }
    const { alignment, size } = props;
    const cssAlignment = getCssAlignment(alignment);
    const flagProps: Object = {};
    if (!size || size === 'cover' || size === 'contain') {
      flagProps.style = {
        width: '100%',
        height: '100%',
        backgroundSize: size || 'contain',
        backgroundPosition: cssAlignment.backgroundPosition,
        backgroundRepeat: 'no-repeat',
      };
    } else {
      flagProps.style = {
        width: size,
        maxWidth: '100%',
      };
    }
    return CountryFlagByCode(flag.get('alpha_2'), flagProps, size);
  };

  handleSetFlag = (props: Props) => {
    this.setState({ flag: this.getFlag(props) });
  }

  render() {
    const { classes, className, node, backgroundImage, backgroundImageSize, backgroundImageAlignment, alignment, padding, fgColor, bgColor } = this.props;
    if (!node || !Map.isMap(node)) {
      return null;
    }
    const style = {
      ...getFlexAlignment(alignment),
      ...getPadding(padding),
    };
    return (
      <LayoutComponentBase
        className={className}
        color={fgColor || 'inherit'}
        backgroundColor={bgColor || 'inherit'}
        backgroundImage={backgroundImage}
        backgroundImageSize={backgroundImageSize}
        backgroundImageAlignment={backgroundImageAlignment}
      >
        <div className={classes.container} style={style}>
          {this.state.flag}
        </div>
      </LayoutComponentBase>
    );
  }
}

export default compose(
  withStyles(styles),
  withNode(),
)(LayoutComponentFlag);

