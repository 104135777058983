// @flow

import { Map as ImmutableMap } from 'immutable';
import * as React from 'react';
import withNode from '@bunchtogether/boost-client/dist/components/withNode';
import LayoutComponentText from 'components/LayoutComponentText';
import LayoutComponentImage from 'components/LayoutComponentImage';
import LayoutComponentIcon from 'components/LayoutComponentIcon';
import LayoutComponentUrl from 'components/LayoutComponentUrl';
import LayoutComponentHtml from 'components/LayoutComponentHtml';
import LayoutComponentVideoStream from 'components/LayoutComponentVideoStream';
import LayoutComponentQumuVccProgram from 'components/LayoutComponentQumuVccProgram';
import LayoutComponentVideo from 'components/LayoutComponentVideo';
import LayoutComponentCarousel from 'components/LayoutComponentCarousel';
import LayoutComponentSpacer from 'components/LayoutComponentSpacer';
import LayoutComponentZoomMeeting from 'components/LayoutComponentZoomMeeting';
import LayoutComponentBluescapeMeeting from 'components/LayoutComponentBluescapeMeeting';
import LayoutComponentPdf from 'components/LayoutComponentPdf';
import LayoutComponentFlag from 'components/LayoutComponentFlag';
import LayoutComponentClock from 'components/LayoutComponentClock';
import LayoutComponentContentLibraryPlaylist from 'components/LayoutComponentContentLibraryPlaylist';
import LayoutComponentContentLibraryPlaylistSearch from 'components/LayoutComponentContentLibraryPlaylistSearch';
import LayoutComponentKeiserRoom from 'components/LayoutComponentKeiserRoom';

type Props = {
  id: string,
  className?: string,
  node?: ImmutableMap<string, *>,
  thumbnail?: boolean,
  layoutWidth: number,
  layoutHeight: number,
  backgroundImage?: Object,
  backgroundImageSize?: string,
  backgroundImageAlignment?: number,
  padding?: Object,
  alignment?: number,
  size?: string,
  fgColor?: string,
  bgColor?: string,
  carouselStyles?: Object,
  channelName?: string,
  channelDescription?: string,
};

function LayoutComponent(props:Props) {
  const {
    id,
    node,
    className,
    thumbnail,
    layoutWidth,
    layoutHeight,
    backgroundImage,
    backgroundImageSize,
    backgroundImageAlignment,
    padding,
    alignment,
    size,
    fgColor,
    bgColor,
    carouselStyles,
  } = props;
  if (!node || !ImmutableMap.isMap(node)) {
    return null;
  }
  const type = node.get('type');
  if (type === 'text') {
    return <LayoutComponentText id={id} className={className} layoutWidth={layoutWidth} layoutHeight={layoutHeight} padding={padding} alignment={alignment} size={size} fgColor={fgColor} bgColor={bgColor} thumbnail={thumbnail} backgroundImage={backgroundImage} backgroundImageSize={backgroundImageSize} backgroundImageAlignment={backgroundImageAlignment} />;
  } else if (type === 'image') {
    return <LayoutComponentImage id={id} className={className} layoutWidth={layoutWidth} layoutHeight={layoutHeight} padding={padding} alignment={alignment} size={size} fgColor={fgColor} bgColor={bgColor} backgroundImage={backgroundImage} backgroundImageSize={backgroundImageSize} backgroundImageAlignment={backgroundImageAlignment} />;
  } else if (type === 'icon') {
    return <LayoutComponentIcon id={id} className={className} layoutWidth={layoutWidth} layoutHeight={layoutHeight} padding={padding} alignment={alignment} size={size} fgColor={fgColor} bgColor={bgColor} backgroundImage={backgroundImage} backgroundImageSize={backgroundImageSize} backgroundImageAlignment={backgroundImageAlignment} />;
  } else if (type === 'qumu-vcc-program') {
    return <LayoutComponentQumuVccProgram id={id} className={className} active={!thumbnail} layoutWidth={layoutWidth} layoutHeight={layoutHeight} padding={padding} alignment={alignment} size={size} fgColor={fgColor} bgColor={bgColor} />;
  } else if (type === 'video') {
    return <LayoutComponentVideo id={id} className={className} active={!thumbnail} layoutWidth={layoutWidth} layoutHeight={layoutHeight} padding={padding} alignment={alignment} size={size} fgColor={fgColor} bgColor={bgColor} backgroundImage={backgroundImage} backgroundImageSize={backgroundImageSize} backgroundImageAlignment={backgroundImageAlignment} />;
  } else if (type === 'url') {
    return <LayoutComponentUrl id={id} className={className} layoutWidth={layoutWidth} layoutHeight={layoutHeight} padding={padding} alignment={alignment} size={size} fgColor={fgColor} bgColor={bgColor} active={!thumbnail} />;
  } else if (type === 'html') {
    return <LayoutComponentHtml id={id} className={className} layoutWidth={layoutWidth} layoutHeight={layoutHeight} padding={padding} alignment={alignment} size={size} fgColor={fgColor} bgColor={bgColor} active={!thumbnail} />;
  } else if (type === 'pdf') {
    return <LayoutComponentPdf id={id} className={className} layoutWidth={layoutWidth} layoutHeight={layoutHeight} padding={padding} alignment={alignment} size={size} fgColor={fgColor} bgColor={bgColor} active={!thumbnail} backgroundImage={backgroundImage} backgroundImageSize={backgroundImageSize} backgroundImageAlignment={backgroundImageAlignment} />;
  } else if (type === 'video-stream') {
    return <LayoutComponentVideoStream id={id} className={className} active={!thumbnail} layoutWidth={layoutWidth} layoutHeight={layoutHeight} padding={padding} alignment={alignment} size={size} fgColor={fgColor} bgColor={bgColor} />;
  } else if (type === 'carousel') {
    return <LayoutComponentCarousel id={id} className={className} layoutWidth={layoutWidth} layoutHeight={layoutHeight} active={!thumbnail} carouselStyles={carouselStyles} />;
  } else if (type === 'spacer') {
    return <LayoutComponentSpacer id={id} className={className} layoutWidth={layoutWidth} layoutHeight={layoutHeight} padding={padding} alignment={alignment} size={size} fgColor={fgColor} bgColor={bgColor} backgroundImage={backgroundImage} backgroundImageSize={backgroundImageSize} backgroundImageAlignment={backgroundImageAlignment} />;
  } else if (type === 'flag') {
    return <LayoutComponentFlag id={id} className={className} layoutWidth={layoutWidth} layoutHeight={layoutHeight} padding={padding} alignment={alignment} size={size} fgColor={fgColor} bgColor={bgColor} backgroundImage={backgroundImage} backgroundImageSize={backgroundImageSize} backgroundImageAlignment={backgroundImageAlignment} />;
  } else if (type === 'clock') {
    return <LayoutComponentClock id={id} className={className} layoutWidth={layoutWidth} layoutHeight={layoutHeight} padding={padding} alignment={alignment} size={size} fgColor={fgColor} bgColor={bgColor} thumbnail={thumbnail} backgroundImage={backgroundImage} backgroundImageSize={backgroundImageSize} backgroundImageAlignment={backgroundImageAlignment} />;
  } else if (type === 'zoom-meeting') {
    return <LayoutComponentZoomMeeting id={id} className={className} layoutWidth={layoutWidth} layoutHeight={layoutHeight} active={!thumbnail} />;
  } else if (type === 'bluescape-workspace') {
    return <LayoutComponentBluescapeMeeting id={id} className={className} layoutWidth={layoutWidth} layoutHeight={layoutHeight} active={!thumbnail} />;
  } else if (type === 'content-library-playlist') {
    if (node.getIn(['metadata', 'playMode']) === 'search') {
      return <LayoutComponentContentLibraryPlaylistSearch id={id} active={!thumbnail} className={className} layoutWidth={layoutWidth} layoutHeight={layoutHeight} padding={padding} alignment={alignment} size={size} fgColor={fgColor} bgColor={bgColor} thumbnail={thumbnail} backgroundImage={backgroundImage} backgroundImageSize={backgroundImageSize} backgroundImageAlignment={backgroundImageAlignment} />;
    }
    return <LayoutComponentContentLibraryPlaylist channelName={props.channelName} channelDescription={props.channelDescription} id={id} active={!thumbnail} className={className} layoutWidth={layoutWidth} layoutHeight={layoutHeight} padding={padding} alignment={alignment} size={size} fgColor={fgColor} bgColor={bgColor} thumbnail={thumbnail} backgroundImage={backgroundImage} backgroundImageSize={backgroundImageSize} backgroundImageAlignment={backgroundImageAlignment} />;
  } else if (type === 'keiser-room') {
    return <LayoutComponentKeiserRoom id={id} className={className} layoutWidth={layoutWidth} layoutHeight={layoutHeight} padding={padding} alignment={alignment} size={size} fgColor={fgColor} bgColor={bgColor} backgroundImage={backgroundImage} backgroundImageSize={backgroundImageSize} backgroundImageAlignment={backgroundImageAlignment} />;
  }
  return null;
}

export default withNode()(LayoutComponent);

