// @flow

import { agent } from './api-agent';

const reload = async () => {
  try {
    const response = await agent.head(window.location.href);
    if (response.statusCode === 200) {
      console.log('Recieved status 200 from webserver, reloading in 3 seconds.');
      await new Promise((resolve) => setTimeout(resolve, 3000));
      window.location.reload(true);
    } else {
      console.log(`Received status ${response.statusCode} from webserver, retrying in 10 seconds.`);
      setTimeout(reload, 10000);
    }
  } catch (error) {
    console.log(`Error while checking webserver, retrying in 10 seconds: ${error.status}`);
    setTimeout(reload, 10000);
  }
};

export default reload;
