// @flow

import * as constants from './constants';

export function updateBandDevice(id: string, metadata: Object, isUser: boolean = false) {
  return {
    type: constants.UPDATE_BAND_DEVICE,
    value: {
      id,
      metadata,
      isUser,
    },
  };
}

export function changeScreenChannel(screenId: string, nextChannelId: ?string, options?: Object = {}): ActionType {
  return {
    type: constants.CHANGE_SCREEN_CHANNEL,
    value: {
      screenId,
      nextChannelId,
      options,
    },
  };
}

export function clearScreenChannel(screenId: string): ActionType {
  return {
    type: constants.CLEAR_SCREEN_CHANNEL,
    value: {
      screenId,
    },
  };
}
