// @flow

import { call, put, takeEvery } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import * as constants from './constants';
import type { AdapterType, CreateDefaultSaga } from './types';
import type { ActionType } from '../types';
import { logout } from '../auth/actions';
import { VALIDATE_TOKEN_RESULT, USER_LOGOUT } from '../auth/constants';

export function* upsertNodeSaga(adapter: AdapterType, action: ActionType): Saga<void> {
  const id = `upsertNode/${action.value.id}`;
  try {
    const response = yield call(adapter.upsertNode, action.value.nodeType, action.value.metadata, action.value.id);
    yield put({ type: constants.SUCCESS,
      value: {
        status: response.status,
        id,
      } });
  } catch (error) {
    error.id = id;
    if (error.status === 401) {
      yield put(logout());
    } else {
      yield put({ type: constants.ERROR, value: error });
    }
  }
}

export function* updateNodeSaga(adapter: AdapterType, action: ActionType): Saga<void> {
  const id = `updateNode/${action.value.id}`;
  try {
    const response = yield call(adapter.updateNode, action.value.nodeType, { ...action.value.metadata }, action.value.id);
    yield put({ type: constants.SUCCESS,
      value: {
        status: response.status,
        id,
      } });
  } catch (error) {
    error.id = id;
    if (error.status === 401) {
      yield put(logout());
    } else {
      yield put({ type: constants.ERROR, value: error });
    }
  }
}

export function* deleteNodeSaga(adapter: AdapterType, action: ActionType): Saga<void> {
  const id = `deleteNode/${action.value.id}`;
  try {
    const response = yield call(adapter.deleteNode, action.value.nodeType, action.value.id);
    yield put({ type: constants.SUCCESS,
      value: {
        status: response.status,
        id,
      } });
  } catch (error) {
    error.id = id;
    if (error.status === 404) {
      yield put({ type: constants.SUCCESS,
        value: {
          status: error.status,
          id,
        } });
    } else if (error.status === 401) {
      yield put(logout());
    } else {
      yield put({ type: constants.ERROR, value: error });
    }
  }
}

export function* deleteNodesSaga(adapter: AdapterType, action: ActionType): Saga<void> {
  const ids = action.value.ids;
  if (ids && ids.length > 0) {
    for (let i = 0; i < ids.length; i += 1) {
      const id = `deleteNode/${ids[i]}`;
      try {
        const response = yield call(adapter.deleteNode, action.value.nodeType, ids[i]);
        yield put({
          type: constants.SUCCESS,
          value: {
            status: response.status,
            id,
          },
        });
      } catch (error) {
        error.id = id;
        if (error.status === 404) {
          yield put({
            type: constants.SUCCESS,
            value: {
              status: error.status,
              id,
            },
          });
        } else if (error.status === 401) {
          yield put(logout());
        } else {
          yield put({ type: constants.ERROR, value: error });
        }
      }
    }
  }
}

export function* upsertEdgeSaga(adapter: AdapterType, action: ActionType): Saga<void> {
  const id = `upsertEdge/${action.value.parentId}/${action.value.childId}`;
  try {
    const response = yield call(adapter.upsertEdge, action.value.parentId, action.value.childId, { ...action.value.metadata });
    yield put({ type: constants.SUCCESS,
      value: {
        status: response.status,
        id,
      } });
  } catch (error) {
    error.id = id;
    if (error.status === 401) {
      yield put(logout());
    } else {
      yield put({ type: constants.ERROR, value: error });
    }
  }
}

export function* deleteEdgeSaga(adapter: AdapterType, action: ActionType): Saga<void> {
  const id = `deleteEdge/${action.value.parentId}/${action.value.childId}`;
  try {
    const response = yield call(adapter.deleteEdge, action.value.parentId, action.value.childId);
    yield put({ type: constants.SUCCESS,
      value: {
        status: response.status,
        id,
      } });
  } catch (error) {
    error.id = id;
    if (error.status === 404) {
      yield put({ type: constants.SUCCESS,
        value: {
          status: error.status,
          id,
        } });
    } else if (error.status === 401) {
      yield put(logout());
    } else {
      yield put({ type: constants.ERROR, value: error });
    }
  }
}

export function* updateEdgeSaga(adapter: AdapterType, action: ActionType): Saga<void> {
  const id = `updateEdge/${action.value.parentId}/${action.value.childId}`;
  try {
    const response = yield call(adapter.updateEdge, action.value.parentId, action.value.childId, action.value.metadata);
    yield put({ type: constants.SUCCESS,
      value: {
        status: response.status,
        id,
      } });
  } catch (error) {
    error.id = id;
    if (error.status === 401) {
      yield put(logout());
    } else {
      yield put({ type: constants.ERROR, value: error });
    }
  }
}

export function* addPermissionSaga(adapter: AdapterType, action: ActionType): Saga<void> {
  const id = `addPermission/${action.value.parentId}/${action.value.childId}/${action.value.permissionType}`;
  try {
    const response = yield call(adapter.addPermission, action.value.parentId, action.value.childId, action.value.permissionType);
    yield put({ type: constants.SUCCESS,
      value: {
        status: response.status,
        id,
      } });
  } catch (error) {
    error.id = id;
    if (error.status === 401) {
      yield put(logout());
    } else {
      yield put({ type: constants.ERROR, value: error });
    }
  }
}

export function* removePermissionSaga(adapter: AdapterType, action: ActionType): Saga<void> {
  const id = `removePermission/${action.value.sourceId}/${action.value.targetId}/${action.value.permissionType}`;
  try {
    const response = yield call(adapter.removePermission, action.value.sourceId, action.value.targetId, action.value.permissionType);
    yield put({ type: constants.SUCCESS,
      value: {
        status: response.status,
        id,
      } });
  } catch (error) {
    error.id = id;
    if (error.status === 404) {
      yield put({ type: constants.SUCCESS,
        value: {
          status: error.status,
          id,
        } });
    } else if (error.status === 401) {
      yield put(logout());
    } else {
      yield put({ type: constants.ERROR, value: error });
    }
  }
}

export function* deleteTagSaga(adapter: AdapterType, action: ActionType): Saga<void> {
  const { target, name } = action.value;
  const id = `deleteTag/${target}/${name}`;
  try {
    const response = yield call(adapter.deleteTag, target, name);
    yield put({ type: constants.SUCCESS,
      value: {
        status: response.status,
        id,
      } });
  } catch (error) {
    error.id = id;
    if (error.status === 404) {
      yield put({ type: constants.SUCCESS,
        value: {
          status: error.status,
          id,
        } });
    } else if (error.status === 401) {
      yield put(logout());
    } else {
      yield put({ type: constants.ERROR, value: error });
    }
  }
}

export function* setAccessTokenSaga(adapter: AdapterType, action: ActionType): Saga<void> { // eslint-disable-line require-yield
  if (action.value.authData) {
    adapter.setAccessToken(action.value.authData.access_token);
  }
}

export function* logoutSaga(adapter: AdapterType): Saga<void> { // eslint-disable-line require-yield
  adapter.setAccessToken('');
}

export default function createDefaultSaga(adapter: AdapterType): CreateDefaultSaga {
  return function* (): Saga<void> {
    yield takeEvery(constants.UPSERT_NODE, upsertNodeSaga, adapter);
    yield takeEvery(constants.UPDATE_NODE, updateNodeSaga, adapter);
    yield takeEvery(constants.DELETE_NODE, deleteNodeSaga, adapter);
    yield takeEvery(constants.DELETE_NODES, deleteNodesSaga, adapter);
    yield takeEvery(constants.UPSERT_EDGE, upsertEdgeSaga, adapter);
    yield takeEvery(constants.DELETE_EDGE, deleteEdgeSaga, adapter);
    yield takeEvery(constants.UPDATE_EDGE, updateEdgeSaga, adapter);
    yield takeEvery(constants.ADD_PERMISSION, addPermissionSaga, adapter);
    yield takeEvery(constants.REMOVE_PERMISSION, removePermissionSaga, adapter);
    yield takeEvery(constants.DELETE_TAG, deleteTagSaga, adapter);
    yield takeEvery(VALIDATE_TOKEN_RESULT, setAccessTokenSaga, adapter);
    yield takeEvery(USER_LOGOUT, logoutSaga, adapter);
  };
}
