// @flow

export const QUERY_PROGRAMS = 'redux/vcc/QUERY_PROGRAMS';
export const QUERY_PROGRAMS_RESULT = 'redux/vcc/QUERY_PROGRAMS_RESULT';
export const QUERY_PROGRAMS_ERROR = 'redux/vcc/QUERY_PROGRAMS_ERROR';

export const QUERY_ADMIN_PROGRAMS = 'redux/vcc/QUERY_ADMIN_PROGRAMS';
export const QUERY_ADMIN_PROGRAMS_RESULT = 'redux/vcc/QUERY_ADMIN_PROGRAMS_RESULT';
export const QUERY_ADMIN_PROGRAMS_ERROR = 'redux/vcc/QUERY_ADMIN_PROGRAMS_ERROR';

export const GET_PROGRAM = 'redux/vcc/GET_PROGRAM';
export const GET_PROGRAM_RESULT = 'redux/vcc/GET_PROGRAM_RESULT';
export const GET_PROGRAM_ERROR = 'redux/vcc/GET_PROGRAM_ERROR';

export const GET_PROGRAM_CONTENT_LOCATIONS = 'redux/vcc/GET_PROGRAM_CONTENT_LOCATIONS';
export const GET_PROGRAM_CONTENT_LOCATIONS_RESULT = 'redux/vcc/GET_PROGRAM_CONTENT_LOCATIONS_RESULT';
export const GET_PROGRAM_CONTENT_LOCATIONS_ERROR = 'redux/vcc/GET_PROGRAM_CONTENT_LOCATIONS_ERROR';
