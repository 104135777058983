// @flow

import type { Saga } from 'redux-saga';
import { takeLatest, takeEvery, call, select } from 'redux-saga/effects';
import { blendBluescape } from 'blend2-client';
import * as constants from './constants';
import type { BandAdapterType, CreateDefaultSaga } from '../types';

export function* swithToBluescapeSaga(graphAdapter: BandAdapterType, action: ActionType): any {
  try {
    yield call(blendBluescape.focus)
  } catch(error) {
    console.log(error)
  }
}

export default function createDefaultSaga(graphAdapter: BandAdapterType): CreateDefaultSaga {
  return function* (): Saga<void> {
    yield takeLatest(constants.SWITCH_TO_BLUESCAPE, swithToBluescapeSaga, graphAdapter);
  };
}
