// @flow

import * as React from 'react';
import { Map, is } from 'immutable';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LayoutComponentBase from 'components/LayoutComponentBase';
import withNode from '@bunchtogether/boost-client/dist/components/withNode';
import Fab from '@material-ui/core/Fab';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import { blendExtensionStatusSelector, mouseActiveSelector } from 'containers/App/selectors';
import bluescapeLogo from '../../images/Bluescape-Logo.png';

const styles = (theme: Object) => ({
  overlay: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    pointerEvents: 'none',
  },
  marginTop: {
    marginTop: theme.spacing(1),
  },
  fab: {
    position: 'absolute',
    top: theme.spacing(7),
    left: theme.spacing(2),
    transition: 'opacity 0.5s',
    '&:hover': {
      opacity: 1,
    },
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  noOpacity: {
    opacity: 0,
  },
  logo: {
    width: 300,
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
});

type Props = {
  classes: ClassesType,
  className?: string,
  node: Map<string, any>,
  padding: Object,
  // blendExtensionStatus: boolean,
  active?: boolean, // eslint-disable-line
  mouseActive: boolean,
};

type State = {
  hasLiveStream: boolean,
};

class LayoutComponentBluescapeMeeting extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.meetingId = null;
    this.state = {
      hasLiveStream: false,
    };
  }

  componentDidMount() {
    if (this.props.node) {
      this.handleJoinMeeting();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (!is(prevProps.node, this.props.node) && this.props.node) {
      this.handleJoinMeeting();
    }
  }

  componentWillUnmount() {
    this.handleLeaveMeeting();
  }

  getButtonStyle = () => {
    const { classes, mouseActive } = this.props;
    if (mouseActive) {
      return classes.fab;
    }
    return `${classes.noOpacity} ${classes.fab}`;
  }

  openTab = (joinUrl: string) => {
    window.open(joinUrl, '_blank');
  }

  handleLeaveMeeting = async () => {
  }

  handleJoinMeeting = async () => {
  }

  meetingId: string | null;

  render() {
    const { className, node, padding, classes } = this.props;
    if (!node || !Map.isMap(node)) {
      return null;
    }
    const uid = node.getIn(['metadata', 'uid']);
    const name = node.getIn(['metadata', 'name']);
    return (
      <LayoutComponentBase
        className={className}
        color={'inherit'}
        backgroundColor={'inherit'}
        alignment={0}
        padding={padding}
      >
        <div className={classes.overlay}>
          <img src={bluescapeLogo} className={classes.logo} alt='Bluescape Logo' />
          <Typography className={classes.marginTop}>Workspace: {name}</Typography>
        </div>
        {uid ? (
          <Fab
            className={this.getButtonStyle()}
            variant='extended'
            onClick={() => this.openTab(`https://client.apps.us.bluescape.com/${uid}`)}
            color='primary'
          >
            <MeetingRoomIcon className={classes.icon} />
            Join Bluescape Workspace
          </Fab>
        ) : null}
      </LayoutComponentBase>
    );
  }
}

const withConnect = connect((state: StateType) => ({
  blendExtensionStatus: blendExtensionStatusSelector(state),
  mouseActive: mouseActiveSelector(state),
}));

export default compose(
  withStyles(styles),
  withConnect,
  withNode(),
)(LayoutComponentBluescapeMeeting);

