// @flow

import type { AdapterType } from './types'; // eslint-disable-line no-unused-vars

(x: AbstractAdapter) => (x: AdapterType); // eslint-disable-line no-unused-expressions
export default class AbstractAdapter {
  shouldFail: boolean;
  accessToken: string;

  constructor() {
    this.shouldFail = false;
  }

  setAccessToken(accessToken: string): void {
    this.accessToken = accessToken;
  }

  query(options: Object): Promise<*> { // eslint-disable-line no-unused-vars
    throw new Error('Method query is not implemented.');
  }

  increment(id: string, data: { title: string, provider: string }): Promise<*> { // eslint-disable-line no-unused-vars
    throw new Error('Method increment is not implemented.');
  }
}
