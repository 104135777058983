// @flow

/* eslint-disable no-unused-vars */

import Hls from 'hls.js';
import makeLogger from '../../lib/logger';

const hlsLogger = makeLogger('HLS.js', true);
const videoLogger = makeLogger('Video Element', true);

export const hlsDebugLogger = (hls: Hls) => {
  hls.on(Hls.Events.MEDIA_ATTACHING, (event, data) => {
    hlsLogger.info(`${Hls.Events.MEDIA_ATTACHING}`, 'Fired before MediaSource is attaching to media element');
  });
  hls.on(Hls.Events.MEDIA_ATTACHED, (event, data) => {
    hlsLogger.info(`${Hls.Events.MEDIA_ATTACHED}`, 'Fired when MediaSource has been succesfully attached to media element');
  });
  hls.on(Hls.Events.MEDIA_DETACHING, (event, data) => {
    hlsLogger.info(`${Hls.Events.MEDIA_DETACHING}`, 'Fired before detaching MediaSource from media element');
  });
  hls.on(Hls.Events.MEDIA_DETACHED, (event, data) => {
    hlsLogger.info(`${Hls.Events.MEDIA_DETACHED}`, 'Fired when MediaSource has been detached from media element');
  });
  hls.on(Hls.Events.BUFFER_RESET, (event, data) => {
    hlsLogger.info(`${Hls.Events.BUFFER_RESET}`, 'Fired when we buffer is going to be reset');
  });
  hls.on(Hls.Events.BUFFER_CODECS, (event, data) => {
    hlsLogger.info(`${Hls.Events.BUFFER_CODECS}`, 'Fired when we know about the codecs that we need buffers for to push into');
    if (data) {
      const logData = Object.assign({}, data);
      if (logData.audio && logData.audio.initSegment) {
        logData.audio = Object.assign({}, logData.audio);
        delete logData.audio.initSegment;
      }
      if (logData.video && logData.video.initSegment) {
        logData.video = Object.assign({}, logData.video);
        delete logData.video.initSegment;
      }
      hlsLogger.info(logData);
    }
  });
  hls.on(Hls.Events.BUFFER_CREATED, (event, data) => {
    hlsLogger.info(`${Hls.Events.BUFFER_CREATED}`, 'Fired when sourcebuffers have been created');
  });
  hls.on(Hls.Events.BUFFER_EOS, (event, data) => {
    hlsLogger.info(`${Hls.Events.BUFFER_EOS}`, 'Fired when the stream is finished and we want to notify the media buffer that there will be no more data');
  });
  hls.on(Hls.Events.BUFFER_FLUSHING, (event, data) => {
    hlsLogger.info(`${Hls.Events.BUFFER_FLUSHING}`, 'Fired when the media buffer should be flushed');
  });
  hls.on(Hls.Events.BUFFER_FLUSHED, (event, data) => {
    hlsLogger.info(`${Hls.Events.BUFFER_FLUSHED}`, 'Fired when the media buffer has been flushed');
  });
  hls.on(Hls.Events.MANIFEST_LOADING, (event, data) => {
    hlsLogger.info(`${Hls.Events.MANIFEST_LOADING}`, 'Fired to signal that a manifest loading starts');
  });
  hls.on(Hls.Events.MANIFEST_LOADED, (event, data) => {
    hlsLogger.info(`${Hls.Events.MANIFEST_LOADED}`, 'Fired after manifest has been loaded');
  });
  hls.on(Hls.Events.MANIFEST_PARSED, (event, data) => {
    hlsLogger.info(`${Hls.Events.MANIFEST_PARSED}`, 'Fired after manifest has been parsed');
  });
  hls.on(Hls.Events.LEVEL_SWITCHING, (event, data) => {
    hlsLogger.info(`${Hls.Events.LEVEL_SWITCHING}`, 'Fired when a level switch is requested');
  });
  hls.on(Hls.Events.LEVEL_SWITCHED, (event, data) => {
    hlsLogger.info(`${Hls.Events.LEVEL_SWITCHED}`, 'Fired when a level switch is effective');
  });
  hls.on(Hls.Events.AUDIO_TRACKS_UPDATED, (event, data) => {
    hlsLogger.info(`${Hls.Events.AUDIO_TRACKS_UPDATED}`, 'Fired to notify that audio track lists has been updated');
  });
  hls.on(Hls.Events.AUDIO_TRACK_SWITCHING, (event, data) => {
    hlsLogger.info(`${Hls.Events.AUDIO_TRACK_SWITCHING}`, 'Fired when an audio track switching is requested');
  });
  hls.on(Hls.Events.AUDIO_TRACK_SWITCHED, (event, data) => {
    hlsLogger.info(`${Hls.Events.AUDIO_TRACK_SWITCHED}`, 'Fired when an audio track switch actually occurs');
  });
  hls.on(Hls.Events.AUDIO_TRACK_LOADING, (event, data) => {
    hlsLogger.info(`${Hls.Events.AUDIO_TRACK_LOADING}`, 'Fired when an audio track loading starts');
  });
  hls.on(Hls.Events.AUDIO_TRACK_LOADED, (event, data) => {
    hlsLogger.info(`${Hls.Events.AUDIO_TRACK_LOADED}`, 'Fired when an audio track loading finishes');
  });
  hls.on(Hls.Events.SUBTITLE_TRACKS_UPDATED, (event, data) => {
    hlsLogger.info(`${Hls.Events.SUBTITLE_TRACKS_UPDATED}`, 'Fired to notify that subtitle track lists has been updated');
  });
  hls.on(Hls.Events.SUBTITLE_TRACK_SWITCH, (event, data) => {
    hlsLogger.info(`${Hls.Events.SUBTITLE_TRACK_SWITCH}`, 'Fired when a subtitle track switch occurs');
  });
  hls.on(Hls.Events.SUBTITLE_TRACK_LOADING, (event, data) => {
    hlsLogger.info(`${Hls.Events.SUBTITLE_TRACK_LOADING}`, 'Fired when a subtitle track loading starts');
  });
  hls.on(Hls.Events.SUBTITLE_TRACK_LOADED, (event, data) => {
    hlsLogger.info(`${Hls.Events.SUBTITLE_TRACK_LOADED}`, 'Fired when a subtitle track loading finishes');
  });
  hls.on(Hls.Events.SUBTITLE_FRAG_PROCESSED, (event, data) => {
    hlsLogger.info(`${Hls.Events.SUBTITLE_FRAG_PROCESSED}`, 'Fired when a subtitle fragment has been processed');
  });
  hls.on(Hls.Events.INIT_PTS_FOUND, (event, data) => {
    hlsLogger.info(`${Hls.Events.INIT_PTS_FOUND}`, 'Fired when the first timestamp is found');
  });
  hls.on(Hls.Events.FRAG_LOAD_EMERGENCY_ABORTED, (event, data) => {
    hlsLogger.info(`${Hls.Events.FRAG_LOAD_EMERGENCY_ABORTED}`, 'Identifier for fragment load aborting for emergency switch down');
    if (data) {
      hlsLogger.info(data);
    }
  });
  /*
  hls.on(Hls.Events.LEVEL_PTS_UPDATED, (event, data) => {
    hlsLogger.info(`${Hls.Events.LEVEL_PTS_UPDATED}`, 'Fired when a level\'s PTS information has been updated after parsing a fragment');
    if(data) {
      hlsLogger.info(data);
    }
  });
  hls.on(Hls.Events.LEVEL_UPDATED, (event, data) => {
    hlsLogger.info(`${Hls.Events.LEVEL_UPDATED}`, 'Fired when a level\'s details have been updated based on previous details, after it has been loaded');
    if(data) {
      hlsLogger.info(data);
    }
  });
  hls.on(Hls.Events.BUFFER_APPENDING, (event, data) => {
    hlsLogger.info(`${Hls.Events.BUFFER_APPENDING}`, 'Fired when we append a segment to the buffer');
    if(data) {
      hlsLogger.info(data);
    }
  });
  hls.on(Hls.Events.BUFFER_APPENDED, (event, data) => {
    hlsLogger.info(`${Hls.Events.BUFFER_APPENDED}`, 'Fired when we are done with appending a media segment to the buffer');
    if(data) {
      hlsLogger.info(data);
    }
  });
  hls.on(Hls.Events.LEVEL_LOADING, (event, data) => {
    hlsLogger.info(`${Hls.Events.LEVEL_LOADING}`, 'Fired when a level playlist loading starts');
    if(data) {
      hlsLogger.info(data);
    }
  });
  hls.on(Hls.Events.LEVEL_LOADED, (event, data) => {
    hlsLogger.info(`${Hls.Events.LEVEL_LOADED}`, 'Fired when a level playlist loading finishes');
    if(data) {
      hlsLogger.info(data);
    }
  });
  hls.on(Hls.Events.FRAG_LOADING, (event, data) => {
    hlsLogger.info(`${Hls.Events.FRAG_LOADING}`, 'Fired when a fragment loading starts');
    if(data) {
      hlsLogger.info(data);
    }
  });
  hls.on(Hls.Events.FRAG_LOAD_PROGRESS, (event, data) => {
    hlsLogger.info(`${Hls.Events.FRAG_LOAD_PROGRESS}`, 'Fired when a fragment load is in progress');
    if(data) {
      hlsLogger.info(data);
    }
  });
  hls.on(Hls.Events.FRAG_LOADED, (event, data) => {
    hlsLogger.info(`${Hls.Events.FRAG_LOADED}`, 'Fired when a fragment loading is completed');
    if(data) {
      hlsLogger.info(data);
    }
  });

  hls.on(Hls.Events.FRAG_DECRYPTED, (event, data) => {
    hlsLogger.info(`${Hls.Events.FRAG_DECRYPTED}`, 'Fired when a fragment decryption is completed');
    if(data) {
      hlsLogger.info(data);
    }
  });
  hls.on(Hls.Events.FRAG_PARSING_INIT_SEGMENT, (event, data) => {
    hlsLogger.info(`${Hls.Events.FRAG_PARSING_INIT_SEGMENT}`, 'Fired when Init Segment has been extracted from fragment');
    if(data) {
      hlsLogger.info(data);
    }
  });
  hls.on(Hls.Events.FRAG_PARSING_USERDATA, (event, data) => {
    hlsLogger.info(`${Hls.Events.FRAG_PARSING_USERDATA}`, 'Fired when parsing sei text is completed');
    if(data) {
      hlsLogger.info(data);
    }
  });
  hls.on(Hls.Events.FRAG_PARSING_METADATA, (event, data) => {
    hlsLogger.info(`${Hls.Events.FRAG_PARSING_METADATA}`, 'Fired when parsing id3 is completed');
    if(data) {
      hlsLogger.info(data);
    }
  });
  hls.on(Hls.Events.FRAG_PARSING_DATA, (event, data) => {
    hlsLogger.info(`${Hls.Events.FRAG_PARSING_DATA}`, 'Fired when moof/mdat have been extracted from fragment');
    if(data) {
      hlsLogger.info(data);
    }
  });
  hls.on(Hls.Events.FRAG_PARSED, (event, data) => {
    hlsLogger.info(`${Hls.Events.FRAG_PARSED}`, 'Fired when fragment parsing is completed');
    if(data) {
      hlsLogger.info(data);
    }
  });
  hls.on(Hls.Events.FRAG_BUFFERED, (event, data) => {
    hlsLogger.info(`${Hls.Events.FRAG_BUFFERED}`, 'Fired when fragment remuxed MP4 boxes have all been appended into SourceBuffer');
    if(data) {
      hlsLogger.info(data);
    }
  });
  hls.on(Hls.Events.FRAG_CHANGED, (event, data) => {
    hlsLogger.info(`${Hls.Events.FRAG_CHANGED}`, 'Fired when fragment matching with current video position is changing');
    if(data) {
      hlsLogger.info(data);
    }
  });
  */
  hls.on(Hls.Events.FPS_DROP, (event, data) => {
    hlsLogger.info(`${Hls.Events.FPS_DROP}`, 'triggered when FPS drop in last monitoring period is higher than given threshold');
  });
  hls.on(Hls.Events.FPS_DROP_LEVEL_CAPPING, (event, data) => {
    hlsLogger.info(`${Hls.Events.FPS_DROP_LEVEL_CAPPING}`, 'triggered when FPS drop triggers auto level capping');
  });
  hls.on(Hls.Events.DESTROYING, (event, data) => {
    hlsLogger.info(`${Hls.Events.DESTROYING}`, 'Fired when hls.js instance starts destroying. Different from MEDIA_DETACHED as one could want to detach and reattach a video to the instance of hls.js to handle mid-rolls for example');
  });
  hls.on(Hls.Events.KEY_LOADING, (event, data) => {
    hlsLogger.info(`${Hls.Events.KEY_LOADING}`, 'Fired when a decryption key loading starts');
  });
  hls.on(Hls.Events.KEY_LOADED, (event, data) => {
    hlsLogger.info(`${Hls.Events.KEY_LOADED}`, 'Fired when a decryption key loading is completed');
  });
  hls.on(Hls.Events.STREAM_STATE_TRANSITION, (event, data) => {
    if (data && (data.nextState === 'PARSING' || data.nextState === 'PARSED' || data.nextState === 'IDLE' || data.nextState === 'FRAG_LOADING')) {
      return;
    }
    hlsLogger.info(`${Hls.Events.STREAM_STATE_TRANSITION}`, 'Fired upon stream controller state transitions');
    if (data) {
      hlsLogger.info(data);
    }
  });
  hls.on(Hls.Events.ERROR, (event, data) => {
    if (data.fatal) {
      // if error is fatal, an action is required to (try to) recover it.
      switch (data.type) {
        case Hls.ErrorTypes.NETWORK_ERROR:
          hlsLogger.error('Fatal network error encountered');
          hlsLogger.error(data);
          break;
        case Hls.ErrorTypes.MEDIA_ERROR:
          hlsLogger.error('Fatal media error encountered');
          hlsLogger.error(data);
          break;
        case Hls.ErrorTypes.OTHER_ERROR:
          hlsLogger.error('Fatal non-network/non-media error');
          hlsLogger.error(data);
          break;
        default:
          hlsLogger.error(data);
          break;
      }
    } else {
      // if error is not fatal, hls.js will try to recover it
      switch (data.type) {
        case Hls.ErrorTypes.NETWORK_ERROR:
          hlsLogger.error('Non-fatal network error encountered');
          hlsLogger.error(data);
          break;
        case Hls.ErrorTypes.MEDIA_ERROR:
          hlsLogger.error('Non-fatal media error encountered');
          hlsLogger.error(data);
          break;
        case Hls.ErrorTypes.OTHER_ERROR:
          hlsLogger.error('Non-fatal non-network/non-media error');
          hlsLogger.error(data);
          break;
        case Hls.ErrorTypes.MUX_ERROR:
          hlsLogger.error('Non-fatal mux error encountered');
          hlsLogger.error(data);
          break;
        default:
          hlsLogger.error(data);
          break;
      }
    }
  });
};

export const videoDebugLogger = (element: HTMLMediaElement) => {
  element.addEventListener('canplay', (event: MessageEvent) => {
    videoLogger.info('canplay', 'Sent when enough data is available that the media can be played, at least for a couple of frames.  This corresponds to the HAVE_ENOUGH_DATA readyState');
  });
  element.addEventListener('canplaythrough', (event: MessageEvent) => {
    videoLogger.info('canplaythrough', 'Sent when the ready state changes to CAN_PLAY_THROUGH, indicating that the entire media can be played without interruption, assuming the download rate remains at least at the current level. It will also be fired when playback is toggled between paused and playing. Note: Manually setting the currentTime will eventually fire a canplaythrough event in firefox. Other browsers might not fire this event');
  });
  element.addEventListener('durationchange', (event: MessageEvent) => {
    videoLogger.info('durationchange', 'The metadata has loaded or changed, indicating a change in duration of the media.  This is sent, for example, when the media has loaded enough that the duration is known');
  });
  element.addEventListener('emptied', (event: MessageEvent) => {
    videoLogger.info('emptied', 'The media has become empty; for example, this event is sent if the media has already been loaded (or partially loaded), and the load() method is called to reload it');
  });
  element.addEventListener('encrypted', (event: MessageEvent) => {
    videoLogger.info('encrypted', ' The user agent has encountered initialization data in the media data');
  });
  element.addEventListener('ended', (event: MessageEvent) => {
    videoLogger.info('ended', 'Sent when playback completes');
  });
  element.addEventListener('error', (event:ProgressEvent) => {
    const mediaError = element.error;
    const message = mediaError && mediaError.message ? mediaError.message : null;
    if (mediaError && message) {
      videoLogger.error(`${mediaError.code}: ${message}`);
    } else {
      videoLogger.error('error', 'Sent when an error occurs.  The element\'s error attribute contains more information. See HTMLMediaElement.error for details');
      if (event) {
        videoLogger.error(event);
      }
    }
  });
  element.addEventListener('interruptbegin', (event: MessageEvent) => {
    videoLogger.info('interruptbegin', 'Sent when audio playing on a Firefox OS device is interrupted, either because the app playing the audio is sent to the background, or audio in a higher priority audio channel begins to play. See Using the AudioChannels API for more details');
  });
  element.addEventListener('interruptend', (event: MessageEvent) => {
    videoLogger.info('interruptend', 'Sent when previously interrupted audio on a Firefox OS device commences playing again — when the interruption ends. This is when the associated app comes back to the foreground, or when the higher priority audio finished playing. See Using the AudioChannels API for more details');
  });
  element.addEventListener('loadeddata', (event: MessageEvent) => {
    videoLogger.info('loadeddata', 'The first frame of the media has finished loading');
  });
  element.addEventListener('loadedmetadata', (event: MessageEvent) => {
    videoLogger.info('loadedmetadata', 'The media\'s metadata has finished loading; all attributes now contain as much useful information as they\'re going to');
  });
  element.addEventListener('loadstart', (event:ProgressEvent) => {
    videoLogger.info('loadstart', 'Sent when loading of the media begins');
  });
  element.addEventListener('mozaudioavailable', (event: MessageEvent) => {
    videoLogger.info('mozaudioavailable', 'Sent when an audio buffer is provided to the audio layer for processing; the buffer contains raw audio samples that may or may not already have been played by the time you receive the event');
  });
  element.addEventListener('pause', (event: MessageEvent) => {
    videoLogger.info('pause', 'Sent when the playback state is changed to paused (paused property is true)');
  });
  element.addEventListener('play', (event: MessageEvent) => {
    videoLogger.info('play', 'Sent when the playback state is no longer paused, as a result of the play method, or the autoplay attribute');
  });
  element.addEventListener('playing', (event: MessageEvent) => {
    videoLogger.info('playing', 'Sent when the media has enough data to start playing, after the play event, but also when recovering from being stalled, when looping media restarts, and after seeked, if it was playing before seeking');
  });
  element.addEventListener('ratechange', (event: MessageEvent) => {
    videoLogger.info('ratechange', 'Sent when the playback speed changes');
  });
  element.addEventListener('seeked', (event: MessageEvent) => {
    videoLogger.info('seeked', 'Sent when a seek operation completes');
  });
  element.addEventListener('seeking', (event: MessageEvent) => {
    videoLogger.info('seeking', 'Sent when a seek operation begins');
  });
  element.addEventListener('stalled', (event: MessageEvent) => {
    videoLogger.info('stalled', 'Sent when the user agent is trying to fetch media data, but data is unexpectedly not forthcoming');
  });
  element.addEventListener('suspend', (event: MessageEvent) => {
    videoLogger.info('suspend', 'Sent when loading of the media is suspended; this may happen either because the download has completed or because it has been paused for any other reason');
  });
  element.addEventListener('volumechange', (event: MessageEvent) => {
    videoLogger.info('volumechange', 'Sent when the audio volume changes (both when the volume is set and when the muted attribute is changed)');
  });
  element.addEventListener('waiting', (event: MessageEvent) => {
    videoLogger.info('waiting', 'Sent when the requested operation (such as playback) is delayed pending the completion of another operation (such as a seek)');
  });
  /*
  element.addEventListener('progress', (event:ProgressEvent) => {
    videoLogger.info('progress', 'Sent periodically to inform interested parties of progress downloading the media. Information about the current amount of the media that has been downloaded is available in the media element\'s buffered attribute');
    if (event) {
      videoLogger.info(event);
    }
  });
  element.addEventListener('timeupdate', (event) => {
    videoLogger.info('timeupdate', 'The time indicated by the element\'s currentTime attribute has changed');
    if (event) {
      videoLogger.info(event);
    }
  });
  */
};
