// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import boltClient from '@bunchtogether/bolt-client';
import DeviceItem from 'components/DeviceItem';
import { resultSelector } from 'shared-redux/src/video-elephant/selectors';
import type { VideoElephantType } from 'shared-redux/src/video-elephant/types';
import { boltClientReadySelector } from 'containers/App/selectors';

type Props = {
  id: string, // eslint-disable-line react/no-unused-prop-types
  video: VideoElephantType,
  onClick: Function,
  boltClientReady: boolean,
};

export class DeviceItemVideoElephant extends React.PureComponent<Props> {
  render() {
    const { video, boltClientReady } = this.props;
    if (!boltClientReady) {
      return null;
    }
    return (
      <DeviceItem
        onClick={this.props.onClick}
        name={video.title}
        description={video.description}
        content={<div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            backgroundSize: 'cover',
            backgroundImage: `url(${boltClient.getUrl((video.thumbnail || '').replace(/^https?:\/\//, 'no-expire/'))})`,
          }}
        />}
      />
    );
  }
}

export default connect((state: StateType, props: Props) => ({
  video: resultSelector(state, props.id),
  boltClientReady: boltClientReadySelector(state),
}))(DeviceItemVideoElephant);
