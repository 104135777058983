// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import MenuIcon from '@material-ui/icons/Menu';
import { channelSelectorOpenSelector, pathnameSelector, mouseActiveSelector, menuButtonVisibleSelector } from 'containers/App/selectors';
import { openChannelSelector, closeChannelSelector } from 'containers/App/actions';

const styles = (theme: Object) => ({ // eslint-disable-line no-unused-vars
  noOpacity: {
    opacity: 0,
  },
  button: {
    position: 'fixed',
    right: theme.spacing(3),
    zIndex: 12,
    transition: 'opacity 0.5s',
    '&:hover': {
      opacity: 1,
    },
    [theme.breakpoints.up('md')]: {
      top: theme.spacing(6),
    },
    [theme.breakpoints.down('sm')]: {
      right: theme.spacing(2),
      bottom: theme.spacing(2),
      width: theme.spacing(6),
      height: theme.spacing(6),
    },
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  icon: {
    fontSize: 64,
    [theme.breakpoints.down('sm')]: {
      fontSize: 32,
    },
  },
});

type Props = {
  classes: ClassesType,
  pathname: string,
  open: boolean,
  mouseActive: boolean,
  menuButtonVisible: boolean,
  openChannelSelector: Function,
  closeChannelSelector: Function
};

type State = {};

class Menu extends React.PureComponent<Props, State> { // eslint-disable-line react/prefer-stateless-function
  handleButtonClick = (e) => {
    e.preventDefault();
    if (this.props.open) {
      this.props.closeChannelSelector();
    } else {
      this.props.openChannelSelector();
    }
  }

  render() {
    const { classes, open, mouseActive, pathname, menuButtonVisible } = this.props;
    return (
      <Fab
        color="secondary"
        aria-label="edit"
        size="large"
        onMouseDown={this.handleButtonClick}
        className={menuButtonVisible || open || mouseActive || pathname === '/' ? classes.button : `${classes.noOpacity} ${classes.button}`}
      >
        <MenuIcon className={classes.icon} />
      </Fab>
    );
  }
}

const withConnect = connect((state: StateType) => ({
  pathname: pathnameSelector(state),
  open: channelSelectorOpenSelector(state),
  mouseActive: mouseActiveSelector(state),
  menuButtonVisible: menuButtonVisibleSelector(state),
}), (dispatch: Function) => bindActionCreators({ openChannelSelector, closeChannelSelector }, dispatch));

export default compose(
  withStyles(styles, { withTheme: true }),
  withConnect,
)(Menu);
