// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import withNode from '@bunchtogether/boost-client/dist/components/withNode';
import { withStyles } from '@material-ui/core/styles';
import { deviceIdSelector, volumeSelector, mutedSelector, isMultiVideoSelector, audioSourceSelector, canAutoplayUnmutedSelector } from 'containers/App/selectors';
import { addLayoutVideo, removeLayoutVideo } from 'containers/App/actions';
import { Map as ImmutableMap } from 'immutable';
import { updateBandDevice } from 'band-redux/src/app/actions';
import DialogMuteRequired from 'components/DialogMuteRequired';
import FullScreenButton from '../FullScreenButton';
import { getUserAgentParameters } from '../../lib/device';

const STATUS_PREFIX = 'com.qumu.vcc.embed.status:';
const PLAY_PREFIX = 'com.qumu.vcc.embed.play:';
const PAUSE_PREFIX = 'com.qumu.vcc.embed.pause:';
const SETMUTED_PREFIX = 'com.qumu.vcc.embed.setMuted:';
const SETVOLUME_PREFIX = 'com.qumu.vcc.embed.setVolume:';
const SETPOSITION_PREFIX = 'com.qumu.vcc.embed.setPosition:';
const FULLSCREEN_ENABLED_PREFIX = 'com.qumu.vcc.embed.fs.enabled';
const FULLSCREEN_QUERY_PREFIX = 'com.qumu.vcc.embed.fs.query';

const LOADING = 1; // eslint-disable-line no-unused-vars
const WAITING = 2; // eslint-disable-line no-unused-vars
const PLAYING = 3; // eslint-disable-line no-unused-vars
const ENDED = 4; // eslint-disable-line no-unused-vars
const CLICK_TO_PLAY = 5; // eslint-disable-line no-unused-vars
const ERROR = 6; // eslint-disable-line no-unused-vars
const IOS_CLICK_TO_PLAY = 7; // eslint-disable-line no-unused-vars

const styles = (theme: Object) => ({ // eslint-disable-line no-unused-vars
  placeholder: {
    position: 'relative',
    display: 'block',
    width: '100%',
    height: '100%',
    margin: 'auto',
    textAlign: 'center',
    backgroundColor: 'white',
  },
  content: {
    position: 'relative',
    height: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    width: '100%',
    height: '100%',
    border: 'none',
    margin: 0,
    padding: 0,
    zIndex: 5,
  },
  iframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height: '100%',
    border: 'none',
    margin: 0,
    padding: 0,
    overflow: 'hidden',
    zIndex: 10,
  },
});

type Props = {
  deviceId: string,
  active?: boolean,
  volume?: number,
  muted?: boolean,
  protocol?: string,
  hostname?: string,
  domain?: string,
  port?: string,
  thumbnail?: string,
  vccProgramId?: string,
  updateBandDevice: Function,
  classes: {[string]: string},
  audioSource?: string,
  id: string,
  volume: number,
  addLayoutVideo: Function,
  removeLayoutVideo: Function,
  canAutoplayUnmuted: boolean
};

type State = {
  muteRequiredDialogOpen: boolean,
  duration?: number,
  mediaState?: number,
  muted?: boolean,
  position?: number,
  viewState?: number,
  volume?: number,
};

class QumuVccProgram extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super();
    this.state = {
      muteRequiredDialogOpen: false,
    };
    this.listening = false;
    this.userAgent = getUserAgentParameters();
    this.muteRequired = props.canAutoplayUnmuted === undefined ? false : !props.canAutoplayUnmuted;
  }

  componentDidMount() {
    this.setMuted(true);
    if (this.props.active) {
      this.addMessageListener();
    }
    if (this.props.addLayoutVideo && this.props.active && this.props.id) {
      this.props.addLayoutVideo(this.props.id);
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.vccProgramId !== prevProps.vccProgramId) {
      this.resetMuteRequired();
    }
    if ((this.props.active !== prevProps.active || this.props.id !== prevProps.id) && this.props.removeLayoutVideo && this.props.addLayoutVideo) {
      if (prevProps.active && prevProps.id) {
        this.props.removeLayoutVideo(prevProps.id);
      }
      if (this.props.active && this.props.id) {
        this.props.addLayoutVideo(this.props.id);
      }
    }
    if (this.props.active !== prevProps.active) {
      if (this.props.active) {
        this.addMessageListener();
      } else {
        this.removeMessageListener();
      }
    }
    if (prevProps.canAutoplayUnmuted !== this.props.canAutoplayUnmuted && this.props.canAutoplayUnmuted !== undefined) {
      this.muteRequired = !this.props.canAutoplayUnmuted;
      if (typeof this.props.muted === 'boolean') {
        this.setMuted(this.props.muted);
      }
    }
    if (this.props.active) {
      if (this.props.muted !== prevProps.muted && this.state.muted !== this.props.muted && typeof this.props.muted === 'boolean') {
        if (this.props.audioSource === this.props.id) {
          if (this.muteRequired) {
            if (typeof prevProps.muted === 'boolean') {
              this.showMuteRequiredDialog();
            }
            return;
          }
          this.setMuted(this.props.muted);
        } else {
          this.setMuted(true);
        }
      }
      if (this.props.volume !== prevProps.volume && this.state.volume !== this.props.volume && typeof this.props.volume === 'number') {
        if (this.props.audioSource === this.props.id) {
          if (this.muteRequired) {
            if (typeof prevProps.volume === 'number') {
              this.showMuteRequiredDialog();
            }
            return;
          }
          this.setVolume(this.props.volume);
        }
      }
      if (prevProps.audioSource !== this.props.audioSource && this.props.audioSource) {
        if (this.props.audioSource !== this.props.id) {
          this.setMuted(true);
          setTimeout(() => {
            this.setMuted(true);
          }, 1000);
        } else {
          this.setMuted(false);
          if (this.props.volume && typeof this.props.volume === 'number') {
            this.setVolume(this.props.volume);
          }
        }
      }
      if (this.muteRequired && this.state.muted !== prevState.muted && this.state.muted === false && this.state.viewState === PLAYING) {
        this.muteRequired = false;
      }
      if (this.state.muted !== prevState.muted && this.state.muted !== this.props.muted) {
        const metadata:Object = {
          muted: this.state.muted,
        };
        if (this.props.id === this.props.audioSource) {
          metadata.audioSource = this.props.id;
          this.handleUpdateBandDevice(this.props.deviceId, metadata);
        }
      }
      if (this.state.volume !== prevState.volume && this.state.volume !== this.props.volume) {
        const metadata: Object = {
          volume: this.state.volume,
        };
        if (this.props.id !== this.props.audioSource) {
          metadata.audioSource = this.props.id;
        }
        this.handleUpdateBandDevice(this.props.deviceId, metadata);
      }
    }
  }

  componentWillUnmount() {
    if (this.props.removeLayoutVideo && this.props.active && this.props.id) {
      this.props.removeLayoutVideo(this.props.id);
    }
    clearTimeout(this.muteRequiredDialogTimeout);
    this.removeMessageListener();
  }

  setMuted(muted: boolean) {
    if (this.iframe && this.iframe.contentWindow) {
      this.iframe.contentWindow.postMessage(SETMUTED_PREFIX + muted.toString(), '*');
    }
  }

  setPosition(position: number) {
    if (this.iframe && this.iframe.contentWindow) {
      this.iframe.contentWindow.postMessage(SETPOSITION_PREFIX + position.toString(), '*');
    }
  }

  setVolume(volume: number) {
    if (this.iframe && this.iframe.contentWindow) {
      this.iframe.contentWindow.postMessage(SETVOLUME_PREFIX + volume.toString(), '*');
    }
  }

  handleUpdateBandDevice = (id: string, metadata: Object) => {
    this.props.updateBandDevice(id, metadata);
  }

  addMessageListener() {
    if (window && !this.listening && this.props.active) {
      this.listening = true;
      window.addEventListener('message', this.messageListener, false);
    }
  }

  removeMessageListener() {
    if (window && this.listening) {
      this.listening = false;
      window.removeEventListener('message', this.messageListener, false);
    }
  }

  handleMuteRequiredDialogClose = () => {
    clearTimeout(this.muteRequiredDialogTimeout);
    this.setState({
      muteRequiredDialogOpen: false,
    });
  }

  resetMuteRequired() {
    this.muteRequired = !this.props.canAutoplayUnmuted;
  }

  showMuteRequiredDialog() {
    clearTimeout(this.muteRequiredDialogTimeout);
    this.setState({
      muteRequiredDialogOpen: true,
    });
    this.muteRequiredDialogTimeout = setTimeout(() => {
      this.setState({
        muteRequiredDialogOpen: false,
      });
    }, 5000);
  }

  pause() {
    if (this.iframe && this.iframe.contentWindow) {
      this.iframe.contentWindow.postMessage(`${PAUSE_PREFIX}{}`, '*');
    }
  }

  play() {
    if (this.iframe && this.iframe.contentWindow) {
      this.iframe.contentWindow.postMessage(`${PLAY_PREFIX}{}`, '*');
    }
  }

  reloadIframe() {
    const iframe = this.iframe;
    if (iframe) {
      const src = iframe.src;
      iframe.src = src;
    }
  }

  iframe: ?HTMLIFrameElement;
  loopTimeout: any;
  listening: boolean;
  userAgent: Object;
  muteRequired: boolean;
  muteRequiredDialogTimeout: TimeoutID;

  messageListener = (event) => {
    const { protocol, hostname } = this.props;
    if (!protocol || !hostname) {
      return;
    }
    if (event.origin === `${protocol}://${hostname}` && this.iframe && event.source === this.iframe.contentWindow) {
      try {
        if (event.data === FULLSCREEN_QUERY_PREFIX) {
          event.source.postMessage(FULLSCREEN_ENABLED_PREFIX, event.origin);
          return;
        }
        if (event.data.substr(0, STATUS_PREFIX.length) !== STATUS_PREFIX) {
          return;
        }
        const message = JSON.parse(event.data.substr(STATUS_PREFIX.length));
        this.setState(message);
        // If we encounter an error, reload
        // the iframe.
        if (message.viewState === ERROR) {
          console.log('Reloading because of program error.'); // eslint-disable-line no-console
          this.reloadIframe();
          return;
        }
        if (typeof message.position !== 'number') {
          return;
        }
        if (typeof message.duration === 'number') {
          // Calculate time remaining in program.
          const timeRemaining = (message.duration - message.position) * 1000;
          // If still playing with 5 seconds left, set a
          // timeout to restart the video 250ms before the end
          if (message.viewState === PLAYING && timeRemaining < 5000 && !this.loopTimeout) {
            this.loopTimeout = setTimeout(() => {
              this.setPosition(0);
              this.loopTimeout = null;
            }, timeRemaining - 250);
            return;
          }
          // If for some reason we reach the ended state, fall back to reloading
          // the iframe.
          if (message.viewState === ENDED) {
            console.log('Reloading at end of program.'); // eslint-disable-line no-console
            this.reloadIframe();
            return;
          }
        }
        if (message.viewState === ENDED || message.viewState === CLICK_TO_PLAY || message.viewState === WAITING) {
          if (this.userAgent.osName === 'Android' || this.userAgent.osName === 'iOS') {
            return;
          }
          if (this.muteRequired) {
            this.setMuted(true);
          }
          this.play();
        }
      } catch (error) {
        console.error(error.message); // eslint-disable-line no-console
      }
    }
  }

  metadata: Object;

  render() {
    const { muteRequiredDialogOpen } = this.state;
    const { active, classes, protocol, hostname, port, domain, thumbnail, vccProgramId } = this.props;
    if (!protocol || !hostname || !port || !domain || !vccProgramId) {
      return null;
    }
    let thumbnailUrl;
    if (thumbnail) {
      thumbnailUrl = thumbnail.indexOf('http') === -1 ? `${protocol}://${hostname}:${port}/viewerportal/${domain}/${thumbnail}` : thumbnail;
    }
    if (!active) {
      return (
        <div className={classes.placeholder}>
          <div className={classes.content} style={thumbnailUrl ? { backgroundImage: `url(${thumbnailUrl})` } : {}} />
        </div>
      );
    }
    const src = `${protocol}://${hostname}/viewerportal/${domain}/embed.vp?programId=${encodeURIComponent(vccProgramId)}&taxonomy=false&supp=false&titlebar=false&vcclink=false&autoplay=false&offset=0&startTime=0`;
    return (<React.Fragment>
      <div className={classes.container}>
        <iframe
          ref={(iframe) => { this.iframe = iframe; }}
          className={classes.iframe}
          seamless
          title={'VCC Program'}
          frameBorder={0}
          allowFullScreen
          src={src}
        />
        <FullScreenButton />
      </div>
      <DialogMuteRequired
        open={muteRequiredDialogOpen}
        onClose={this.handleMuteRequiredDialogClose}
        aria-labelledby='mute-dialog-title'
      />
    </React.Fragment>);
  }
}


const withConnect = connect((state:StateType, props: {node: ImmutableMap<string, *>}) => {
  const data:Object = {
    canAutoplayUnmuted: canAutoplayUnmutedSelector(state),
    deviceId: deviceIdSelector(state),
    volume: volumeSelector(state),
    muted: mutedSelector(state),
    audioSource: audioSourceSelector(state),
    isMultiVideo: isMultiVideoSelector(state),
  };
  const { node } = props;
  if (ImmutableMap.isMap(node)) {
    data.protocol = node.getIn(['metadata', 'protocol']);
    data.hostname = node.getIn(['metadata', 'hostname']);
    data.domain = node.getIn(['metadata', 'domain']);
    data.port = node.getIn(['metadata', 'port']);
    data.thumbnail = node.getIn(['metadata', 'thumbnail']);
    data.vccProgramId = node.getIn(['metadata', 'vccProgramId']);
  }
  return data;
}, (dispatch: Function) => bindActionCreators({ updateBandDevice, addLayoutVideo, removeLayoutVideo }, dispatch));

export default compose(
  withStyles(styles),
  withNode(),
  withConnect,
)(QumuVccProgram);

