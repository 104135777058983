// @flow

import PQueue from 'p-queue';
import type { Saga } from 'redux-saga';
import { takeLatest, takeEvery, call } from 'redux-saga/effects';
import * as graphConstants from 'shared-redux/src/graph/constants';
import * as constants from './constants';
import type { BandAdapterType, CreateDefaultSaga } from '../types';

const queues = {};

const getQueue = (id: string) => {
  let queue = queues[id];
  if (!queue) {
    queue = new PQueue({ concurrency: 1 });
    queues[id] = queue;
    const handleActive = () => {
      queue.removeListener('active', handleActive);
      queue.onIdle().then(() => {
        delete queues[id];
      });
    };
    queue.on('active', handleActive);
  }
  return queue;
};

export function updateBandDeviceSaga(adapter: BandAdapterType, action: ActionType): any {
  try {
    const type = action.value.isUser ? graphConstants.TYPE_USER : graphConstants.TYPE_BAND_DEVICE;
    if (action.value.id) {
      getQueue(action.value.id).add(() => adapter.updateNode(type, action.value.metadata, action.value.id));
    }
  } catch (error) {
    console.error('Error updating band devices: ', error);
  }
}

export function* changeScreenChannelSaga(adapter: BandAdapterType, action: ActionType): Saga<void> {
  try {
    yield call(adapter.changeChannel, action.value.screenId, action.value.nextChannelId, action.value.options);
  } catch (error) {
    console.error('Error changing screen channel: ', error);
  }
}

export function* clearScreenChannelSaga(adapter: BandAdapterType, action: ActionType): Saga<void> {
  try {
    yield call(adapter.changeChannel, action.value.screenId);
  } catch (error) {
    console.error('Error clearing screen channel: ', error);
  }
}

export default function createDefaultSaga(graphAdapter: BandAdapterType & {changeChannel: () => void}): CreateDefaultSaga {
  return function* (): Saga<void> {
    yield takeLatest(constants.UPDATE_BAND_DEVICE, updateBandDeviceSaga, graphAdapter);
    yield takeEvery(constants.CHANGE_SCREEN_CHANNEL, changeScreenChannelSaga, graphAdapter);
    yield takeEvery(constants.CLEAR_SCREEN_CHANNEL, clearScreenChannelSaga, graphAdapter);
  };
}
