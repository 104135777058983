// @flow

import { call, put, takeEvery } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import get from 'lodash/get';
import * as constants from './constants';
import type { AdapterType, CreateDefaultSaga } from './types';
import type { ActionType } from '../types';
import { VALIDATE_TOKEN_RESULT, USER_LOGOUT } from '../auth/constants';

export function* querySaga(adapter: AdapterType, action: ActionType): Saga<*> {
  try {
    const options = action.value.options || {};
    const value = yield call(adapter.query, options);
    const results = get(value, 'body.hits.hit');
    const start = get(value, 'body.hits.start');
    const found = get(value, 'body.hits.found');
    yield put({ type: constants.QUERY_RESULT,
      value: {
        queryGroupId: action.value.queryGroupId,
        queryId: action.value.queryId,
        created: new Date(),
        hasMore: (typeof start === 'number' && typeof found === 'number') ? (found > start + results.length) : false,
        result: results.map((result: Object) => ({
          id: result.id,
          category: get(result, 'fields.category[0]'),
          credit: get(result, 'fields.credit[0]'),
          description: get(result, 'fields.description[0]'),
          duration: parseInt(get(result, 'fields.duration[0]'), 0),
          evergreen: get(result, 'fields.evergreen[0]') === 'true',
          keywords: get(result, 'fields.keywords'),
          pub_date: get(result, 'fields.pub_date[0]'),
          tags: get(result, 'fields.tags'),
          thumbnail: get(result, 'fields.thumbnail[0]'),
          title: get(result, 'fields.title[0]'),
          url: get(result, 'fields.url[0]'),
        })),
      } });
  } catch (error) {
    yield put({ type: constants.QUERY_ERROR, value: error });
  }
}

export function* incrementCountSaga(adapter: AdapterType, action: ActionType): Saga<*> {
  try {
    yield call(adapter.increment, action.value.id, {
      title: action.value.title,
      provider: action.value.credit,
    });
  } catch (error) {
    console.log(error);
  }
}

export function* setAccessTokenSaga(adapter: AdapterType, action: ActionType): Saga<void> { // eslint-disable-line require-yield
  if (action.value.authData) {
    adapter.setAccessToken(action.value.authData.access_token);
  }
}

export function* logoutSaga(adapter: AdapterType): Saga<void> { // eslint-disable-line require-yield
  adapter.setAccessToken('');
}

export default function createDefaultSaga(adapter: AdapterType): CreateDefaultSaga {
  return function* (): Saga<*> {
    yield takeEvery(constants.QUERY, querySaga, adapter);
    yield takeEvery(constants.INCREMENT_COUNT, incrementCountSaga, adapter);
    yield takeEvery(VALIDATE_TOKEN_RESULT, setAccessTokenSaga, adapter);
    yield takeEvery(USER_LOGOUT, logoutSaga, adapter);
  };
}
