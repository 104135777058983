// @flow

/* eslint-disable jsx-a11y/media-has-caption */

import * as React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import boltClient from '@bunchtogether/bolt-client';
import { parse } from 'url';
import superagent from 'superagent';
import { debounce } from 'lodash';
import VideoSilverlightControls from 'components/VideoSilverlightControls';
import { compose, bindActionCreators } from 'redux';
import { updateBandDevice } from 'band-redux/src/app/actions';
import { addLayoutVideo, removeLayoutVideo } from 'containers/App/actions';
import { deviceIdSelector, boltClientReadySelector } from 'containers/App/selectors';

const styles = () => ({
  container: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'black',
  },
  anchor: {
    textDecoration: 'none',
  },
  image: {
    borderStyle: 'none',
  },
  posterImage: {
    objectFit: 'contain',
  },
});


type Props = {
  classes: ClassesType,
  onError?: (error: Error, data?: Object) => void,
  className?: string,
  style?: Object,
  controls?: boolean,
  muted?: boolean,
  poster?: string,
  src: string | null,
  sourceAddress?: string,
  volume?: number,
  handleNextStream ?: Function, // eslint-disable-line
  active ?: boolean,
  id?: string,
  deviceId?: string,
  addLayoutVideo: Function,
  removeLayoutVideo: Function,
  updateBandDevice: Function,
  boltClientReady: boolean,
};

type State = {
  unload: boolean
};

window.globalSilverlightErrorHandler = (message: string) => {
  console.log('Silveright Error:');
  console.error(message);
};

const createGlobalNamespaceFunction = (func:Function) => {
  const name = `function_${Math.random().toString().replace('.', '')}`;
  window[name] = func;
  return name;
};

export class VideoSilverlight extends React.Component<Props, State> {
  constructor(props:Props) {
    super(props);
    this.state = {
      unload: false,
    };
    this.errorCallback = createGlobalNamespaceFunction((sender:Object, args:Object) => {
      let appSource = '';
      if (sender !== null && sender !== 0) {
        appSource = sender.getHost().Source;
      }
      const errorType = args.ErrorType;
      const iErrorCode = args.ErrorCode;
      let errMsg = `Unhandled Error in Silverlight Application ${appSource}\n`;
      errMsg += `Code: ${iErrorCode}    \n`;
      errMsg += `Category: ${errorType}       \n`;
      errMsg += `Message: ${args.ErrorMessage}     \n`;
      if (errorType === 'ParserError') {
        errMsg += `File: ${args.xamlFile}     \n`;
        errMsg += `Line: ${args.lineNumber}     \n`;
        errMsg += `Position: ${args.charPosition}     \n`;
      } else if (errorType === 'RuntimeError') {
        if (args.lineNumber !== 0) {
          errMsg += `Line: ${args.lineNumber}     \n`;
          errMsg += `Position: ${args.charPosition}     \n`;
        }
        errMsg += `MethodName: ${args.methodName}     \n`;
      }
      const e = new Error(errMsg);
      if (typeof this.props.onError === 'function') {
        this.props.onError(e);
      }
      console.error(e);
    });
    this.loadCallback = createGlobalNamespaceFunction((sender:Object) => {
      const player = sender.getHost().Content.Player;
      this.player = player;
      this.handlePlayCallback = createGlobalNamespaceFunction(this.handlePlay);
      this.handlePauseCallback = createGlobalNamespaceFunction(this.handlePause);
      this.handleVolumeCallback = createGlobalNamespaceFunction(this.handleVolume);
      this.handleErrorCallback = createGlobalNamespaceFunction(this.handleError);
      this.handleUpdatePlayheadCallback = createGlobalNamespaceFunction(this.handleUpdatePlayhead);
      player.on('play', this.handlePlayCallback);
      player.on('pause', this.handlePauseCallback);
      player.on('volume', this.handleVolumeCallback);
      player.on('error', this.handleErrorCallback);
      player.on('updatePlayhead', this.handleUpdatePlayheadCallback);
      this.initialize();
    });
    this.playhead = 0;
    this.updateBandDeviceDebounced = debounce(props.updateBandDevice, 250);
  }

  componentDidMount() {
    if (this.props.addLayoutVideo && this.props.active && this.props.id) {
      this.props.addLayoutVideo(this.props.id);
    }
  }

  shouldComponentUpdate(nextProps:Props, nextState:State) {
    if (this.state.unload !== nextState.unload) {
      return true;
    }
    if (this.props.volume !== nextProps.volume || this.props.muted !== nextProps.muted || this.props.id !== nextProps.id) {
      const player = this.player;
      if (player) {
        const { muted, volume } = nextProps;
        if (muted === true) {
          player.setVolume(0);
        } else if (typeof volume === 'number') {
          player.setVolume(volume);
        }
      }
    }
    if (this.props.src !== nextProps.src) {
      const player = this.player;
      if (player) {
        player.setAddress(nextProps.src);
      }
    }
    if (this.props.active !== nextProps.active) {
      return true;
    }
    return false;
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.src !== prevProps.src || this.props.active !== prevProps.active) {
      this.activateBoltSilverlightAuthorization();
      this.initialize();
    }
    if ((this.props.active !== prevProps.active || this.props.id !== prevProps.id) && this.props.removeLayoutVideo && this.props.addLayoutVideo) {
      if (prevProps.active && prevProps.id) {
        this.props.removeLayoutVideo(prevProps.id);
      }
      if (this.props.active && this.props.id) {
        this.props.addLayoutVideo(this.props.id);
      }
    }
  }

  componentDidCatch(error:Error, errorInfo:Object) {
    console.log(errorInfo);
    console.error(error);
  }

  componentWillUnmount() {
    clearInterval(this.playheadCheckInterval);
    if (this.playheadCheckTimeout) {
      clearTimeout(this.playheadCheckTimeout);
    }
    const player = this.player;
    if (player) {
      try {
        player.removeListener('play', this.handlePlayCallback);
        player.removeListener('pause', this.handlePauseCallback);
        player.removeListener('volume', this.handleVolumeCallback);
        player.removeListener('error', this.handleErrorCallback);
        player.removeListener('updatePlayhead', this.handleUpdatePlayheadCallback);
      } catch (error) {
        console.log('Unable to remove handlers');
        console.error(error);
      }
    }
    if (this.props.removeLayoutVideo && this.props.active && this.props.id) {
      this.props.removeLayoutVideo(this.props.id);
    }
    if (this.handleCloseConnections) {
      this.handleCloseConnections();
    }
    if (window[this.loadCallback]) {
      delete window[this.loadCallback];
    }
    if (window[this.errorCallback]) {
      delete window[this.errorCallback];
    }
    if (window[this.handlePlayCallback]) {
      delete window[this.handlePlayCallback];
    }
    if (window[this.handlePauseCallback]) {
      delete window[this.handlePauseCallback];
    }
    if (window[this.handleVolumeCallback]) {
      delete window[this.handleVolumeCallback];
    }
    if (window[this.handleErrorCallback]) {
      delete window[this.handleErrorCallback];
    }
    if (window[this.handleUpdatePlayheadCallback]) {
      delete window[this.handleUpdatePlayheadCallback];
    }
  }

  removeHandlers() {

  }

  async activateBoltSilverlightAuthorization() {
    const { src, sourceAddress, boltClientReady } = this.props;
    if (!src || sourceAddress) {
      return;
    }
    const { hostname } = parse(src);
    if (!hostname) {
      return;
    }
    if (!boltClientReady) {
      return;
    }
    const boltUrl = boltClient.getUrl('api/1.0/silverlight');
    try {
      await superagent.post(boltUrl).send({ address: hostname });
    } catch (error) {
      console.log('Unable to activate Silverlight authorization');
      console.error(error);
    }
  }

  initialize = () => {
    const player = this.player;
    if (!player) {
      return;
    }
    if (this.handleCloseConnections) {
      this.handleCloseConnections();
    }
    const { muted, volume } = this.props;
    if (muted === true) {
      player.setVolume(0);
    } else if (typeof volume === 'number') {
      player.setVolume(volume);
    }
    this.handleCloseConnections = () => {
      try {
        player.stop();
      } catch (error) {
        console.log('Unable to stop player');
        console.error(error);
      }
    };
  }

  handleClickPause = () => {
    const player = this.player;
    if (!player) {
      return;
    }
    player.pause();
  }

  handleClickPlay = () => {
    const player = this.player;
    if (!player) {
      return;
    }
    player.play();
  }

  handleChangeVolume = (volume:number) => {
    if (volume !== this.props.volume || this.props.muted && volume > 0) {
      this.updateBandDeviceDebounced(this.props.deviceId, { volume, muted: volume === 0 });
    }
    const player = this.player;
    if (!player) {
      return;
    }
    player.setVolume(volume);
  }

  handlePause = () => {
    const controls = this.controls;
    if (!controls) {
      return;
    }
    controls.setPlaying(false);
  }

  handlePlay = () => {
    const { muted, volume } = this.props;
    const player = this.player;
    if (player) {
      if (muted) {
        player.setVolume(0);
      } else if (typeof volume === 'number') {
        player.setVolume(volume);
      }
    }
    const controls = this.controls;
    if (controls) {
      controls.setPlaying(true);
      if (muted) {
        controls.setVolume(0);
      } else if (typeof volume === 'number') {
        controls.setVolume(volume);
      }
    }
    clearInterval(this.playheadCheckInterval);
    let lastPlayhead = this.playhead;
    this.playheadCheckTimeout = null;
    this.playheadCheckInterval = setInterval(() => {
      if (this.playhead === lastPlayhead) {
        if (!this.playheadCheckTimeout) {
          this.playheadCheckTimeout = setTimeout(() => {
            this.playheadCheckTimeout = null;
            clearInterval(this.playheadCheckInterval);
            this.setState({
              unload: true,
            }, () => {
              this.setState({
                unload: false,
              });
            });
          }, 10000);
        }
      } else if (this.playheadCheckTimeout) {
        clearTimeout(this.playheadCheckTimeout);
        this.playheadCheckTimeout = null;
      }
      lastPlayhead = this.playhead;
    }, 3000);
  }

  handleVolume = (volumeString:string) => {
    const volume = parseFloat(volumeString);
    const controls = this.controls;
    if (!controls) {
      return;
    }
    controls.setVolume(volume);
  }

  handleError = (message:String) => {
    if (typeof this.props.onError === 'function') {
      this.props.onError(new Error(message));
    }
    console.error(message);
  }

  handleUpdatePlayhead = (message:string) => {
    this.playhead = parseInt(message, 10);
  }

  controls: any;
  errorCallback: string;
  loadCallback:string;
  handlePlayCallback:string;
  handlePauseCallback:string;
  handleVolumeCallback:string;
  handleErrorCallback:string;
  handleUpdatePlayheadCallback:string;
  handleCloseConnections: () => void;
  player: Object;
  updateBandDeviceDebounced: Function;
  playhead: number;
  playheadCheckInterval: IntervalID | void;
  playheadCheckTimeout: TimeoutID | null;

  render() {
    const { unload } = this.state;
    if (unload) {
      return null;
    }
    const { id, classes, src, className, style, controls, active, poster, sourceAddress } = this.props;
    const { anchor, image, container, posterImage } = classes;
    if (!active) {
      if (typeof poster !== 'string') {
        return null;
      }
      return (<div id={id} style={style} className={`${container} ${className || ''}`}>
        <img src={poster} alt={src || 'Poster'} className={posterImage} />
      </div>);
    }
    if (typeof src !== 'string') {
      return null;
    }
    return (<div id={id} style={style} className={`${container} ${className || ''}`}>
      <object data="data:application/x-silverlight-2," type="application/x-silverlight-2" width="100%" height="100%">
        <param name="source" value="/binaries/Player.xap" />
        <param name="onError" value={this.errorCallback} />
        <param name="background" value="black" />
        <param name="minRuntimeVersion" value="5.0.61118.0" />
        <param name="onLoad" value={this.loadCallback} />
        <param name="autoUpgrade" value="true" />
        <param name="windowless" value="true" />
        <param name="initParams" value={`volume=0,autoplay=true,address=${src.slice(6)}${sourceAddress ? `,sourceAddress=${sourceAddress}` : ''}`} />
        <a href="https://go.microsoft.com/fwlink/?LinkID=149156&v=5.0.61118.0" className={anchor}>
          <img src="https://go.microsoft.com/fwlink/?LinkId=161376" alt="Get Microsoft Silverlight" className={image} />
        </a>
      </object>
      {controls ? <VideoSilverlightControls
        ref={(e) => { this.controls = e; }}
        onClickPlay={this.handleClickPlay}
        onClickPause={this.handleClickPause}
        onChangeVolume={this.handleChangeVolume}
      /> : null}
    </div>);
  }
}

const withConnect = connect((state: StateType) => ({
  deviceId: deviceIdSelector(state),
  boltClientReady: boltClientReadySelector(state),
}), (dispatch: Function) => bindActionCreators({ updateBandDevice, addLayoutVideo, removeLayoutVideo }, dispatch));

export default compose(
  withConnect,
  withStyles(styles),
)(VideoSilverlight);
