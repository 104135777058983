// @flow

/**
*
* LayoutPane
*
*/

import * as React from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme: Object) => ({ // eslint-disable-line no-unused-vars
  row: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    boxSizing: 'border-box',
  },
  column: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
  },
});

type Props = {
  classes: ClassesType,
  children?: React.Node,
  flex: number,
  type: string
};

const flexStyles = Array.from({ length: 101 }, (x, index) => ({ flexGrow: 0, flexShrink: 0, flexBasis: `${index}%` }));

function LayoutPane(props: Props) {
  const { type, classes, children, flex } = props;
  if (type === 'column' || type === 'row') {
    const sizeStyle = {
      height: type === 'row' ? `${flex}%` : '100%',
    };
    return (
      <div
        style={{ ...sizeStyle, ...flexStyles[flex] }}
        className={type === 'row' ? classes.row : classes.column}
        role='presentation'
      >
        {children}
      </div>
    );
  }
}


export default compose(
  withStyles(styles, { withTheme: true }),
)(LayoutPane);

