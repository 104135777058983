// @flow

export const isBlendUrl = (src: ?string) => {
  if (!src || typeof src !== 'string') {
    return false;
  }
  if (src.includes('.m3u8')) {
    return false;
  }
  if (src.endsWith('.sdp')) {
    return true;
  }
  if (!src.includes('http://') || !src.includes('https://')) {
    return true;
  }
  return false;
};


const silverlightAvailable = navigator && navigator.plugins && navigator.plugins['Silverlight Plug-In'];

export function isSilverlightUrl(src: string | null) {
  if (!silverlightAvailable || !src) {
    return false;
  }
  if (src.includes('rtp://')) {
    return true;
  }
  return false;
}
