// @flow

import type { AdapterType } from 'shared-redux/src/auth/types';
import AbstractAdapter from 'shared-redux/src/auth/adapter';
import pick from 'lodash/pick';
import { agent } from '../lib/api-agent';

(x: AuthAdapter) => (x: AdapterType); // eslint-disable-line no-unused-expressions
export default class AuthAdapter extends AbstractAdapter {
  createAuthClient = async (teamName: string, link: string): Promise<*> => {
    const response = await agent
      .post('/api/v1.0/client/band')
      .send({ teamName, link });
    return response;
  }

  validateToken = async (data: Object): Promise<*> => {
    const authData = pick(data, ['id_token', 'access_token', 'expires_at', 'expires_in']);
    authData.expires_at = Math.round(Date.now() / 1000 + parseInt(authData.expires_in, 10));
    const response = await agent
      .post('/api/v1.0/auth/band')
      .send({ authData, teamId: data.teamId })
      .catch((err) => {
        throw err;
      });
    return response.status;
  }
}
