/* eslint-disable import/first */

// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import createHistory from 'history/createBrowserHistory';
import safeReload from './lib/safe-reload';

// Import root app
import App from 'containers/App';

// Import Language Provider
import LanguageProvider from 'containers/LanguageProvider';

import configureStore from './configureStore';

// Import i18n messages
import { translationMessages } from './i18n';

const bandMessage = `
     ______      ________      ________       ________
    /\\  __ \\    /\\   __  \\    /\\   ___  \\    /\\   ___ \\
    \\ \\ \\_\\ \\   \\ \\  \\_\\  \\   \\ \\  \\\\ \\  \\   \\ \\  \\  \\ \\
     \\ \\  __<    \\ \\   __  \\   \\ \\  \\\\ \\  \\   \\ \\  \\  \\ \\
      \\ \\ \\_\\ \\   \\ \\  \\ \\  \\   \\ \\  \\\\ \\  \\   \\ \\  \\_ \\ \\
       \\ \\_____\\   \\ \\__\\ \\__\\   \\ \\__\\\\ \\__\\   \\ \\_______\\
        \\/_____/    \\/__/\\/__/    \\/__/ \\/__/    \\/_______/\n\n`;

console.log(bandMessage); // eslint-disable-line no-console

console.log('Listening for uncaught errors.');

const errorEventHandler = (event) => {
  const errorReloadAttemptString = window.localStorage.getItem('errorReloadAttempt');
  let errorReloadAttempt = errorReloadAttemptString ? parseInt(errorReloadAttemptString, 10) : 1;
  if (errorReloadAttempt > 20) {
    errorReloadAttempt = 20;
  }
  if (event.error) {
    console.log(`Uncaught error, will start safe reload after ${errorReloadAttempt * errorReloadAttempt} seconds.`);
    console.error(event.error);
    window.localStorage.setItem('errorReloadAttempt', (errorReloadAttempt + 1).toString());
    setTimeout(safeReload, errorReloadAttempt * errorReloadAttempt * 1000);
  } else if (event.message) {
    console.error(event.message);
  }
  event.preventDefault();
};

window.addEventListener('unhandledrejection', errorEventHandler);

window.addEventListener('error', errorEventHandler);

setTimeout(() => {
  window.localStorage.removeItem('errorReloadAttempt');
}, 60 * 60 * 1000);

window.requestIdleCallback = window.requestIdleCallback || function (cb) {
  const start = Date.now();
  return setTimeout(() => {
    cb({
      didTimeout: false,
      timeRemaining() {
        return Math.max(0, 50 - (Date.now() - start));
      },
    });
  }, 1);
};

window.cancelIdleCallback = window.cancelIdleCallback || function (id) {
  clearTimeout(id);
};

// Create redux store with history
const initialState = {};
const history = createHistory();
const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('app');

const requestStoragePersist = async () => {
  if (!navigator.storage || !navigator.storage.persisted || !navigator.storage.persist) {
    console.log('Persistent storage API is not available.');
    return;
  }
  try {
    if (await navigator.storage.persisted()) {
      console.log('Persistent storage is active.');
      return;
    }
  } catch (error) {
    console.error('Unable to check persistent storage status.');
  }
  try {
    if (await navigator.storage.persist()) {
      console.log('Persistent storage request granted.');
    } else {
      console.log('Persistent storage request denied.');
    }
  } catch (error) {
    console.error('Unable to request persistent storage.');
  }
};

setTimeout(requestStoragePersist, 5000);

window.copyLocalStorage = function transferLocalStorageUtil() {
  try {
    const textArea = document.createElement('textarea');
    textArea.value = `
      tempLocalStorageContents = ${JSON.stringify(window.localStorage)};
      (function() {
        Object.keys(tempLocalStorageContents).forEach((key) => window.localStorage.setItem(key, tempLocalStorageContents[key]))
      })()`;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
  } catch (error) {
    console.log('Error copying localstorage: ', error);
  }
};

const render = (messages) => {
  ReactDOM.render(
    <Provider store={store}>
      <LanguageProvider messages={messages}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </LanguageProvider>
    </Provider>,
    MOUNT_NODE,
  );
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n', 'containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translationMessages);
  });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  (new Promise((resolve) => {
    resolve(import('intl'));
  }))
    .then(() => Promise.all([
      import('intl/locale-data/jsonp/en'),
    ]))
    .then(() => render(translationMessages))
    .catch((err) => {
      throw err;
    });
} else {
  render(translationMessages);
}

