// @flow

import * as React from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import ReactQuill from 'react-quill';
import withNode from '@bunchtogether/boost-client/dist/components/withNode';
import { Map } from 'immutable';
import type { Map as MapType } from 'immutable';
import LayoutComponentBase from 'components/LayoutComponentBase';
import { getFlexAlignment, getPadding } from '../../lib/alignment';


const styles = (theme: Object) => ({ // eslint-disable-line no-unused-vars
  deltaContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    fontFamily: theme.typography.fontFamily,
    wordBreak: 'break-word',
  },
});

type Props = {
  classes: ClassesType,
  className?: string,
  node?: MapType<string, any>,
  layoutWidth: number,
  backgroundImage: Object,
  backgroundImageSize: string,
  backgroundImageAlignment: number,
  padding: Object,
  alignment: number,
  fgColor: string,
  bgColor: string,
};

export class LayoutComponentText extends React.PureComponent<Props, void> { // eslint-disable-line react/prefer-stateless-function
  render() {
    const { classes, className, node, layoutWidth, backgroundImage, backgroundImageSize, backgroundImageAlignment, padding, alignment, fgColor, bgColor } = this.props;
    if (!node || !Map.isMap(node)) {
      return null;
    }
    const delta = node.getIn(['metadata', 'delta']);
    const calcScale = layoutWidth / 1280;
    const style = {
      fontSize: `${calcScale}rem`,
      ...getFlexAlignment(alignment),
      ...getPadding(padding),
    };
    return (
      <LayoutComponentBase
        className={className}
        color={fgColor || 'inherit'}
        backgroundColor={bgColor || 'inherit'}
        backgroundImage={backgroundImage}
        backgroundImageSize={backgroundImageSize}
        backgroundImageAlignment={backgroundImageAlignment}
      >
        <div style={style} className={classes.deltaContainer}>
          {delta ? <ReactQuill theme={null} readOnly value={JSON.parse(delta)} /> : null}
        </div>
      </LayoutComponentBase>
    );
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withNode(),
)(LayoutComponentText);
