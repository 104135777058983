// @flow

import * as React from 'react';
import { Map as ImmutableMap } from 'immutable';
import withNode from '@bunchtogether/boost-client/dist/components/withNode';
import LayoutComponentText from 'components/LayoutComponentText';
import LayoutComponentImage from 'components/LayoutComponentImage';
import LayoutComponentIcon from 'components/LayoutComponentIcon';
import LayoutComponentUrl from 'components/LayoutComponentUrl';
import LayoutComponentHtml from 'components/LayoutComponentHtml';
import LayoutComponentVideoStream from 'components/LayoutComponentVideoStream';
import LayoutComponentQumuVccProgram from 'components/LayoutComponentQumuVccProgram';
import LayoutComponentVideo from 'components/LayoutComponentVideo';
import LayoutComponentSpacer from 'components/LayoutComponentSpacer';
import LayoutComponentPdf from 'components/LayoutComponentPdf';
import LayoutComponentFlag from 'components/LayoutComponentFlag';
import LayoutComponentClock from 'components/LayoutComponentClock';

const styles = (theme:Object) => ({ // eslint-disable-line no-unused-vars
});

type Props = {
  id: string,
  className?: string,
  node?: ImmutableMap<string, *>,
  layoutWidth: number,
  layoutHeight: number,
  active: boolean,
  backgroundImage: Object,
  backgroundImageSize: string,
  backgroundImageAlignment: number,
  padding: Object,
  alignment: number,
  size: string,
  fgColor: string,
  bgColor: string,
};

function LayoutComponentCarouselAsset(props: Props) {
  const {
    id,
    node,
    className,
    active,
    layoutWidth,
    layoutHeight,
    backgroundImage,
    backgroundImageSize,
    backgroundImageAlignment,
    padding,
    alignment,
    size,
    fgColor,
    bgColor,
  } = props;
  if (!node || !ImmutableMap.isMap(node)) {
    return null;
  }
  const type = node.get('type');
  if (type === 'text') {
    return <LayoutComponentText id={id} className={className} layoutWidth={layoutWidth} layoutHeight={layoutHeight} padding={padding} alignment={alignment} size={size} fgColor={fgColor} bgColor={bgColor} thumbnail={!active} backgroundImage={backgroundImage} backgroundImageSize={backgroundImageSize} backgroundImageAlignment={backgroundImageAlignment} />;
  } else if (type === 'image') {
    return <LayoutComponentImage id={id} className={className} layoutWidth={layoutWidth} layoutHeight={layoutHeight} padding={padding} alignment={alignment} size={size} fgColor={fgColor} bgColor={bgColor} backgroundImage={backgroundImage} backgroundImageSize={backgroundImageSize} backgroundImageAlignment={backgroundImageAlignment} />;
  } else if (type === 'icon') {
    return <LayoutComponentIcon id={id} className={className} layoutWidth={layoutWidth} layoutHeight={layoutHeight} padding={padding} alignment={alignment} size={size} fgColor={fgColor} bgColor={bgColor} backgroundImage={backgroundImage} backgroundImageSize={backgroundImageSize} backgroundImageAlignment={backgroundImageAlignment} />;
  } else if (type === 'qumu-vcc-program') {
    return <LayoutComponentQumuVccProgram id={id} className={className} active={active} layoutWidth={layoutWidth} layoutHeight={layoutHeight} padding={padding} alignment={alignment} size={size} fgColor={fgColor} bgColor={bgColor} />;
  } else if (type === 'video') {
    return <LayoutComponentVideo id={id} className={className} active={active} layoutWidth={layoutWidth} layoutHeight={layoutHeight} padding={padding} alignment={alignment} size={size} fgColor={fgColor} bgColor={bgColor} backgroundImage={backgroundImage} backgroundImageSize={backgroundImageSize} backgroundImageAlignment={backgroundImageAlignment} />;
  } else if (type === 'url') {
    return <LayoutComponentUrl id={id} className={className} layoutWidth={layoutWidth} layoutHeight={layoutHeight} padding={padding} alignment={alignment} size={size} fgColor={fgColor} bgColor={bgColor} />;
  } else if (type === 'html') {
    return <LayoutComponentHtml id={id} className={className} layoutWidth={layoutWidth} layoutHeight={layoutHeight} padding={padding} alignment={alignment} size={size} fgColor={fgColor} bgColor={bgColor} />;
  } else if (type === 'video-stream') {
    return <LayoutComponentVideoStream id={id} className={className} active={active} layoutWidth={layoutWidth} layoutHeight={layoutHeight} padding={padding} alignment={alignment} size={size} fgColor={fgColor} bgColor={bgColor} />;
  } else if (type === 'spacer') {
    return <LayoutComponentSpacer id={id} className={className} layoutWidth={layoutWidth} layoutHeight={layoutHeight} padding={padding} alignment={alignment} size={size} fgColor={fgColor} bgColor={bgColor} backgroundImage={backgroundImage} backgroundImageSize={backgroundImageSize} backgroundImageAlignment={backgroundImageAlignment} />;
  } else if (type === 'pdf') {
    return <LayoutComponentPdf id={id} className={className} active={active} layoutWidth={layoutWidth} layoutHeight={layoutHeight} padding={padding} alignment={alignment} size={size} fgColor={fgColor} bgColor={bgColor} backgroundImage={backgroundImage} backgroundImageSize={backgroundImageSize} backgroundImageAlignment={backgroundImageAlignment} />;
  } else if (type === 'flag') {
    return <LayoutComponentFlag id={id} className={className} active={active} layoutWidth={layoutWidth} layoutHeight={layoutHeight} padding={padding} alignment={alignment} size={size} fgColor={fgColor} bgColor={bgColor} backgroundImage={backgroundImage} backgroundImageSize={backgroundImageSize} backgroundImageAlignment={backgroundImageAlignment} />;
  } else if (type === 'clock') {
    return <LayoutComponentClock id={id} className={className} active={active} layoutWidth={layoutWidth} layoutHeight={layoutHeight} padding={padding} alignment={alignment} size={size} fgColor={fgColor} bgColor={bgColor} backgroundImage={backgroundImage} backgroundImageSize={backgroundImageSize} backgroundImageAlignment={backgroundImageAlignment} />;
  }
  return null;
}

export default withNode()(LayoutComponentCarouselAsset);

