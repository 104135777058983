// @flow

import DetectRTC from 'detectrtc';

const promise: Promise<boolean> = new Promise((resolve) => {
  const timeout = setTimeout(() => {
    resolve(false);
  }, 5000);
  DetectRTC.load(() => {
    clearTimeout(timeout);
    const { isWebSocketsSupported, isWebRTCSupported } = DetectRTC;
    resolve(isWebSocketsSupported && isWebRTCSupported);
  });
});

export default promise;

