// @flow

import UAParser from 'ua-parser-js';
import queryString from 'query-string';
import history from './history';

const query = queryString.parse(history.location.hash.slice(1));

if (query.m) {
  console.log(`Found MAC address: ${query.m}`);
}

const parser = new UAParser();
let userAgentParameters;

export const getUserAgentParameters = ():Object => {
  if (userAgentParameters) {
    return userAgentParameters;
  }
  const result:Object = Object.assign({}, parser.getResult());
  const response = {};
  response.isKiosk = /Band-Kiosk/.test(result.ua);
  response.isBrightSign = /BrightSign/.test(result.ua);
  delete result.ua;
  Object.keys(result).forEach((category) => {
    if (result[category] instanceof Object) {
      Object.keys(result[category]).forEach((key) => {
        if (result[category][key] && key !== 'version') {
          response[`${category}${key.slice(0, 1).toUpperCase()}${key.slice(1)}`] = result[category][key];
        }
      });
    }
  });
  response.deviceType = response.isKiosk ? 'kiosk' : response.deviceType || 'default';
  response.macAddress = query.m;
  userAgentParameters = response;
  return response;
};

