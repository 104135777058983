// @flow

import type { ActionType } from '../types';
import type { SearchOptionsType } from './types';
import * as constants from './constants';

export function queryPrograms(queryGroupId: string, options: SearchOptionsType, metadata: Object): ActionType {
  const queryId = JSON.stringify(options);
  return {
    type: constants.QUERY_PROGRAMS,
    value: {
      queryGroupId,
      queryId,
      options,
      metadata,
    },
  };
}

export function queryAdminPrograms(queryGroupId: string, options: SearchOptionsType, metadata: Object): ActionType {
  const queryId = JSON.stringify(options);
  return {
    type: constants.QUERY_ADMIN_PROGRAMS,
    value: {
      queryGroupId,
      queryId,
      options,
      metadata,
    },
  };
}

export function queryProgramsWithId(queryGroupId: string, queryId: string, options: SearchOptionsType, metadata: Object): ActionType {
  return {
    type: constants.QUERY_PROGRAMS,
    value: {
      queryGroupId,
      queryId,
      options,
      metadata,
    },
  };
}

export function getProgram(programId: string, metadata: Object): ActionType {
  return {
    type: constants.GET_PROGRAM,
    value: {
      programId,
      metadata,
    },
  };
}

export function getProgramContentLocations(programId: string, metadata: Object): ActionType {
  return {
    type: constants.GET_PROGRAM_CONTENT_LOCATIONS,
    value: {
      programId,
      metadata,
    },
  };
}
