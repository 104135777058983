// @flow

/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import * as React from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { Switch, Route, withRouter } from 'react-router-dom';
import { Map } from 'immutable';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import ReactQuill from 'react-quill';
import Landing from 'containers/Landing/Loadable';
import Channel from 'containers/Channel/Loadable';
import NotFoundPage from 'containers/NotFoundPage/Loadable';
import Notifications from 'components/Notifications';
import DialogUserLogout from 'components/DialogUserLogout';
import DialogSwitchTeam from 'components/DialogSwitchTeam';
import DialogLoadMeeting from 'components/DialogLoadMeeting';
import ChannelSelector from 'components/ChannelSelector';
import Menu from 'components/Menu';
import { themeColorSelector, deviceIdSelector, mouseActiveSelector } from './selectors';
import { setMouseActive } from './actions';
import { setTheme, getTheme, DEFAULT_SECONDARY, DEFAULT_PRIMARY } from './theme';
import '../../lib/quill.css';
import './base.css';

const Quill = ReactQuill.Quill;

const Size = Quill.import('formats/size');
Size.whitelist = [
  '8',
  '9',
  '10',
  '11',
  '12',
  '14',
  '16',
  '18',
  '24',
  '30',
  '36',
  '48',
  '60',
  '72',
  '96',
  '120',
  '144',
];
Quill.register(Size, true);

const Font = Quill.import('formats/font');
Font.whitelist = ['sans-serif', 'serif', 'monospace', 'condensed'];
Quill.register(Font, true);

const { Attributor } = Quill.import('parchment');
const FontWeight = new Attributor.Class('font-weight', 'ql-font-weight', {
  scope: 5,
  whitelist: [
    '900',
    '800',
    '700',
    '600',
    '500',
    '400',
    '300',
    '200',
    '100',
  ],
});
Quill.register(FontWeight, true);

const LineHeight = new Attributor.Class('line-height', 'ql-line-height', {
  scope: 5,
  whitelist: [
    '20',
    '18',
    '14',
    '12',
    '10',
    '8',
    '6',
    '4',
    '2',
    '0',
  ],
});
Quill.register(LineHeight, true);

type Props = {
  deviceId: string,
  themeColor: string,
  setMouseActive: Function,
  mouseActive: boolean,
  themeColor: Map,
};

type State = {
  theme: Object,
};

export class App extends React.PureComponent<Props, State> { // eslint-disable-line
  state = {
    theme: getTheme(),
  }

  componentDidMount() {
    window.addEventListener('touchstart', this.handleMouseMove);
    window.addEventListener('mousemove', this.handleMouseMove);
    if (this.props.themeColor) {
      this.handleSetTheme(this.props);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.themeColor !== prevProps.themeColor && this.props.themeColor) {
      this.handleSetTheme(this.props);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('touchstart', this.handleMouseMove);
    window.removeEventListener('mousemove', this.handleMouseMove);
  }

  handleMouseMove = () => {
    if (!this.props.mouseActive) {
      this.props.setMouseActive(true);
    }
    clearTimeout(this.mouseActiveTimeout);
    this.mouseActiveTimeout = setTimeout(this.handleMouseStop, 1000);
  }

  handleMouseStop = () => {
    this.props.setMouseActive(false);
  }

  handleSetTheme = (props: Props) => {
    const { themeColor } = props;
    if (!themeColor || !Map.isMap(themeColor)) {
      return;
    }
    const color = themeColor.get('color');
    const secondaryColor = themeColor.get('secondaryColor');
    setTheme(color || DEFAULT_PRIMARY, secondaryColor || DEFAULT_SECONDARY);
    this.setState({ theme: getTheme() });
  }

  mouseActiveTimeout: any;

  render() {
    const { deviceId } = this.props;
    return (
      <MuiThemeProvider theme={this.state.theme}>
        <CssBaseline />
        <Notifications />
        <Menu />
        <ChannelSelector id={deviceId} />
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route exact path="/:teamName/:channelName*" component={Channel} />
          <Route component={NotFoundPage} />
        </Switch>
        <DialogUserLogout />
        <DialogSwitchTeam />
        <DialogLoadMeeting />
      </MuiThemeProvider>
    );
  }
}

const withConnect = connect((state: StateType) => ({
  deviceId: deviceIdSelector(state),
  themeColor: themeColorSelector(state),
  mouseActive: mouseActiveSelector(state),
}), (dispatch: Function) => bindActionCreators({ setMouseActive }, dispatch));

export default compose(
  withRouter,
  withConnect,
)(App);
