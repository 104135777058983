// @flow

import type { AdapterType } from './types';

export class GraphAdapterError extends Error {
  status: number;

  constructor(message: string, status: number) {
    super(message);
    this.status = status;
  }
}

(x: AbstractAdapter) => (x: AdapterType); // eslint-disable-line no-unused-expressions
export default class AbstractAdapter {
  shouldFail: boolean;
  accessToken: string;

  constructor() {
    this.shouldFail = false;
  }

  setAccessToken(accessToken: string): void {
    this.accessToken = accessToken;
  }

  upsertNode(nodeType: string, metadata: Object, id: string): Promise<*> { // eslint-disable-line no-unused-vars
    throw new Error('Method upsertNode is not implemented.');
  }

  updateNode(nodeType: string, metadata: Object, id: string): Promise<*> { // eslint-disable-line no-unused-vars
    throw new Error('Method updateNode is not implemented.');
  }

  deleteNode(nodeType: string, id: string): Promise<*> { // eslint-disable-line no-unused-vars
    throw new Error('Method deleteNode is not implemented.');
  }

  getNode(nodeType: string, id: string): Promise<*> { // eslint-disable-line no-unused-vars
    throw new Error('Method getNode is not implemented.');
  }

  getDescendents(nodeType: string, id: string, options: ?Object): Promise<*> { // eslint-disable-line no-unused-vars
    throw new Error('Method getDescendents is not implemented.');
  }

  getAncestors(nodeType: string, id: string, options: ?Object): Promise<*> { // eslint-disable-line no-unused-vars
    throw new Error('Method getAncestors is not implemented.');
  }

  upsertEdge(parentId: string, childId: string, metadata: Object): Promise<*> { // eslint-disable-line no-unused-vars
    throw new Error('Method upsertEdge is not implemented.');
  }

  getEdge(parentId: string, childId: string): Promise<*> { // eslint-disable-line no-unused-vars
    throw new Error('Method getEdge is not implemented.');
  }

  updateEdge(parentId: string, childId: string, metadata: Object): Promise<*> { // eslint-disable-line no-unused-vars
    throw new Error('Method updateEdge is not implemented.');
  }

  deleteEdge(parentId: string, childId: string): Promise<*> { // eslint-disable-line no-unused-vars
    throw new Error('Method deleteEdge is not implemented.');
  }

  addPermission(parentId: string, childId: string, permissionType: string): Promise<*> { // eslint-disable-line no-unused-vars
    throw new Error('Method addPermission is not implemented.');
  }

  removePermission(sourceId: string, targetId: string, permissionType: string): Promise<*> { // eslint-disable-line no-unused-vars
    throw new Error('Method removePermission is not implemented.');
  }

  deleteTag(target: string, name: string): Promise<*> { // eslint-disable-line no-unused-vars
    throw new Error('Method deleteTag is not implemented.');
  }

  checkUserGroupName(name: string, teamId: string): Promise<*> { // eslint-disable-line no-unused-vars
    throw new Error('Method checkUserGroupName is not implemented.');
  }
}
