// @flow

import { createSelector } from 'reselect';

const getIds = (result: MapType<string, *>): ListType<string> => {
  const ids = result.get('ids');
  if (!ids) {
    throw new Error('Unable to get ids in result.');
  }
  return ids;
};

// ------- Programs ------- //

export const programsResultSelector = (state: StateType, id: string):MapType<string, *> => {
  const programsResult = state.getIn(['vcc', 'programsResultValues', id]);
  if (!programsResult) {
    throw new Error(`Unable to get program result with ID ${id}`);
  }
  return programsResult;
};

const queryProgramsResult = (state: StateType, queryGroupId: string, queryId: string):MapType<string, *> => {
  const queryPrograms = state.getIn(['vcc', 'queryProgramsResults', queryGroupId, queryId]);
  if (!queryPrograms) {
    throw new Error(`Unable to get programs with Query Group ID "${queryGroupId}" and Query ID "${queryId}"`);
  }
  return queryPrograms;
};

export const queryProgramsResultSelector = createSelector<StateType, *, *, *>(
  queryProgramsResult,
  getIds,
);

export const latestQueryProgramsResultSelector = (state: StateType, queryGroupId: string): ListType<string> => {
  const latestQueryResultId = state.getIn(['vcc', 'latestQueryProgramsResultIds', queryGroupId]);
  return queryProgramsResultSelector(state, queryGroupId, latestQueryResultId);
};

export const latestQueryProgramsHasMoreSelector = (state: StateType, queryGroupId: string): bool => {
  const latestQueryId = state.getIn(['vcc', 'latestQueryProgramsIds', queryGroupId]);
  return programHasMoreSelector(state, queryGroupId, latestQueryId);
};

export const programResultNameSelector = createSelector<StateType, *, *, *>(
  programsResultSelector,
  (program: MapType<string, *>): ?string => program.get('name'),
);

export const programHasMoreSelector = createSelector<StateType, *, *, *>(
  queryProgramsResult,
  (result: MapType<string, *>): bool => {
    const loading = result.get('loading');
    const hasMore = result.get('hasMore');
    return !!(!loading && hasMore);
  },
);

export const programChannelIdSelector = createSelector<StateType, *, *, *>(
  programsResultSelector,
  (program: MapType<any, *>): ?string => program.getIn(['channel', 'identifier']),
);

export const programLiveSchedulesSelector = createSelector<StateType, *, *, *>(
  programsResultSelector,
  (program: MapType<any, *>): ?string => {
    const isLive = program.get('live');
    if (!isLive) {
      return '';
    }
    const liveMediaStream = program.getIn(['liveSchedules', 0, 'liveMediaStreams', 0]);
    if (liveMediaStream) {
      return liveMediaStream.get('mediaLink');
    }
    return '';
  },
);

export const programVodMediaStreamsSelector = createSelector<StateType, *, *, *>(
  programsResultSelector,
  (program: MapType<any, *>): ?string => {
    const isLive = program.get('live');
    if (isLive) {
      return '';
    }
    const vodMediaStream = program.getIn(['vodMediaStreams', 0]);
    if (vodMediaStream) {
      return vodMediaStream.get('mediaLink');
    }
    return '';
  },
);

export const programContentLocationsSelector = (state: StateType, id: string): MapType<string, *> => state.getIn(['vcc', 'contentLocations', id]);

export const programDurationSelector = createSelector<StateType, *, *, *>(
  programsResultSelector,
  (program: MapType<any, *>): ?number => program.get('duration'),
);
