// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import LinearProgress from '@material-ui/core/LinearProgress';
import CloseIcon from '@material-ui/icons/Close';
import { closeDialog } from 'containers/App/actions';
import { dialogSelector } from 'containers/App/selectors';

const styles = (theme: Object) => ({
  paper: {
    [theme.breakpoints.up('sm')]: {
      minWidth: 300,
    },
  },
  button: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  title: {
    width: '100%',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingRight: 0,
    paddingLeft: 0,
    textAlign: 'center',
  },
  content: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

type Props = {
  classes: ClassesType,
  open: boolean,
  closeDialog: Function,
  fullScreen?: boolean,
  dialogName?: string,
};

export class DialogLoadMeeting extends React.PureComponent<Props, void> { // eslint-disable-line react/prefer-stateless-function
  handleCancel = () => {
    this.props.closeDialog(this.props.dialogName || 'loadMeeting');
  }

  render() {
    const { classes, fullScreen, open } = this.props;
    if (!open) {
      return null;
    }
    return (
      <Dialog
        classes={{ paper: classes.paper }}
        fullScreen={fullScreen}
        open={open}
        onClose={this.handleCancel}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" className={classes.title}>Initializing Meeting</DialogTitle>
        <IconButton onClick={this.handleCancel} color="primary" className={classes.button}>
          <CloseIcon />
        </IconButton>
        <LinearProgress />
      </Dialog>
    );
  }
}

const withConnect = connect((state: StateType, props: Props) => {
  const open = dialogSelector(state, props.dialogName || 'loadMeeting');
  if (!open) {
    return { open };
  }
  return {
    open,
  };
}, (dispatch: Function) => bindActionCreators({ closeDialog }, dispatch));

export default compose(
  withStyles(styles, { withTheme: true }),
  withMobileDialog(),
  withConnect,
)(DialogLoadMeeting);

