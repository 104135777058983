// @flow

import * as React from 'react';
import { Map, List } from 'immutable';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import withNode from '@bunchtogether/boost-client/dist/components/withNode';
import LayoutComponentBase from 'components/LayoutComponentBase';
import KeiserRoomFilterSet from 'components/KeiserRoomFilterSet';
import KeiserAggregatePower from 'components/KeiserAggregatePower';
import { getPadding, getFlexAlignment } from '../../lib/alignment';


const styles = (theme: Object) => ({ // eslint-disable-line
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    overflow: 'auto',
  },
  aggregatePowerContainer: {
    width: '18%',
    height: '100%',
    boxSizing: 'border-box',
    padding: 4,
    [theme.breakpoints.down('md')]: {
      width: 0,
    },
  },
  setContainer: {
    [theme.breakpoints.up('lg')]: {
      width: '33.333%',
      height: '50%',
      boxSizing: 'border-box',
      padding: 4,
    },
    [theme.breakpoints.down('md')]: {
      width: '50%',
      height: '50%',
      boxSizing: 'border-box',
      padding: 4,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '50%',
      minHeight: 380,
    },
  },
});

type Props = {
  classes: ClassesType,
  className?: string,
  node?: Map,
  backgroundImage: Object,
  backgroundImageSize: string,
  backgroundImageAlignment: number,
  padding: Object,
  alignment: number,
  fgColor: string,
  bgColor: string,
  layoutWidth: number,
  id: string,
};

type State = {};

class LayoutComponentKeiserRoom extends React.PureComponent<Props, State> {
  render() {
    const { classes, className, node, layoutWidth, backgroundImage, backgroundImageSize, backgroundImageAlignment, alignment, padding, fgColor, bgColor } = this.props;
    if (!node || !Map.isMap(node)) {
      return null;
    }
    const scale = layoutWidth / 1280;
    const style = {
      fontSize: `${scale}em`,
      ...getFlexAlignment(alignment),
      ...getPadding(padding),
    };
    const latest6Keys = node && node.getIn(['metadata', 'latest6Keys']) ? node.getIn(['metadata', 'latest6Keys']) : List();
    return (
      <LayoutComponentBase
        className={className}
        color={fgColor || 'inherit'}
        backgroundColor={bgColor || 'inherit'}
        backgroundImage={backgroundImage}
        backgroundImageSize={backgroundImageSize}
        backgroundImageAlignment={backgroundImageAlignment}
      >
        <div className={classes.root}>
          <div className={classes.aggregatePowerContainer}>
            <KeiserAggregatePower id={this.props.id} />
          </div>
          <div className={classes.container} style={style}>
            {latest6Keys.map((serialNumber) => (
              <div className={classes.setContainer} key={serialNumber}>
                <KeiserRoomFilterSet
                  id={this.props.id}
                  filter={`${JSON.stringify({ display: { $is: true }, startedAt: { $not: null }, serialNumber })}`}
                />
              </div>
            ))}
          </div>
        </div>
      </LayoutComponentBase>
    );
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withNode(),
)(LayoutComponentKeiserRoom);

