// @flow

import superagent from 'superagent';
import type { AdapterType, QueryProgramsResultType, ProgramType } from 'shared-redux/src/vcc/types';
import AbstractAdapter from 'shared-redux/src/vcc/adapter';

(x: VccAdapter) => (x: AdapterType); // eslint-disable-line no-unused-expressions
export default class VccAdapter extends AbstractAdapter {
  queryPrograms = (queryGroupId: string, queryId: string, options: Object): Promise<QueryProgramsResultType> => { // eslint-disable-line
    if (!this.viewerPortalUrl) {
      throw new Error('Unable to set viewer portal url');
    }
    const created = new Date();
    return superagent
      .get(`${this.viewerPortalUrl}/programs`)
      .set('x-qumu-host', this.xQumuHost)
      .query(options)
      .then((response) => ({
        queryGroupId,
        queryId,
        result: response.body,
        hasMore: false,
        created,
      }))
      .catch((err) => new Error(`Query programs error: ${err}`));
  }

  getProgram = (programId: string): Promise<ProgramType> => { // eslint-disable-line
    if (!this.viewerPortalUrl) {
      throw new Error('Unable to set viewer portal url');
    }
    const created = new Date();
    return superagent
      .get(`${this.viewerPortalUrl}/programs/${programId}`)
      .set('x-qumu-host', this.xQumuHost)
      .then((response) => ({
        result: response.body,
        hasMore: false,
        created,
        programId,
      }))
      .catch((err) => new Error(`Query program by id error: ${err}`));
  }

  getMediaLink = (link: string): Promise<*> => superagent
    .get(`${this.origin}/${link}`)
    .set('x-qumu-host', this.xQumuHost)
    .set('Accept', 'application/json')
    .then((response) => response.body)
    .catch((err) => new Error(`Get media link error: ${err}`));
}
