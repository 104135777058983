// @flow

import React from 'react';
import countryFlags from './index';

const CountryFlagByCode = (countryCode: string, props: Object, size?: string) => {
  if (!size || size === 'cover' || size === 'contain') {
    const imageStyle = {
      ...props.style,
      backgroundImage: `url(${countryFlags[countryCode.toLowerCase()]})`,
    };
    return (<div style={imageStyle} />);
  }
  if (countryCode) {
    return (<img src={countryFlags[countryCode.toLowerCase()]} alt="country_flag" {...props} />);
  }
  return null;
};

export default CountryFlagByCode;
