// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { closeDialog, logoutUser } from 'containers/App/actions';
import { dialogSelector } from 'containers/App/selectors';

const styles = (theme: Object) => ({
  paper: {
    [theme.breakpoints.up('sm')]: {
      minWidth: 500,
    },
  },
});

type Props = {
  classes: ClassesType,
  closeDialog: Function,
  logoutUser: Function,
  open: boolean,
  fullScreen: boolean,
  dialogName?: string,
};

type State = {};

export class DialogUserLogout extends React.PureComponent<Props, State> { // eslint-disable-line react/prefer-stateless-function
  handleClose = () => {
    this.props.closeDialog(this.props.dialogName || 'userLogout');
  };

  handleLogout = () => {
    this.props.logoutUser();
    this.handleClose();
  }

  render() {
    const { classes, fullScreen, open } = this.props;
    return (
      <Dialog
        classes={{
          paper: classes.paper,
        }}
        fullScreen={fullScreen}
        open={open}
        onClose={this.handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Log out</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You will be logged out but this display will stay on as an anonymous user.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={this.handleLogout}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const withConnect = connect((state: StateType, props: Props) => ({
  open: dialogSelector(state, props.dialogName || 'userLogout'),
}), (dispatch: Function) => bindActionCreators({ closeDialog, logoutUser }, dispatch));

export default compose(
  withMobileDialog(),
  withStyles(styles),
  withConnect,
)(DialogUserLogout);
