export const DEFAULT_LOCALE = 'en';

export const ADD_NOTIFICATION = 'web/App/ADD_NOTIFICATION';
export const OPEN_DIALOG = 'web/App/OPEN_DIALOG';
export const CLOSE_DIALOG = 'web/App/CLOSE_DIALOG';

export const LAYOUT_CHANGE = 'web/App/LAYOUT_CHANGE';
export const SHOW_NAVIGATION = 'web/App/SHOW_NAVIGATION';
export const HIDE_NAVIGATION = 'web/App/HIDE_NAVIGATION';
export const SEARCH = 'web/App/SEARCH';
export const CLEAR_SEARCH = 'web/App/CLEAR_SEARCH';

export const SET_LOGO = 'web/App/SET_LOGO';
export const SET_THEME = 'web/App/SET_THEME';
export const SET_CLOCK_24HR = 'web/App/SET_CLOCK_24HR';

export const NAVIGATE_CHANNEL = 'web/App/NAVIGATE_CHANNEL';
export const NAVIGATE_LANDING = 'web/App/NAVIGATE_LANDING';

export const USER_LOGIN = 'web/App/USER_LOGIN';
export const SET_USER_ID = 'web/App/SET_USER_ID';
export const SWITCH_TEAM = 'web/App/SWITCH_TEAM';
export const SWITCH_CHANNEL = 'web/App/SWITCH_CHANNEL';

export const SET_DEVICE_ACCESS_TOKEN = 'web/App/SET_DEVICE_ACCESS_TOKEN';

export const SET_PRIVATE_KEY = 'web/App/SET_PRIVATE_KEY';
export const CLEAR_PRIVATE_KEY = 'web/App/CLEAR_PRIVATE_KEY';

export const LOGIN_ERROR = 'web/App/LOGIN_ERROR';
export const RESET_LOGIN_ERROR = 'web/App/RESET_LOGIN_ERROR';
export const REGISTRATION_ERROR = 'web/App/REGISTRATION_ERROR';
export const SET_SCALA_PLAYER_ID = 'web/App/SET_SCALA_PLAYER_ID';
export const SET_SCALA_PLAYER_NAME = 'web/App/SET_SCALA_PLAYER_NAME';
export const SET_SCALA_ACCOUNT_ID = 'web/App/SET_SCALA_ACCOUNT_ID';
export const SET_SCALA_DATA = 'web/App/SET_SCALA_DATA';
export const SET_APPLICATION = 'web/App/SET_APPLICATION';
export const APPLICATION_ERROR = 'web/App/APPLICATION_ERROR';
export const REGISTER_BAND_DEVICE = 'web/App/REGISTER_BAND_DEVICE';
export const SET_DEVICE_ID = 'web/App/SET_DEVICE_ID';
export const GET_DEVICE_ID = 'web/App/GET_DEVICE_ID';
export const SET_ZOOM_ROOM_PASSCODE = 'web/App/SET_ZOOM_ROOM_PASSCODE';
export const SET_ZOOM_ROOM_AVAILABLE = 'web/App/SET_ZOOM_ROOM_AVAILABLE';
export const SET_BLUESCAPE_AVAILABLE = 'web/App/SET_BLUESCAPE_AVAILABLE';

export const SET_TEAM_NAME = 'app/App/SET_TEAM_NAME';
export const GET_TEAM_ID = 'app/App/GET_TEAM_ID';
export const GET_TEAM_ID_ERROR = 'app/App/GET_TEAM_ID_ERROR';
export const GET_TEAM_ID_RESPONSE = 'app/App/GET_TEAM_ID_RESPONSE';

export const GET_DEFAULT_TEAM = 'app/App/GET_DEFAULT_TEAM';
export const GET_DEFAULT_TEAM_RESULT = 'app/App/GET_DEFAULT_TEAM_RESULT';
export const GET_DEFAULT_TEAM_ERROR = 'app/App/GET_DEFAULT_TEAM_ERROR';

export const SET_ACTIVE_LAYOUT = 'web/App/SET_ACTIVE_LAYOUT';
export const SET_ACTIVE_CHANNEL_ID = 'web/App/SET_ACTIVE_CHANNEL_ID';
export const SET_CHANNEL_URL = 'web/App/SET_CHANNEL_URL';

export const OPEN_CHANNEL_SELECTOR = 'web/App/OPEN_CHANNEL_SELECTOR';
export const CLOSE_CHANNEL_SELECTOR = 'web/App/CLOSE_CHANNEL_SELECTOR';

export const DEVICE_SUBSCRIBE = 'web/App/DEVICE_SUBSCRIBE';
export const DEVICE_RECORD_CHANGE = 'web/App/DEVICE_RECORD_CHANGE';

export const VALIDATE_REQUESTED_CHANNEL = 'web/App/VALIDATE_REQUESTED_CHANNEL';

export const DEVICE_LOGIN = 'web/App/DEVICE_LOGIN';

export const LOGOUT_DEVICE = 'web/App/LOGOUT_DEVICE';
export const LOGOUT_USER = 'web/App/LOGOUT_USER';
export const SET_DEVICE_METADATA = 'web/App/SET_DEVICE_METADATA';

export const ADD_LAYOUT_VIDEO = 'web/App/ADD_LAYOUT_VIDEO';
export const REMOVE_LAYOUT_VIDEO = 'web/App/REMOVE_LAYOUT_VIDEO';

export const CAN_AUTOPLAY_MUTED = 'web/App/CAN_AUTOPLAY_MUTED';
export const CAN_AUTOPLAY_UNMUTED = 'web/App/CAN_AUTOPLAY_UNMUTED';

export const SET_MOUSE_ACTIVE = 'web/App/SET_MOUSE_ACTIVE';

export const SET_USER_AGENT_PARAMS = 'web/App/SET_USER_AGENT_PARAMS';

export const SET_BLEND_EXTENSION = 'web/App/SET_BLEND_EXTENSION';

export const CREDENTIALED_LOGIN = 'web/App/CREDENTIALED_LOGIN';
export const SET_WINDOW_SIZE = 'web/App/SET_WINDOW_SIZE';

export const BOLT_CLIENT_READY = 'web/App/BOLT_CLIENT_READY';

export const SET_CLOCK = 'web/App/SET_CLOCK';

export const START_LOCAL_ZOOM_MEETING = 'web/App/START_LOCAL_ZOOM_MEETING';
export const JOIN_LOCAL_ZOOM_MEETING = 'web/App/JOIN_LOCAL_ZOOM_MEETING';
export const START_LOCAL_ZOOM_SHARE = 'web/App/START_LOCAL_ZOOM_SHARE';
export const START_LOCAL_ZOOM_PHONE = 'web/App/START_LOCAL_ZOOM_PHONE';
export const SET_ZOOM_MEETING_ID = 'web/App/SET_ZOOM_MEETING_ID';
export const SET_ZOOM_MEETING_NODE_ID = 'web/App/SET_ZOOM_MEETING_NODE_ID';
export const LEAVE_ZOOM_MEETING = 'web/App/LEAVE_ZOOM_MEETING';
export const EMIT_ZOOM_MEETING_ID = 'web/App/EMIT_ZOOM_MEETING_ID';
export const EMIT_ZOOM_IN_MEETING = 'web/App/EMIT_ZOOM_IN_MEETING';
export const EMIT_ZOOM_MEETING_END = 'web/App/EMIT_ZOOM_MEETING_END';
export const EMIT_ZOOM_MEETING_NEEDS_PASSWORD = 'web/App/EMIT_ZOOM_MEETING_NEEDS_PASSWORD';
export const EMIT_ZOOM_SHARING_KEY = 'web/App/EMIT_ZOOM_SHARING_KEY';
export const EMIT_ZOOM_PARTICIPANTS = 'web/App/EMIT_ZOOM_PARTICIPANTS';
export const UPDATE_ZOOM_PARTICIPANTS = 'web/App/UPDATE_ZOOM_PARTICIPANTS';
export const ZOOM_SHARING_STATUS = 'web/App/ZOOM_SHARING_STATUS';
export const EMIT_ZOOM_VIEW_LIST = 'web/App/EMIT_ZOOM_VIEW_LIST';
export const ZOOM_CAMERA_CONTINUE = 'web/App/ZOOM_CAMERA_CONTINUE';
export const EMIT_ZOOM_CAMERA_LIST = 'web/App/EMIT_ZOOM_CAMERA_LIST';
export const UPDATE_ZOOM_CAMERA_LIST = 'web/App/UPDATE_ZOOM_CAMERA_LIST';
export const SET_ZOOM_CLIENT_MEETING_ID = 'web/App/SET_ZOOM_CLIENT_MEETING_ID';
export const ZOOM_MEETING_NODE_CHECK = 'web/App/ZOOM_MEETING_NODE_CHECK';

export const SET_USE_MULTICAST = 'web/App/SET_USE_MULTICAST';
export const SET_MENU_BUTTON_VISIBLE = 'web/App/SET_MENU_BUTTON_VISIBLE';

export const UPDATE_DEVICE = 'web/App/UPDATE_DEVICE';
export const UPDATE_MEETING = 'web/App/UPDATE_MEETING';

export const SET_HARDWARE_MANAGER_IP = 'web/App/SET_HARDWARE_MANAGER_IP';

export const SET_REMOTE_URL = 'web/App/SET_REMOTE_URL';
export const KEEP_QR_CODE_ON_TOP = 'web/App/KEEP_QR_CODE_ON_TOP';

export const SEND_ANALYTICS = 'web/App/SEND_ANALYTICS';
