// @flow

import { List, Map, OrderedSet } from 'immutable';
import { createSelector } from 'reselect';

const videoElephantState = (state: StateType) => state.get('videoElephant');

const getIds = (result: MapType<string, *>): ListType<string> => {
  const ids = result.get('ids');
  if (!ids) {
    throw new Error('Unable to get ids in result.');
  }
  return ids;
};

const getLoading = (result: MapType<string, *>): ListType<string> => {
  const loading = result.get('loading');
  if (typeof loading !== 'boolean') {
    throw new Error('Unable to get loading in result.');
  }
  return loading;
};

export const resultSelector = (state: StateType, id: string):MapType<string, *> => {
  const result = state.getIn(['videoElephant', 'resultValues', id]);
  if (!result) {
    throw new Error(`Unable to get videoElephant with ID ${id}`);
  }
  return result;
};

const queryResult = (state: StateType, queryGroupId: string, queryId: string):MapType<string, *> => {
  const query = state.getIn(['videoElephant', 'queryResults', queryGroupId, queryId]);
  if (!query) {
    throw new Error(`Unable to get videoElephant with Query Group ID "${queryGroupId}" and Query ID "${queryId}"`);
  }
  return query;
};

export const queryResultSelector = createSelector<StateType, *, *, *>(
  queryResult,
  getIds,
);

export const loadingSelector = createSelector<StateType, *, *, *>(
  queryResult,
  getLoading,
);

export const latestQueryResultSelector = (state: StateType, queryGroupId: string): ListType<string> => {
  const latestQueryResultId = state.getIn(['videoElephant', 'latestQueryResultIds', queryGroupId]);
  return queryResultSelector(state, queryGroupId, latestQueryResultId);
};

export const latestQueryLoadSelector = (state: StateType, queryGroupId: string): ListType<string> => {
  const latestQueryId = state.getIn(['videoElephant', 'latestQueryIds', queryGroupId]);
  return loadingSelector(state, queryGroupId, latestQueryId);
};

export const latestQueryHasMoreSelector = (state: StateType, queryGroupId: string): bool => {
  const latestQueryId = state.getIn(['videoElephant', 'latestQueryIds', queryGroupId]);
  return hasMoreSelector(state, queryGroupId, latestQueryId);
};

export const hasMoreSelector = createSelector<StateType, *, *, *>(
  queryResult,
  (result: MapType<string, *>): bool => {
    const loading = result.get('loading');
    const hasMore = result.get('hasMore');
    return !!(!loading && hasMore);
  },
);

type QueriesType = [string, MapType<string, *>];
export const queryGroupResultSelector = (state: StateType, queryGroupId: string): ListType<string> => (
  OrderedSet(state
    .getIn(['videoElephant', 'queryResults', queryGroupId]) || Map())
    .valueSeq()
)
  .sort((queriesA: QueriesType, queriesB: QueriesType) => {
    const { groups: groupsA } = /"start":(?<startA>\d+)/.exec(queriesA[0]) || { groups: {} };
    const { groups: groupsB } = /"start":(?<startB>\d+)/.exec(queriesB[0]) || { groups: {} };
    const { startA } = groupsA || {};
    const { startB } = groupsB || {};
    return parseInt(startA || '0', 10) - parseInt(startB || '0', 10);
  })
  .map((queries: QueriesType) => queries[1].get('ids') || List())
  .flatten(true)
  .toList();

export const videoElephantQuerySelector = createSelector<StateType, *, *, *>(
  videoElephantState,
  (state: StateType): string => state.get('videoElephantQuery'),
);
