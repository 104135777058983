// @flow

import type { AdapterType } from 'shared-redux/src/video-elephant/types';
import AbstractAdapter from 'shared-redux/src/video-elephant/adapter';
import { agent } from '../lib/api-agent';

(x: VideoElephantAdapter) => (x: AdapterType); // eslint-disable-line no-unused-expressions
export default class VideoElephantAdapter extends AbstractAdapter {
  query = (options: Object): Promise<*> => agent
    .post('/api/v1.0/video-elephant/search')
    .send(options)
    .set('Authorization', `Bearer ${agent.token}`);

  increment = (id: string, data: { title: string, provider: string }): Promise<*> => agent
    .post(`/api/v1.0/video-elephant/${id}/increment`)
    .send(data)
    .set('Authorization', `Bearer ${agent.token}`);
}
