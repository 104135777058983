// @flow

/**
*
* LayoutComponentCarousel
*
*/

import * as React from 'react';
import { is, Map, List } from 'immutable';
import type { Map as MapType } from 'immutable';
import withNode from '@bunchtogether/boost-client/dist/components/withNode';
import LayoutComponentCarouselAsset from 'components/LayoutComponentCarouselAsset';

type Props = {
  node: MapType<string, *>,
  layoutWidth: number,
  layoutHeight: number,
  active: boolean,
  carouselStyles: Object,
};

type State = {
  time: number,
  start: number,
  duration: number,
  assets: Array<Object>,
  currentAsset: number,
};

class LayoutComponentCarousel extends React.PureComponent<Props, State> { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
      time: 0,
      start: 0,
      duration: 0,
      assets: [],
      currentAsset: 0,
    };
  }

  componentDidMount() {
    if (this.props.node) {
      this.setMetadata(this.props);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (!is(prevProps.node, this.props.node) && this.props.node) {
      this.setMetadata(this.props);
    }
  }

  setMetadata = (props: Props) => {
    if (!Map.isMap(props.node)) {
      return;
    }
    const assets = props.node.getIn(['metadata', 'assets']);
    this.setState({
      assets: assets && List.isList(assets) ? assets.toJS() : [],
    }, () => {
      const asset = assets.get(this.state.currentAsset) || Map();
      if (asset.get('duration') > 0 && props.active) {
        this.startTimer();
      }
    });
  }

  startTimer = () => {
    this.setState({
      time: this.state.time,
      start: Date.now() - this.state.time,
    });
    const asset = this.state.assets[this.state.currentAsset];
    if (asset) {
      this.timer = setInterval(() => {
        const time = Date.now() - this.state.start;
        if (time >= (asset.duration * 1000)) {
          clearInterval(this.timer);
          this.handleNextAsset();
        } else {
          this.setState({
            time: Date.now() - this.state.start,
          });
        }
      }, 1000);
    }
  }

  handleNextAsset = () => {
    clearInterval(this.timer);
    let currentAsset = this.state.currentAsset += 1;
    if (this.state.currentAsset >= this.state.assets.length) {
      currentAsset = 0;
    }
    this.setState({ currentAsset, time: 0, start: 0 });
    this.startTimer();
  }

  timer: any;

  render() {
    const { assets, currentAsset } = this.state;
    const { layoutWidth, layoutHeight, active, carouselStyles } = this.props;
    const assetId = assets && assets[currentAsset] ? assets[currentAsset].id : '';
    const styles = carouselStyles && carouselStyles[assetId] ? carouselStyles[assetId] : {};
    return (
      <LayoutComponentCarouselAsset
        id={assetId}
        key={assetId}
        layoutWidth={layoutWidth}
        layoutHeight={layoutHeight}
        active={active}
        size={styles.size}
        padding={styles.padding}
        alignment={styles.alignment}
        fgColor={styles.fgColor}
        bgColor={styles.bgColor}
        backgroundImage={styles.backgroundImage}
        backgroundImageSize={styles.backgroundImageSize}
        backgroundImageAlignment={styles.backgroundImageAlignment}
      />
    );
  }
}

export default withNode()(LayoutComponentCarousel);

