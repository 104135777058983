// @flow

export const getFlexAlignment = (alignment: number) => {
  const paneStyle: Object = {};
  if (alignment === 0) {
    paneStyle.justifyContent = 'center';
    paneStyle.alignItems = 'center';
  } else if (alignment === 1) {
    paneStyle.justifyContent = 'center';
    paneStyle.alignItems = 'flex-start';
  } else if (alignment === 2) {
    paneStyle.justifyContent = 'flex-end';
    paneStyle.alignItems = 'flex-start';
  } else if (alignment === 3) {
    paneStyle.justifyContent = 'flex-end';
    paneStyle.alignItems = 'center';
  } else if (alignment === 4) {
    paneStyle.justifyContent = 'flex-end';
    paneStyle.alignItems = 'flex-end';
  } else if (alignment === 5) {
    paneStyle.justifyContent = 'center';
    paneStyle.alignItems = 'flex-end';
  } else if (alignment === 6) {
    paneStyle.justifyContent = 'flex-start';
    paneStyle.alignItems = 'flex-end';
  } else if (alignment === 7) {
    paneStyle.justifyContent = 'flex-start';
    paneStyle.alignItems = 'center';
  } else if (alignment === 8) {
    paneStyle.justifyContent = 'flex-start';
    paneStyle.alignItems = 'flex-start';
  } else if (!alignment) {
    paneStyle.justifyContent = 'center';
    paneStyle.alignItems = 'center';
  }
  return paneStyle;
};

export const getCssAlignment = (alignment: number) => {
  const paneStyle: Object = {};
  if (alignment === 0) {
    paneStyle.backgroundPosition = 'center center';
  } else if (alignment === 1) {
    paneStyle.backgroundPosition = 'center top';
  } else if (alignment === 2) {
    paneStyle.backgroundPosition = 'right top';
  } else if (alignment === 3) {
    paneStyle.backgroundPosition = 'right center';
  } else if (alignment === 4) {
    paneStyle.backgroundPosition = 'right bottom';
  } else if (alignment === 5) {
    paneStyle.backgroundPosition = 'center bottom';
  } else if (alignment === 6) {
    paneStyle.backgroundPosition = 'left bottom';
  } else if (alignment === 7) {
    paneStyle.backgroundPosition = 'left center';
  } else if (alignment === 8) {
    paneStyle.backgroundPosition = 'left top';
  } else if (!alignment) {
    paneStyle.backgroundPosition = 'center center';
  }
  return paneStyle;
};

export const getBackgroundPosition = (alignment: number) => {
  if (alignment === 0) {
    return 'center center';
  } else if (alignment === 1) {
    return 'center top';
  } else if (alignment === 2) {
    return 'right top';
  } else if (alignment === 3) {
    return 'right center';
  } else if (alignment === 4) {
    return 'right bottom';
  } else if (alignment === 5) {
    return 'center bottom';
  } else if (alignment === 6) {
    return 'left bottom';
  } else if (alignment === 7) {
    return 'left center';
  } else if (alignment === 8) {
    return 'left top';
  }
  return 'center center';
};

export const getPadding = (padding: Object = {}) => {
  const basePad = 1;
  const padStyle: Object = {};
  for (const direction of Object.keys(padding)) {
    const padMultiplier = padding[direction];
    if (padMultiplier) {
      const pad = `${basePad * padMultiplier}%`;
      if (direction === 'top') {
        padStyle.paddingTop = pad;
      }
      if (direction === 'left') {
        padStyle.paddingLeft = pad;
      }
      if (direction === 'right') {
        padStyle.paddingRight = pad;
      }
      if (direction === 'bottom') {
        padStyle.paddingBottom = pad;
      }
    }
  }
  return padStyle;
};
