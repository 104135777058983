// @flow
/* eslint-disable jsx-a11y/media-has-caption */

import * as React from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { Map, is } from 'immutable';
import boltClient from '@bunchtogether/bolt-client';
import type { Map as MapType } from 'immutable';
import { withStyles } from '@material-ui/core/styles';
import withNode from '@bunchtogether/boost-client/dist/components/withNode';
import { boltClientReadySelector, volumeSelector, mutedSelector, userAgentParamsSelector } from 'containers/App/selectors';
import { accessTokenSelector } from 'shared-redux/src/auth/selectors';
import LayoutComponentBase from 'components/LayoutComponentBase';
import Video2 from 'components/Video2';
import { getPadding, getFlexAlignment } from '../../lib/alignment';


const styles = () => ({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
});

type Props = {
  className?: string,
  id: string,
  classes: ClassesType,
  node?: MapType<string, *>,
  active: boolean,
  volume: number,
  muted: boolean,
  boltClientReady: boolean,
  backgroundImage: Object,
  backgroundImageSize: string,
  backgroundImageAlignment: number,
  padding: Object,
  alignment: number,
  size: string,
  fgColor: string,
  bgColor: string,
};

type State = {
  src: string | null,
};

export class LayoutComponentVideo extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      src: null,
    };
  }

  componentDidMount() {
    if (this.props.node) {
      this.setSrc(this.props);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (!is(prevProps.node, this.props.node) || (prevProps.boltClientReady !== this.props.boltClientReady && this.props.boltClientReady)) {
      this.setSrc(this.props);
    }
  }

  setSrc = (props: Props) => {
    const { node, boltClientReady } = props;
    if (!node || !Map.isMap(node) || !boltClientReady) {
      return;
    }
    const path = node.getIn(['metadata', 'video', 'path']);
    if (!path) {
      return;
    }
    const src = boltClient.getUrl(path);
    if (this.src !== src && src) {
      this.setState({ src });
    }
  }

  src: string;
  videoElement: HTMLVideoElement;

  render() {
    const { src } = this.state;
    const { classes, className, node, active, muted, volume, backgroundImage, backgroundImageSize, backgroundImageAlignment, id, padding, alignment, size, fgColor, bgColor } = this.props;
    if (!node || !Map.isMap(node)) {
      return null;
    }
    const videoProps: Object = {};
    if (!size || size === 'cover' || size === 'contain') {
      videoProps.style = {
        width: '100%',
        maxHeight: '100%',
      };
    } else {
      videoProps.style = {
        width: size,
        maxWidth: '100%',
      };
    }
    const style = {
      ...getFlexAlignment(alignment),
      ...getPadding(padding),
    };
    return (
      <LayoutComponentBase
        className={className}
        color={fgColor || 'inherit'}
        backgroundColor={bgColor || 'inherit'}
        backgroundImage={backgroundImage}
        backgroundImageSize={backgroundImageSize}
        backgroundImageAlignment={backgroundImageAlignment}
      >
        <div className={classes.container} style={style}>
          <Video2
            key={id}
            id={id}
            src={src}
            size={size}
            active={active}
            autoPlay={active}
            controls={active}
            style={videoProps.style}
            loop={active}
            muted={muted}
            volume={volume}
            playsInline
            ref={(e) => {
              if (e) {
                this.videoElement = e;
              }
            }}
          />
        </div>
      </LayoutComponentBase>
    );
  }
}

const withConnect = connect(
  (state: StateType): Object => ({
    accessToken: accessTokenSelector(state),
    boltClientReady: boltClientReadySelector(state),
    volume: volumeSelector(state),
    muted: mutedSelector(state),
    userAgentParams: userAgentParamsSelector(state),
  }),
  (dispatch: Function) => bindActionCreators({ }, dispatch),
);

export default compose(
  withStyles(styles),
  withConnect,
  withNode(),
)(LayoutComponentVideo);

