// @flow

import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Map, List } from 'immutable';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
// import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withNode from '@bunchtogether/boost-client/dist/components/withNode';
import KeiserChartBar from 'components/KeiserChartBar';
import { stringToColor } from '../../lib/colors';

const styles = (theme:Object) => ({ // eslint-disable-line
  root: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '99%',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '64%',
  },
  bar: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '40%',
    minHeight: 120,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  bigText: {
    fontWeight: 900,
    lineHeight: 1,
    color: theme.palette.primary.main,
    fontFamily: 'Roboto Condensed',
    letterSpacing: -5,
    [theme.breakpoints.up('xl')]: {
      fontSize: '9em',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '9em',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '12em',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '20em',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '26em',
    },
  },
  mediumText: {
    fontWeight: 600,
    color: '#000',
    lineHeight: 1,
    [theme.breakpoints.up('xl')]: {
      fontSize: '2.2em',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '3em',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '5em',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '8em',
    },
  },
  jouleText: {
    color: theme.palette.primary.main,
    opacity: 0.3,
    fontWeight: 600,
    lineHeight: 1,
    [theme.breakpoints.up('xl')]: {
      fontSize: '8em',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '8em',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '12em',
    },
  },
  condensed: {
    fontFamily: 'Roboto Condensed',
    fontWeight: 600,
    color: '#666',
    [theme.breakpoints.up('xl')]: {
      fontSize: '1.1em',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.2em',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.7em',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '3em',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '3.4em',
    },
  },
  centerSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
});

type Props = {
  classes: ClassesType,
  node?: Map,
};

type State = {
  open: boolean,
};

export class KeiserSet extends React.PureComponent<Props, State> {
  render() {
    const { classes, node } = this.props;
    if (!node || !Map.isMap(node)) {
      return null;
    }
    const reps = node.getIn(['metadata', 'reps']) || List();
    const serialNumber = node.getIn(['metadata', 'serialNumber']);
    const machineModel = node.getIn(['metadata', 'machineModel']) || '';
    const serialNumberStr = typeof serialNumber === 'number' ? serialNumber.toString() : serialNumber;
    // const machineId = node.getIn(['metadata', 'machineId']);
    // const startedAt = node.getIn(['metadata', 'startedAt']);
    const data = reps && List.isList(reps) ? reps : List();
    const lastRep = data.last() || Map();
    const lastRepWork = Number(parseFloat(lastRep.get('work') / 1000).toFixed(1));
    let unit = '';
    let calculatedWork = lastRepWork;
    if (lastRepWork > 1000000) {
      calculatedWork = Math.round(lastRepWork / 10000) * 10;
      unit = 'K';
    }
    return (
      <Paper className={classes.root} elevation={0}>
        <div className={classes.main}>
          <div className={classes.header}>
            <Typography className={classes.condensed}>MODEL {machineModel}</Typography>
            {/* <Typography className={classes.condensed}>{startedAt ? moment(startedAt).format('M/D h:mm A') : ''}</Typography> */}
            <Typography className={classes.condensed} style={{ color: stringToColor(serialNumberStr) }}>{serialNumberStr.slice(8)}</Typography>
          </div>
          <div className={classes.centerSection}>
            <div className={classes.row}>
              <Typography className={classes.bigText}>{calculatedWork}</Typography>
              <Typography className={classes.jouleText}>{unit}</Typography>
            </div>
            <Typography className={classes.mediumText}>STRENGTH</Typography>
          </div>
        </div>
        <div className={classes.bar}>
          <KeiserChartBar data={data} />
        </div>
      </Paper>
    );
  }
}

const withConnect = connect(null, (dispatch: Function) => bindActionCreators({}, dispatch));

export default compose(
  withStyles(styles),
  withConnect,
  withNode(),
)(KeiserSet);

