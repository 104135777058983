// @flow

/**
*
* ChannelImageComponent
*
*/

import * as React from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import type { Map as MapType } from 'immutable';
import boltClient from '@bunchtogether/bolt-client';
import { withStyles } from '@material-ui/core/styles';
import { boltClientReadySelector } from 'containers/App/selectors';
import withNode from '@bunchtogether/boost-client/dist/components/withNode';

const styles = (theme:Object) => ({ // eslint-disable-line no-unused-vars
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundSize: 'cover',
  },
});

type Props = {
  classes: ClassesType,
  node: MapType<string, *>, // eslint-disable-line react/no-unused-prop-types
  boltClientReady: boolean, // eslint-disable-line react/no-unused-prop-types
};

type State = {
  style: Object
};

class ChannelImageComponent extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      style: {},
    };
  }

  static getDerivedStateFromProps(props:Props) {
    const { node, boltClientReady } = props;
    if (!node) {
      return null;
    }
    if (!boltClientReady) {
      return null;
    }
    const path = node.getIn(['metadata', 'image', 'path']);
    if (!path) {
      return null;
    }
    return {
      style: {
        backgroundImage: `url(${boltClient.getUrl(path)})`,
        width: '100%',
        height: '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      },
    };
  }

  render() {
    const { style } = this.state;
    const { classes } = this.props;
    return <div className={classes.root} style={style} />;
  }
}

const withConnect = connect((state:StateType) => ({
  boltClientReady: boltClientReadySelector(state),
}), (dispatch: Function) => bindActionCreators({}, dispatch));

export default compose(
  withStyles(styles),
  withConnect,
  withNode(),
)(ChannelImageComponent);

