// @flow

import { Map } from 'immutable';
import * as constants from './constants';
import type { ActionType, StateType } from '../types';

const initialState = Map({
  tokenValidated: null,
  authData: Map({}),
  user: Map({}),
  createAuthClientError: null,
  applicationId: '',
  linkValid: null,
});

const actionsMap = {
  [constants.VALIDATE_TOKEN_RESULT](state: StateType, action: ActionType): StateType {
    return state.mergeDeep({
      tokenValidated: true,
      user: action.value.user,
      authData: action.value.authData,
      applicationId: '',
    });
  },
  [constants.VALIDATE_TOKEN_ERROR](): StateType {
    return initialState.set('tokenValidated', false);
  },
  [constants.LOGOUT](): StateType {
    return initialState.set('tokenValidated', false);
  },
  [constants.USER_LOGOUT](): StateType {
    return initialState.set('tokenValidated', false);
  },
  [constants.CHECK_LINK_RESULT](state: StateType): StateType {
    return state.set('linkValid', true);
  },
  [constants.CLEAR_CHECK_LINK](state: StateType): StateType {
    return state.set('linkValid', null);
  },
  [constants.CREATE_AUTH_CLIENT_RESULT](state: StateType, action: ActionType): StateType {
    return state.merge({
      applicationId: action.value.applicationId,
      teamId: action.value.teamId,
    });
  },
  [constants.CREATE_AUTH_CLIENT_ERROR](state: StateType, action: ActionType): StateType {
    let errorMessage = action.value.message;
    if (action.value.response && action.value.response.body && action.value.response.body.error) {
      errorMessage = action.value.response.body.error;
    }
    return state.merge({
      createAuthClientError: errorMessage,
    });
  },
  [constants.CLEAR_CREATE_AUTH_CLIENT_ERROR](state: StateType): StateType {
    return state.merge({
      createAuthClientError: null,
    });
  },
};

export default (state: StateType = initialState, action: ActionType): StateType => {
  const reduceFn = actionsMap[action.type];
  if (!reduceFn) return state;
  return reduceFn(state, action);
};
