// @flow

/* eslint-disable jsx-a11y/media-has-caption */

import * as React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Fab from '@material-ui/core/Fab';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import Slider from '@material-ui/core/Slider';
import Grid from '@material-ui/core/Grid';
import VolumeDownIcon from '@material-ui/icons/VolumeDown';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import { withStyles } from '@material-ui/core/styles';
import { mouseActiveSelector } from 'containers/App/selectors';

const styles = (theme: Object) => ({
  container: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: theme.spacing(11),
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    transition: 'opacity 0.5s',
  },
  noOpacity: {
    opacity: 0,
  },
  playPause: {
    position: 'absolute',
    bottom: theme.spacing(2),
    left: theme.spacing(2),
  },
  volumeIcon: {
    color: '#FFF',
  },
  volumeSlider: {
    position: 'absolute',
    bottom: theme.spacing(3),
    right: theme.spacing(2),
    width: theme.spacing(40),
  },
});

type Props = {
  mouseActive: boolean,
  forwardedRef: Function,
  classes: ClassesType,
  onClickPlay: () => void,
  onClickPause: () => void,
  onChangeVolume: (value:number) => void
};

type ForwardProps = {
  onClickPlay: () => void,
  onClickPause: () => void,
  onChangeVolume: (value:number) => void
};

type State = {
  volume: number,
  playing: boolean
};

export class VideoSilverlightControls extends React.Component<Props, State> {
  constructor(props:Props) {
    super(props);
    this.state = {
      volume: 0,
      playing: false,
    };
  }

  componentWillMount() {
    this.props.forwardedRef(this);
  }

  setPlaying = (playing: boolean) => {
    this.setState({
      playing,
    });
  }

  setVolume = (volume: number) => {
    this.setState({
      volume: volume * 100,
    });
  }

  handleClickPause = () => {
    if (this.props.onClickPause) {
      this.props.onClickPause();
    }
  }

  handleClickPlay = () => {
    if (this.props.onClickPlay) {
      this.props.onClickPlay();
    }
  }

  handleChangeVolume = (event:Event, value:number) => {
    if (this.props.onChangeVolume) {
      this.props.onChangeVolume(value / 100);
    }
  }

  render() {
    const { classes, mouseActive } = this.props;
    const { container, noOpacity, playPause, volumeIcon, volumeSlider } = classes;
    return (<div className={mouseActive ? container : `${noOpacity} ${container}`}>
      {this.state.playing ? <Fab color="secondary" aria-label="pause" className={playPause} onClick={this.handleClickPause}>
        <PauseIcon />
      </Fab> : <Fab color="secondary" aria-label="play" className={playPause} onClick={this.handleClickPlay}>
        <PlayArrowIcon />
      </Fab>}
      <Grid container spacing={2} className={volumeSlider}>
        <Grid item>
          <VolumeDownIcon className={volumeIcon} />
        </Grid>
        <Grid item xs>
          <Slider
            color="secondary"
            value={this.state.volume}
            max={100}
            min={0}
            onChange={this.handleChangeVolume}
            aria-labelledby="continuous-slider"
          />
        </Grid>
        <Grid item>
          <VolumeUpIcon className={volumeIcon} />
        </Grid>
      </Grid>
    </div>);
  }
}

const withConnect = connect((state: StateType) => ({
  mouseActive: mouseActiveSelector(state),
}));

const VideoSilverlightControlsComposed = compose(
  withStyles(styles),
  withConnect,
)(VideoSilverlightControls);

export default React.forwardRef<ForwardProps, VideoSilverlightControlsComposed>((props, ref) => <VideoSilverlightControlsComposed {...props} forwardedRef={ref} />);
