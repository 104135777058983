// @flow

import { call, put, takeLatest } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import base64 from 'base-64';
import type { AdapterType, CreateDefaultSaga } from './types';
import type { ActionType } from '../types';
import * as constants from './constants';

export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64String = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(base64.decode(base64String));
};


export function* validateTokenSaga(adapter: AdapterType, action: ActionType): Saga<void> {
  let jwt;
  try {
    yield call(adapter.validateToken, action.value);
    jwt = parseJwt(action.value.id_token);
  } catch (error) {
    yield put({ type: constants.VALIDATE_TOKEN_ERROR, value: error });
    return;
  }
  if (!jwt) {
    throw new Error('Failed to parse JWT');
  }
  yield put({ type: constants.VALIDATE_TOKEN_RESULT,
    value: {
      authData: action.value,
      user: jwt,
    },
  });
}

export function* createAuthClientSaga(adapter: AdapterType, action: ActionType): Saga<void> {
  try {
    const teamName = action.value.teamName;
    const link = action.value.link;
    const stripeToken = action.value.stripeToken;
    const response = yield call(adapter.createAuthClient, teamName, link, stripeToken);
    yield put({ type: constants.CREATE_AUTH_CLIENT_RESULT, value: response.body });
  } catch (error) {
    yield put({ type: constants.CREATE_AUTH_CLIENT_ERROR, value: error });
  }
}

export function* logoutSaga(adapter: AdapterType): Saga<void> {
  try {
    yield call(adapter.logout);
    console.log('logged out');
  } catch (error) {
    console.log('Error logging out: ', error);
  }
}

export function* checkLinkSaga(adapter: AdapterType, action: ActionType): Saga<void> {
  try {
    yield call(adapter.checkLink, action.value);
    yield put({ type: constants.CHECK_LINK_RESULT, value: null });
  } catch (error) {
    yield put({ type: constants.CREATE_AUTH_CLIENT_ERROR, value: error });
  }
}

export default function createDefaultSaga(adapter: AdapterType): CreateDefaultSaga {
  return function* (): Saga<*> {
    yield takeLatest(constants.VALIDATE_TOKEN, validateTokenSaga, adapter);
    yield takeLatest(constants.CREATE_AUTH_CLIENT, createAuthClientSaga, adapter);
    yield takeLatest(constants.USER_LOGOUT, logoutSaga, adapter);
    yield takeLatest(constants.CHECK_LINK, checkLinkSaga, adapter);
  };
}
