// @flow

/**
*
* KeiserChartBar
*
*/

import * as React from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { List, is } from 'immutable';
import debounce from 'lodash/debounce';
import { v4 as uuidv4 } from 'uuid';
import { withStyles } from '@material-ui/core/styles';
import ReactEcharts from 'echarts-for-react';
import Typography from '@material-ui/core/Typography';


const styles = (theme:Object) => ({ // eslint-disable-line no-unused-vars
  root: {
    position: 'relative',
    height: '100%',
    width: '100%',
  },
  powerLabelContainer: {
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%)',
    bottom: -4,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    backgroundColor: 'rgba(255,255,255,0.7)',
  },
  condensed: {
    fontFamily: 'Roboto Condensed',
    fontWeight: 800,
    letterSpacing: 1.1,
    color: '#000',
    [theme.breakpoints.up('xl')]: {
      fontSize: '1.1em',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.2em',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.7em',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '3em',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '3.4em',
    },
  },
});

type Props = {
  data: List,
  theme: Object,
  classes: ClassesType,
};

type State = {
  maxWork: number,
};

// If no data, set grey bars

const getBaseOption = (count: number) => ({
  xAxis: {
    type: 'category',
    data: Array(count).fill(100),
    show: false,
  },
  yAxis: {
    show: false,
  },
  series: [{
    itemStyle: { color: 'rgba(0,0,0,0.02)' },
    data: Array(count).fill(100),
    type: 'bar',
    barGap: '0%',
    barCategoryGap: '6%',
  }],
  grid: {
    left: '0px',
    right: '0px',
    bottom: '0px',
  },
});

class KeiserChartBar extends React.PureComponent<Props, State> { // eslint-disable-line react/prefer-stateless-function
  constructor(props: Props) {
    super(props);
    this.state = {
      maxWork: 0,
    };
  }

  componentDidMount() {
    if (List.isList(this.props.data)) {
      this.handleSetMaxWork(this.props);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (!is(prevProps.data, this.props.data) && List.isList(this.props.data)) {
      this.handleSetMaxWork(this.props);
    }
  }

  handleSetMaxWork = debounce((props: Props) => {
    const { data } = props;
    const workData = data.map((rep) => rep.get('peakPower')).sort();
    const maxWork = workData.last();
    if (maxWork !== this.state.maxWork) {
      this.setState({ maxWork });
    }
  }, 500);

  render() {
    const { theme, classes } = this.props;
    const data = this.props.data && List.isList(this.props.data) ? this.props.data.toJS() : [];
    const isBelow10 = data.length < 10;
    const filledData = isBelow10 ? Array(10 - data.length).fill({ rep: uuidv4(), peakPower: 0 }).concat(data) : data;
    const option = {
      xAxis: {
        type: 'category',
        data: filledData.map((rep) => rep.rep),
        show: false,
      },
      yAxis: {
        show: false,
        max: this.state.maxWork,
      },
      series: [{
        itemStyle: { color: theme.palette.secondary.main },
        data: filledData.map((rep) => rep.peakPower),
        type: 'bar',
        barGap: '0%',
        barCategoryGap: '6%',
      }],
      grid: {
        left: '0px',
        right: '0px',
        bottom: '0px',
      },
    };
    return (
      <div className={classes.root}>
        <ReactEcharts
          option={option}
          style={{ width: '100%', height: '100%', position: 'absolute', bottom: 0 }}
          opts={{ renderer: 'svg' }}
        />
        <ReactEcharts
          option={getBaseOption(filledData.length)}
          style={{ width: '100%', height: '100%', position: 'absolute', bottom: 0 }}
          opts={{ renderer: 'svg' }}
        />
        <div className={classes.powerLabelContainer}>
          <Typography className={classes.condensed}>SPEED</Typography>
        </div>
      </div>
    );
  }
}

const withConnect = connect(null, (dispatch: Function) => bindActionCreators({}, dispatch));

export default compose(
  withStyles(styles, { withTheme: true }),
  withConnect,
)(KeiserChartBar);

