// @flow

import * as React from 'react';
import { Map } from 'immutable';
import type { Map as MapType } from 'immutable';
import { compose } from 'redux';
import LayoutComponentBase from 'components/LayoutComponentBase';
import withNode from '@bunchtogether/boost-client/dist/components/withNode';

const styles = {
  overlay: {
    width: '100%',
    height: '100%',
    pointerEvents: 'none',
  },
};

type Props = {
  className?: string,
  node?: MapType<string, any>,
  padding: Object,
  active: boolean,
};

type State = {};

class LayoutComponentHtml extends React.PureComponent<Props, State> {
  getIFrame = (html: string) => {
    if (!html) {
      return null;
    }
    return (
      <iframe
        srcDoc={html}
        title='webpage'
        frameBorder='0'
        width='100%'
        height='100%'
        sandbox=''
      >
        Your current browser does not support html iframes
      </iframe>
    );
  };

  render() {
    const { className, node, padding, active } = this.props;
    if (!node || !Map.isMap(node)) {
      return null;
    }
    const html = node.getIn(['metadata', 'html']);
    return (
      <LayoutComponentBase
        className={className}
        color={'inherit'}
        backgroundColor={'inherit'}
        alignment={0}
        padding={padding}
      >
        {active ? this.getIFrame(html) : (
          <div style={styles.overlay}>
            {this.getIFrame(html)}
          </div>
        )}
      </LayoutComponentBase>
    );
  }
}

export default compose(
  withNode(),
)(LayoutComponentHtml);
